import { css } from '@emotion/react';
import React from 'react';
import { useThemeValues } from '../../../hooks/use-theme-values';
import { Label } from '../atoms/label.component';

type Props = {
  className?: string;
  id: string;
  children: React.ReactNode;
};

export const GroupLabel: React.FC<React.PropsWithChildren<Props>> = ({ children, ...rest }) => {
  const theme = useThemeValues();
  return (
    <Label
      css={css`
        display: block;
        font-size: ${theme.fontSize(16)};
        line-height: 1.35;
        margin-bottom: ${theme.spacing(2)};
      `}
      {...rest}
    >
      {children}
    </Label>
  );
};
