import { IconName, Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { usePopoverMenu, PopoverMenuItem, PopoverMenu } from '../popover';
import { Chip } from './chip.component';

type Item = {
  id: string;
  label: string;
};

export function DropdownChip({
  selectedOption,
  options,
  onSelectOption,
  trackingIdBase,
  iconName,
  showIcon = true,
  useActiveState = false,
  placeholder = 'Select an item',
  className,
}: {
  selectedOption?: Item;
  options: Item[];
  onSelectOption: (option: Item) => void;
  trackingIdBase?: string;
  iconName?: IconName;
  showIcon?: boolean;
  useActiveState?: boolean;
  placeholder?: string;
  className?: string;
}) {
  const { getTriggerProps, getMenuProps, close, open, isOpen } = usePopoverMenu({
    placement: 'bottom-start',
    middlewareOptions: { offset: 8 },
  });
  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();

  const hasSelectedOption = !!selectedOption;
  return (
    <>
      <Chip.DropdownFilter
        ref={filterTriggerRef}
        trackingId={trackingIdBase}
        filterIsActive={useActiveState && hasSelectedOption}
        {...triggerProps}
        onClick={isOpen ? close : open}
        leftElement={showIcon ? <Icon name={iconName ?? 'location-small'} size={18} /> : undefined}
        className={className}
      >
        {selectedOption ? selectedOption.label : placeholder}
      </Chip.DropdownFilter>
      <PopoverMenu {...getMenuProps()}>
        {options.map((option) => (
          <PopoverMenuItem
            onClick={() => onSelectOption(option)}
            key={option.id}
            value={option.id}
            trackingId={trackingIdBase ? `${trackingIdBase}-${option.id}` : undefined}
          >
            <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              {option.label}
              {option.id === selectedOption?.id && (
                <Icon css={{ marginLeft: theme.spacing(2) }} name='check-small' color='primary' size={18} />
              )}
            </div>
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </>
  );
}
