import { Icon, IconProps } from '../Icon.component';

export const MaximizeIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path d='M10 2H14V6' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M6 14H2V10' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M14 2L9.33337 6.66667' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    <path d='M2 14.0002L6.66667 9.3335' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </Icon>
);
