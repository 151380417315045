/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const FilterAltIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M21.7637,5 C22.4537,5 23.0137,5.56 23.0137,6.25 C23.0137,6.94 22.4537,7.5 21.7637,7.5 L2.2497,7.5 C1.5597,7.5 0.9997,6.94 0.9997,6.25 C0.9997,5.56 1.5597,5 2.2497,5 L21.7637,5 Z M18.7637,11 C19.4537,11 20.0137,11.56 20.0137,12.25 C20.0137,12.94 19.4537,13.5 18.7637,13.5 L5.2497,13.5 C4.5597,13.5 3.9997,12.94 3.9997,12.25 C3.9997,11.56 4.5597,11 5.2497,11 L18.7637,11 Z M15.7637,17 C16.4537,17 17.0137,17.56 17.0137,18.25 C17.0137,18.94 16.4537,19.5 15.7637,19.5 L8.2497,19.5 C7.5597,19.5 6.9997,18.94 6.9997,18.25 C6.9997,17.56 7.5597,17 8.2497,17 L15.7637,17 Z'
    />
  </Icon>
);
