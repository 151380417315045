import { FC, ReactNode } from 'react';
import { ConfirmBadgeIcon, ErrorBadgeIcon, IconProps, InfoBadgeIcon, WarningIndicator } from '../../icon';
import { useStyles } from '../../use-styles';
import { Text } from '../text/text.component';
import { css } from '@emotion/react';

type BaseProps = {
  fullWidth?: boolean;
  status: 'success' | 'warn' | 'error' | 'info';
};

type PropsMessage = {
  message: ReactNode;
  children?: never;
};

type PropsChildren = {
  message?: never;
  children: ReactNode;
};

type Props = (BaseProps & PropsMessage) | (BaseProps & PropsChildren);

export type BannerProps = Props & Omit<React.ComponentPropsWithoutRef<'section'>, keyof Props>;

type IconSelectionProps = Record<BannerProps['status'], FC<IconProps>>;

const IconSelection: IconSelectionProps = {
  success: ConfirmBadgeIcon,
  warn: WarningIndicator,
  error: ErrorBadgeIcon,
  info: InfoBadgeIcon,
};

export const BannerNotification = ({ message, fullWidth = false, status, children, ...rest }: BannerProps) => {
  const style = useStyles('BannerNotification', { status, fullWidth });
  const IconComponent = IconSelection[status];
  const iconColor = status === 'info' ? 'primary' : status;

  const displayedMessage = children || message;

  return (
    <section css={style} {...rest}>
      <IconComponent color={iconColor} />
      <div css={{ flex: 1 }}>
        {typeof displayedMessage === 'string' ? (
          <Text
            size='medium'
            css={css`
              margin: 0;
            `}
          >
            {displayedMessage}
          </Text>
        ) : (
          displayedMessage
        )}
      </div>
    </section>
  );
};
