import { Icon, IconProps } from '../Icon.component';

export const TrendDownIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M16.6572 15.2439L16.6572 8.65801C16.6572 8.10573 17.1049 7.65801 17.6572 7.65801C18.2095 7.65801 18.6572 8.10573 18.6572 8.65801L18.6572 17.658L18.6572 17.6581C18.6572 18.2103 18.2095 18.6581 17.6572 18.6581L8.65723 18.6581C8.10494 18.6581 7.65723 18.2103 7.65723 17.6581C7.65723 17.1058 8.10494 16.6581 8.65723 16.6581L15.243 16.6581L5.6364 7.05146C5.24588 6.66093 5.24588 6.02777 5.6364 5.63724C6.02693 5.24672 6.66009 5.24672 7.05062 5.63724L16.6572 15.2439Z'
    />
  </Icon>
);
