import { useState, useMemo } from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useId,
  useFloatingNodeId,
  type UseDismissProps,
} from '@floating-ui/react';

interface DialogOptions {
  initialOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  outsidePress?: UseDismissProps['outsidePress'];
}

export type UseDialogResponse = ReturnType<typeof useVerticalSlideModal>;

export function useVerticalSlideModal({
  initialOpen = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  outsidePress,
}: DialogOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const labelId = useId();
  const descriptionId = useId();
  const nodeId = useFloatingNodeId();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const { context } = useFloating({
    open,
    onOpenChange: setOpen,
  });

  const click = useClick(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown', outsidePress });
  const role = useRole(context);

  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss, role]);

  return useMemo(
    () => ({
      open,
      setOpen,
      labelId,
      descriptionId,
      modalProps: {
        context,
        ...getFloatingProps(),
        labelId,
        ref: context.refs.setFloating,
        descriptionId,
        isOpen: open,
        nodeId,
        close: () => setOpen(false),
      },
      triggerProps: {
        ref: context.refs.setReference,
        ...getReferenceProps(),
      },
    }),
    [open, setOpen, getFloatingProps, getReferenceProps, context, labelId, descriptionId]
  );
}
