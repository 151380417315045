import { css } from '@emotion/react';
import { ListboxOption } from './listbox-option.component';
import type { ListboxProps } from './listbox.component';
import { Listbox } from './listbox.component';
import { useListboxSearch } from './use-listbox-search';

export const SearchableListbox = ({ children, className, ...rest }: ListboxProps) => {
  const { SearchField, searchFieldProps, matchingChildren } = useListboxSearch(children);
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
      className={className}
    >
      <SearchField {...searchFieldProps} name='lb-search' />
      <Listbox {...rest}>{matchingChildren}</Listbox>
    </div>
  );
};

SearchableListbox.Option = ListboxOption;
