import { Icon, IconProps } from '../Icon.component';

export const DocumentFilledIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M13.0148 1.9873H8.48987C5.72844 1.9873 3.48987 4.22588 3.48987 6.98731V17.0127C3.48987 19.7741 5.72844 22.0127 8.48987 22.0127H15.5102C18.2716 22.0127 20.5102 19.7741 20.5102 17.0127V9.32673C20.5102 7.94469 19.9381 6.62428 18.9299 5.67905L16.4345 3.33962C15.5078 2.47081 14.2851 1.9873 13.0148 1.9873ZM5.98987 6.98731C5.98987 5.60659 7.10916 4.4873 8.48987 4.4873H13.0148C13.6499 4.4873 14.2613 4.72906 14.7246 5.16346L17.22 7.50289C17.7242 7.97551 18.0102 8.63571 18.0102 9.32673V17.0127C18.0102 18.3934 16.8909 19.5127 15.5102 19.5127H8.48987C7.10915 19.5127 5.98987 18.3934 5.98987 17.0127V6.98731ZM8.76965 10.75C8.0793 10.75 7.51965 11.3096 7.51965 12C7.51965 12.6904 8.0793 13.25 8.76965 13.25H15.2301C15.9205 13.25 16.4801 12.6904 16.4801 12C16.4801 11.3096 15.9205 10.75 15.2301 10.75H8.76965ZM7.51965 7.625C7.51965 6.93464 8.0793 6.375 8.76965 6.375H11.4367C12.127 6.375 12.6867 6.93464 12.6867 7.625C12.6867 8.31536 12.127 8.875 11.4367 8.875H8.76965C8.0793 8.875 7.51965 8.31536 7.51965 7.625ZM8.76965 15.125C8.0793 15.125 7.51965 15.6846 7.51965 16.375C7.51965 17.0654 8.0793 17.625 8.76965 17.625H15.2301C15.9205 17.625 16.4801 17.0654 16.4801 16.375C16.4801 15.6846 15.9205 15.125 15.2301 15.125H8.76965Z'
    />
  </Icon>
);
