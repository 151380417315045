/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const MarkUnreadIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M19,2.75 C20.7949254,2.75 22.25,4.20507456 22.25,6 L22.25,6 L22.2512367,13.4777554 C23.3272403,14.4816949 24,15.9122817 24,17.5 C24,20.5375661 21.5375661,23 18.5,23 C16.4646661,23 14.6875508,21.8944346 13.7364224,20.251072 L4,20.25 C2.20507456,20.25 0.75,18.7949254 0.75,17 L0.75,17 L0.75,6 C0.75,4.20507456 2.20507456,2.75 4,2.75 L4,2.75 Z M19.75,6.824 L13.5660486,11.9178656 C12.4181446,12.8631982 10.7836304,12.9042996 9.59341254,12.0411698 L9.43395143,11.9178656 L3.25,6.825 L3.25,17 C3.25,17.3796958 3.53215388,17.693491 3.89822944,17.7431534 L4,17.75 L13.0055957,17.7503367 C13.0018785,17.6673564 13,17.5838976 13,17.5 C13,14.4624339 15.4624339,12 18.5,12 C18.9302744,12 19.3490087,12.0494088 19.7508389,12.1428622 L19.75,6.824 Z M17.73,5.25 L5.269,5.25 L11.0232196,9.98803997 C11.2693901,10.1907686 11.6125367,10.2132941 11.8802575,10.0556162 L11.9767804,9.98803997 L17.73,5.25 Z'
    />
  </Icon>
);
