import { css } from '@emotion/react';
import { useThemeValues } from '../../../../hooks';
import { Checkbox } from '../../../forms/atoms';
import type { BoolListOptionProps } from '../../layouts';
import { BoolListLayoutOption } from '../../layouts';

type Props = Omit<BoolListOptionProps<'checkbox'>, 'field'>;

export const CheckListOption = (props: Props) => {
  const theme = useThemeValues();
  return (
    <BoolListLayoutOption
      css={css`
        margin-bottom: ${theme.spacing(2)};
      `}
      {...props}
      field={Checkbox}
    />
  );
};
