import { css } from '@emotion/react';
import type { TableInstance } from 'react-table';
import { IconButton } from '../../';
import { useModalControl } from '../../';
import { FilterAltIcon } from '../../../icon';
import { TableFilters } from '../column-filters';
import type { ManualFilters } from '../table-types';
import { useStyles } from '../../../use-styles';
import { TableData } from '../table-data-type';

type FilterContainerProps<T extends TableData> = {
  tableInstance: TableInstance<T>;
  manualFiltersRender?: ManualFilters<T>;
};

export const FilterContainer = <T extends TableData>({
  tableInstance,
  manualFiltersRender,
}: FilterContainerProps<T>) => {
  const { modalProps, triggerProps } = useModalControl();
  const notificationBadgeStyles = useStyles('TableCommonStyles', 'filterNotificationBadgeStyles');
  const { showEnabledFiltersBadge, setShowEnabledFiltersBadge, tableTrackingIds } = tableInstance;

  const notificationStyle = (showNotification: boolean) => [
    notificationBadgeStyles,
    css`
      ::after {
        opacity: ${showNotification ? 1 : 0};
      }
    `,
  ];

  return (
    <>
      <div
        css={css`
          position: relative;
        `}
      >
        <IconButton
          disabled={tableInstance.isLoading}
          css={notificationStyle(
            !!manualFiltersRender ? showEnabledFiltersBadge : !!tableInstance.state.filters.length
          )}
          label={'Filter'}
          color='light'
          data-test-id={tableTrackingIds.tableFilters}
          trackingId={tableTrackingIds.tableFilters}
          {...triggerProps}
        >
          <FilterAltIcon />
        </IconButton>
      </div>
      {!!manualFiltersRender ? (
        manualFiltersRender(modalProps, setShowEnabledFiltersBadge, tableInstance)
      ) : (
        <TableFilters tableInstance={tableInstance} {...modalProps} />
      )}
    </>
  );
};
