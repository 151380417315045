import { KeyNames } from '../../../constants';
import { isFunction } from 'lodash-es';
import type { FocusEvent, KeyboardEvent } from 'react';
import { useRef } from 'react';
import { shouldShowError } from '../atoms';
import { EmailField } from '../fields/email-field/email-field.component';
import { PhoneField } from '../fields/phone-field/phone-field.component';
import { TextField } from '../fields/text-field/text-field.component';
import type { ValidatorFn } from '../hooks';
import { getListMaxError, useFormField } from '../hooks';
import type { BasicFormFieldProps } from '../layouts';
import { ListFieldItem } from './list-field-item';
import { ListFieldUl } from './list-field-ul';

type ListFieldKey = 'email' | 'phone' | 'text';

type ListFieldProps<T extends ListFieldKey> = BasicFormFieldProps<'list'> & {
  className?: string;
  fieldValidator?: ValidatorFn<T>;
  fieldType: T;
};

export const isMaxed = ({ maxAllowed = 0, count }: { maxAllowed?: number; count: number }) =>
  maxAllowed > 0 && count >= maxAllowed;

const fieldsMap = {
  email: EmailField,
  phone: PhoneField,
  text: TextField,
};

export const ListField = <T extends ListFieldKey>({
  className,
  disabled,
  helperText,
  error,
  fieldType,
  fieldValidator,
  label,
  maxAllowed,
  minRequired,
  name,
  onBlur,
  touched,
  value,
  ...rest
}: ListFieldProps<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const fieldProps = useFormField({
    // if it's using custom validation, we want it to validate all the time
    touched: isFunction(fieldValidator),
    type: fieldType as ListFieldKey,
    validator: fieldValidator as ValidatorFn<ListFieldKey>,
  });

  // upper + lower limit handling
  const hasLimitError = shouldShowError({ error, touched, ...rest });
  const hasFieldError = shouldShowError(fieldProps);
  const errorProps =
    !hasFieldError && hasLimitError && !fieldProps.value
      ? {
          'aria-invalid': rest['aria-invalid'],
          error,
          touched,
        }
      : {
          'aria-invalid': fieldProps['aria-invalid'],
          error: fieldProps.error,
          touched: fieldProps.touched,
        };

  const atMax = isMaxed({ maxAllowed, count: value.length });

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const payload = {
      ...e,
      target: {
        ...e.target,
        name,
      },
    };
    fieldProps.onBlur(payload);
    onBlur(payload);
  };
  const focusField = () => {
    if (containerRef.current) {
      const field = containerRef.current.querySelector('input');
      field?.focus();
    }
  };
  const onAdd = () => {
    const trimmed = (fieldProps.value as string).trim();
    const willBeMaxed = isMaxed({ maxAllowed, count: value.length + 1 });
    fieldProps.onChange({ name, value: '' });
    // dedupe incoming values
    if (!value.includes(trimmed)) {
      rest.onChange({ name, value: trimmed });

      if (willBeMaxed) {
        fieldProps.onBlur();
      } else {
        focusField();
      }
    }
  };
  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === KeyNames.Enter && fieldProps.value.trim() && !fieldProps.error) {
      onAdd();
    }
  };

  const FieldComponent = fieldsMap[fieldType];

  return (
    <div className={className} ref={containerRef}>
      {/*
      // @ts-ignore not worth tracking down which dynamic prop it's complaining about */}
      <FieldComponent
        name={name}
        label={label}
        {...fieldProps}
        {...errorProps}
        disabled={atMax || disabled}
        helperText={atMax ? getListMaxError(maxAllowed as number) : helperText}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
        actionText='Add'
        onActionClick={onAdd}
      />
      {value.length > 0 && (
        <ListFieldUl>
          {value.map((item) => (
            <ListFieldItem
              disabled={disabled}
              key={item}
              onRemove={() => {
                rest.onChange({ name, value: item });
              }}
            >
              {item}
            </ListFieldItem>
          ))}
        </ListFieldUl>
      )}
    </div>
  );
};
