import { ListboxOption } from '../../../listbox';
import { DropdownFieldProps } from '../../fields/dropdown/types';
import { DropdownProvider } from '../../providers';
import { DropdownBaseField } from './dropdown-base-field.component';
import { OptionGroup } from './option-group.component-old';

/**
 *
 * This component is only used internally to the design system by SelectListField.
 * It should be removed once SelectListField is refactored to use the new dropdown based on floating-ui
 */
export const DropdownFieldInternal = ({ children, ...rest }: DropdownFieldProps) => (
  <DropdownProvider
    id={rest.id}
    onBlur={rest.onBlur}
    onFocus={rest.onFocus}
    onChange={rest.onChange}
    value={rest.value}
    name={rest.name}
  >
    <DropdownBaseField {...rest}>{children}</DropdownBaseField>
  </DropdownProvider>
);

DropdownFieldInternal.Option = ListboxOption;
DropdownFieldInternal.OptionGroup = OptionGroup;
