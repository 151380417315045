import { css } from '@emotion/react';
import { useThemeValues } from '../../../hooks';
import { styles } from '../../../styles';
import { useStyles } from '../../../use-styles';
import { Text } from '../../text';
import { TextLink } from '../../text-link';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  onRemove: () => void;
};

export const ListFieldItem = ({ children, disabled, onRemove }: Props) => {
  const style = useStyles('ListFieldItem');
  const theme = useThemeValues();
  return (
    <li css={style}>
      <Text
        css={[
          css`
            margin: ${theme.spacing(0, 2, 0, 0)};
          `,
          styles.truncate,
        ]}
      >
        {children}
      </Text>
      {!disabled && <TextLink onClick={onRemove}>Remove</TextLink>}
    </li>
  );
};
