import { Icon, IconProps } from '../Icon.component';

export const SoftphoneTrayIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={40} {...props} viewBox='0 0 40 40'>
    <rect width='40' height='40' rx='8' fill='#202328' />
    <rect width='24' height='24' transform='translate(8 8)' fill='white' fillOpacity='0.01' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 11C12 12.1046 12.8954 13 14 13C15.1046 13 16 12.1046 16 11C16 9.89543 15.1046 9 14 9C12.8954 9 12 9.89543 12 11ZM12 17C12 18.1046 12.8954 19 14 19C15.1046 19 16 18.1046 16 17C16 15.8954 15.1046 15 14 15C12.8954 15 12 15.8954 12 17ZM14 25C12.8954 25 12 24.1046 12 23C12 21.8954 12.8954 21 14 21C15.1046 21 16 21.8954 16 23C16 24.1046 15.1046 25 14 25ZM18 11C18 12.1046 18.8954 13 20 13C21.1046 13 22 12.1046 22 11C22 9.89543 21.1046 9 20 9C18.8954 9 18 9.89543 18 11ZM20 19C18.8954 19 18 18.1046 18 17C18 15.8954 18.8954 15 20 15C21.1046 15 22 15.8954 22 17C22 18.1046 21.1046 19 20 19ZM18 23C18 24.1046 18.8954 25 20 25C21.1046 25 22 24.1046 22 23C22 21.8954 21.1046 21 20 21C18.8954 21 18 21.8954 18 23ZM26 13C24.8954 13 24 12.1046 24 11C24 9.89543 24.8954 9 26 9C27.1046 9 28 9.89543 28 11C28 12.1046 27.1046 13 26 13ZM24 17C24 18.1046 24.8954 19 26 19C27.1046 19 28 18.1046 28 17C28 15.8954 27.1046 15 26 15C24.8954 15 24 15.8954 24 17ZM26 25C24.8954 25 24 24.1046 24 23C24 21.8954 24.8954 21 26 21C27.1046 21 28 21.8954 28 23C28 24.1046 27.1046 25 26 25ZM18 29C18 30.1046 18.8954 31 20 31C21.1046 31 22 30.1046 22 29C22 27.8954 21.1046 27 20 27C18.8954 27 18 27.8954 18 29Z'
      fill='white'
    />
  </Icon>
);
