import { Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { TableInstance } from 'react-table';
import { ButtonBar } from '../../buttons';
import { TextButton } from '../../text-button';
import { CollapsedTableConfig } from '../table-types';
import { TableData } from '../table-data-type';

type CollapsibleTableButtonProps<T extends TableData> = {
  tableInstance: TableInstance<T>;
  collapsedTableConfig: CollapsedTableConfig<T>;
  setIsCollapsed: Dispatch<SetStateAction<boolean>>;
  isCollapsed: boolean;
};

export const CollapsibleTableButton = <T extends TableData>({
  tableInstance,
  collapsedTableConfig,
  setIsCollapsed,
  isCollapsed,
}: CollapsibleTableButtonProps<T>) => {
  return (
    <ButtonBar
      css={css`
        justify-content: right;
      `}
    >
      <TextButton
        onClick={() => {
          setIsCollapsed((currentState) => {
            if (currentState) {
              collapsedTableConfig?.onCollapse?.(tableInstance);
            }

            if (!currentState) {
              collapsedTableConfig?.onExpand?.(tableInstance);
            }

            return !currentState;
          });
        }}
      >
        {!isCollapsed
          ? collapsedTableConfig.expandLabel ?? 'Show More'
          : collapsedTableConfig.collapseLabel ?? 'Show Less'}
      </TextButton>
    </ButtonBar>
  );
};
