/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const StarIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M6.48878884,22.7053269 C5.75552196,23.0908281 4.84858122,22.8089083 4.46308001,22.0756414 C4.30957141,21.7836507 4.25659939,21.4491986 4.31236466,21.1240621 L5.35047061,15.07144 L0.952983233,10.7849492 C0.359757866,10.2066974 0.347619406,9.25702753 0.925871224,8.66380216 C1.15613413,8.42757671 1.45784775,8.27384594 1.78430329,8.22640917 L7.86148138,7.34334351 L10.579278,1.83648598 C10.9459115,1.09360549 11.8453502,0.788596993 12.5882307,1.15523043 C12.8840496,1.30122579 13.1234909,1.54066707 13.2694863,1.83648598 L15.9872829,7.34334351 L22.064461,8.22640917 C22.8842783,8.3455356 23.4523005,9.10669988 23.3331741,9.92651717 C23.2857373,10.2529727 23.1320065,10.5546863 22.8957811,10.7849492 L18.4982937,15.07144 L19.5363997,21.1240621 C19.6764412,21.9405668 19.1280594,22.7160007 18.3115548,22.8560422 C17.9864183,22.9118075 17.6519662,22.8588355 17.3599755,22.7053269 L11.9243822,19.8476663 L6.48878884,22.7053269 Z M10.8773646,17.5736767 C11.5328437,17.229071 12.3159206,17.229071 12.9713997,17.5736767 L16.7298562,19.5496142 L16.0120548,15.3645114 C15.8868695,14.6346248 16.1288536,13.8898743 16.6591473,13.3729657 L19.6998024,10.4090595 L15.4977204,9.79846123 C14.7648725,9.6919722 14.1313499,9.23169111 13.8036104,8.5676183 L11.9243822,4.75988601 L10.0451539,8.5676183 C9.71741441,9.23169111 9.08389184,9.6919722 8.35104397,9.79846123 L4.14896191,10.4090595 L7.18961706,13.3729657 C7.71991075,13.8898743 7.96189484,14.6346248 7.83670948,15.3645114 L7.11890817,19.5496142 L10.8773646,17.5736767 Z'
    />
  </Icon>
);
