export function classNames(...args: Array<string | undefined | Record<string, boolean>>): string {
  return args
    .filter((arg) => !!arg)
    .reduce((classes, arg) => {
      if (typeof arg === 'string') {
        return classes.concat([arg]);
      } else {
        return classes.concat(Object.keys(arg as Record<string, boolean>).filter((className) => arg?.[className]));
      }
    }, [] as string[])
    .join(' ');
}
