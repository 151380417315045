import { KeyNames } from '../../constants';
import { genUID } from '../../helpers';
import { isFunction } from 'lodash-es';
import type { CSSProperties, KeyboardEvent, MutableRefObject } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

export type ListboxSelectionSource = 'mouse' | 'key' | 'multi_arrow' | 'select_all' | 'arrow';

export type UseListboxProps<T extends HTMLElement = HTMLUListElement> = {
  active?: boolean;
  labelId: string;
  id?: string;
  listboxRef?: MutableRefObject<T | null>;
  onBlur?: () => void;
  onEscape?: () => void;
  onFocus?: () => void;
  onSelect: (value: string | string[], action: ListboxSelectionSource, e: Event | KeyboardEvent) => void;
  selectOnArrowKey?: boolean;
  value: string | string[];
};

export type ListboxOptionGetterProps = {
  'aria-disabled'?: boolean;
  'aria-selected'?: boolean;
  'data-focused'?: string;
  'data-value': string;
  id: string;
  role: string;
};

export type ListboxProps<T extends HTMLElement = HTMLUListElement> = {
  'aria-activedescendant'?: string;
  'aria-labelledby': string;
  id: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyDown: (e: KeyboardEvent<T>) => void;
  ref: MutableRefObject<T | null>;
  role: string;
  style: CSSProperties;
  tabIndex?: number;
};

type OptionProps = { disabled?: boolean; value: string };
export type ListboxOptionData = {
  disabled: boolean;
  index: number;
  value: string;
};

export type OptionPropsGetter = (props: OptionProps) => ListboxOptionGetterProps;

export type UseListboxResponse<T extends HTMLElement = HTMLUListElement> = {
  activeItem: ListboxOptionData;
  getOptionProps: OptionPropsGetter;
  listboxId: string;
  listboxProps: ListboxProps<T>;
  setActiveItem: (item: ListboxOptionData) => void;
};

const getItemId = (id: string, value: string) => `${id}-${value}`;

// utils for working with menu items via stable values
const getChildren = (listElement?: HTMLElement | null) => {
  const optGroup = listElement?.querySelectorAll('[role="option"]');
  if (optGroup?.length) return optGroup;
  if (listElement) return listElement.children;
  return undefined;
};

export const getListboxItems = (listElement?: HTMLElement | null): ListboxOptionData[] => {
  const children = getChildren(listElement);
  if (children?.length) {
    const items = Array.from(children) as HTMLElement[];
    return items.map((el, index) => {
      const { value = '' } = el.dataset;
      return {
        index,
        value,
        disabled: el.getAttribute('aria-disabled') === 'true',
      };
    });
  }
  return [];
};

// utils for ensuring we don't focus on disabled options
const getFirstEnabled = ({ disabled }: ListboxOptionData) => !disabled;

const getNextEnabled = (items: ListboxOptionData[], startIndex: number, isReverse?: boolean) => {
  const nextIndex = isReverse ? startIndex - 1 : startIndex + 1;
  let nextEnabled;
  if (isReverse) {
    if (startIndex === 0) {
      nextEnabled = items.slice().reverse().find(getFirstEnabled);
    } else {
      const searchList = items.slice(0, startIndex).reverse().concat(items.slice().reverse());
      nextEnabled = searchList.find(getFirstEnabled);
    }
  } else {
    if (nextIndex === items.length) {
      nextEnabled = items.find(getFirstEnabled);
    } else {
      const searchList = items.slice(nextIndex).concat(items);
      nextEnabled = searchList.find(getFirstEnabled);
    }
  }
  return nextEnabled ?? defaultItem;
};

// select items from start to end indices (including end)
const getSelectionRange = (listElement: HTMLElement | null, rangeStart: number, rangeEnd?: number) => {
  const items = getListboxItems(listElement);
  return items
    .slice(rangeStart, rangeEnd ? rangeEnd + 1 : items.length)
    .reduce((arr: string[], { disabled, value }) => {
      if (disabled) return arr;
      return [...arr, value];
    }, []) as unknown as string[];
};

const defaultItem = { disabled: false, index: 0, value: '' };

const preventFocus = (event: MouseEvent) => {
  event.preventDefault();
};

/**
 * Hook for accessible listbox functionality + props.
 * @param {boolean} [props.active] Optional control for list focusing behavior for toggled/animated listboxes
 * @param {string} props.labelId Id for the label describing the listbox.
 * @param {string} [props.id] Optional uid when controlling a listbox from a parent component that needs access to the listbox id.
 * @param {object} [props.listboxRef] Optional react ref for control from a parent component that needs access to the listbox ref.
 * @param {Function} [props.onBlur] Optional callback when listbox loses focus
 * @param {Function} [props.onEscape] Optional callback when esc key is pressed while focused on listbox
 * @param {Function} [props.onFocus] Optional callback when listbox receives focus
 * @param {Function} props.onSelect Callback for listbox selection events. Receives either a selected value or array of selected values (for multiselect.)
 * @param {boolean} [props.selectOnArrowKey] Should arrow key navigation (up, down, home, end) trigger select function?
 * @param {string | string[]} props.value Either a string (single select) or array of strings (multiselect.)
 */
export function useListbox<T extends HTMLElement = HTMLUListElement>({
  active = true,
  labelId,
  id,
  listboxRef,
  onBlur,
  onEscape,
  onFocus,
  onSelect,
  selectOnArrowKey,
  value,
}: UseListboxProps<T>): UseListboxResponse<T> {
  const localListRef = useRef<T>(null);
  const listRef = listboxRef ?? localListRef;
  const idRef = useRef(id ?? genUID());
  const [activeItem, setActiveItem] = useState<ListboxOptionData>(() => {
    const items = getListboxItems(listRef.current);
    if (items.length) return items.find(getFirstEnabled) ?? defaultItem;
    return defaultItem;
  });

  useEffect(() => {
    const children = getChildren(listRef.current);
    if (!children) return;
    const nextItem = children[activeItem.index] as HTMLElement;
    if (nextItem) nextItem.focus();
  }, [activeItem.index]);

  // scroll the list when it has overflow and state.index is not fully visible
  // (happens on expand as well as index changes)
  useEffect(() => {
    if (active && listRef.current) {
      // find option at currently active index
      const opt = listRef.current.querySelector(`[data-value="${activeItem.value}"]`) as HTMLLIElement;
      // only do this check if the list has overflow
      if (opt && listRef.current.scrollHeight > listRef.current.clientHeight) {
        const scrollBottom = listRef.current.clientHeight + listRef.current.scrollTop;
        const optBottom = opt.offsetTop + opt.offsetHeight;
        // if the bottom option is not fully in view
        if (optBottom > scrollBottom) {
          listRef.current.scrollTop = optBottom - listRef.current.clientHeight;
        } else if (opt.offsetTop < listRef.current.scrollTop) {
          // if the top option is not fully in view
          listRef.current.scrollTop = opt.offsetTop;
        }
      }
    }
  }, [activeItem.value, active]);

  return useMemo(() => {
    const multiSelect = Array.isArray(value);
    const listboxId = idRef.current;

    const getFirstActiveOrFirst = () => {
      const items = getListboxItems(listRef.current);
      let selected = items.find((item) => {
        if (multiSelect) {
          return value.includes(item.value);
        } else {
          return item.value === value;
        }
      });
      if (!selected) selected = items.find(({ disabled }) => !disabled);
      if (selected) setActiveItem(selected);
    };

    return {
      activeItem,
      listboxId,
      getOptionProps: (props) => {
        let selected;
        if (multiSelect) {
          selected = value.includes(props.value);
        } else if (props.value === value) {
          selected = true;
        }
        return {
          'aria-disabled': props.disabled ? true : undefined,
          'aria-selected': selected,
          'data-focused': props.value === activeItem.value ? 'true' : undefined,
          'data-value': props.value,
          id: getItemId(listboxId, props.value),
          onClick: props.disabled
            ? undefined
            : (e: Event) => {
                onSelect(props.value, 'mouse', e);
              },
          onMouseDown: preventFocus,
          onTouchStart: preventFocus,
          role: 'option',
        };
      },
      listboxProps: {
        id: listboxId,
        'aria-activedescendant': active ? getItemId(listboxId, activeItem.value) : undefined,
        'aria-labelledby': labelId,
        // @ts-ignore
        'aria-multiselectable': multiSelect ? 'true' : undefined,
        onBlur: () => {
          setActiveItem(defaultItem);
          if (isFunction(onBlur)) onBlur?.();
        },
        onFocus: () => {
          getFirstActiveOrFirst();
          if (isFunction(onFocus)) onFocus?.();
        },
        onKeyDown: (e: KeyboardEvent) => {
          switch (e.key) {
            case KeyNames.Escape: {
              if (typeof onEscape === 'function') {
                e.preventDefault();
                return onEscape();
              }
              break;
            }
            case KeyNames.End: {
              e.preventDefault();
              const lastEnabledItem = getListboxItems(listRef.current).reverse().find(getFirstEnabled);
              const rangeStart = activeItem.index;
              const item = lastEnabledItem ?? defaultItem;

              setActiveItem(item);

              if (multiSelect && e.ctrlKey && e.shiftKey) {
                onSelect(getSelectionRange(listRef.current, rangeStart, lastEnabledItem?.index), 'multi_arrow', e);
              } else if (selectOnArrowKey) {
                onSelect(item.value, 'arrow', e);
              }
              break;
            }
            case KeyNames.Enter:
            case KeyNames.Space:
              e.preventDefault();
              return onSelect(activeItem.value, 'key', e);
            case KeyNames.Home: {
              e.preventDefault();
              const firstEnabled = getListboxItems(listRef.current).find(getFirstEnabled) ?? defaultItem;
              const rangeEnd = activeItem.index;

              setActiveItem(firstEnabled);

              if (multiSelect && e.ctrlKey && e.shiftKey) {
                onSelect(getSelectionRange(listRef.current, firstEnabled.index, rangeEnd), 'multi_arrow', e);
              } else if (selectOnArrowKey) {
                onSelect(firstEnabled.value, 'arrow', e);
              }
              break;
            }
            case KeyNames.Down: {
              e.preventDefault();
              const items = getListboxItems(listRef.current);
              const next = getNextEnabled(items, activeItem.index);

              setActiveItem(next);

              if (multiSelect && e.shiftKey) {
                onSelect(next.value, 'multi_arrow', e);
              } else if (selectOnArrowKey) {
                onSelect(next.value, 'arrow', e);
              }
              break;
            }
            case KeyNames.Up: {
              e.preventDefault();
              const items = getListboxItems(listRef.current);
              const next = getNextEnabled(items, activeItem.index, true);

              setActiveItem(next);

              if (multiSelect && e.shiftKey) {
                onSelect(next.value, 'multi_arrow', e);
              } else if (selectOnArrowKey) {
                onSelect(next.value, 'arrow', e);
              }
              break;
            }
            case 'a': {
              // handle ctrl/cmd + a for multiselect
              if (multiSelect && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                const allEnabled = getListboxItems(listRef.current).reduce((arr: string[], { disabled, value }) => {
                  if (disabled) return arr;
                  return [...arr, value];
                }, []) as unknown as string[];
                const remainder =
                  value.length === allEnabled.length ? [] : allEnabled.filter((item) => !value.includes(item));

                onSelect(remainder, 'select_all', e);
              }
            }
            default:
              break;
          }
        },
        ref: listRef,
        role: 'listbox',
        style: {
          position: 'relative' as const,
        },
        tabIndex: active ? 0 : undefined,
      },
      setActiveItem,
    };
  }, [active, activeItem, value, onSelect, onBlur, onFocus, onEscape, selectOnArrowKey]);
}
