import { MutableRefObject, useState, useLayoutEffect, useCallback } from 'react';
import {
  FloatingContext,
  Placement,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useFloating,
  useInteractions,
  useRole,
  ReferenceType,
  useFloatingNodeId,
} from '@floating-ui/react-dom-interactions';
import { useDidUpdate, useOnClickOutside } from '../../../../hooks';
import { KeyNames } from '../../../../constants';
import { useDebouncedBlur } from '../../hooks';

type CustomTriggerRef = React.MutableRefObject<HTMLElement | null>;

type PrimitiveOffsetDistance = number | string;
export type UseDatePickerProps<I extends HTMLElement> = {
  initialFocus?: number;

  /**
   * The distance between the dialog and the reference element.
   * If a number is passed, it will be used for the main axis
   * If an array is passed, the first value will be used for
   * the main axis and the second value will be used for the alignment axis.
   * If a string is passed, it will be parsed as a number.
   */
  offsetDistance?: PrimitiveOffsetDistance | [number, number];
  onToggle?: (open: boolean) => void;
  onOpen?: () => void;
  onClose?: () => void;
  onBlur?: (e?: React.FocusEvent<I>) => void;
  onFocus?: (e?: React.FocusEvent<I>) => void;
  placement?: Placement;
  trigger?: React.RefObject<HTMLElement | null>;

  /**
   * An array of refs to ignore when clicking outside of the dialog.
   * This is useful for when you have elements that are outside of the dialog
   * that should not trigger the dialog to close.
   */
  clickOutsideIgnoreRefs?: MutableRefObject<any>[];
};

type TriggerProps<T> = {
  'aria-controls': string | undefined;
  'aria-expanded': boolean | undefined;
  onClick: () => void;
  onKeyDown: () => void;
  onKeyUp: () => void;
  onPointerDown: () => void;
  ref: React.RefObject<T>;
};

type DialogProps = {
  context: FloatingContext<ReferenceType>;
  descriptionId: string;
  initialFocus: number;
  labelId: string;
  nodeId: string;
  open: boolean;
};

export interface UseDataPickerDialogResponse<
  T extends HTMLElement = HTMLButtonElement,
  I extends HTMLElement = HTMLInputElement
> {
  dialogProps: DialogProps;
  triggerProps: TriggerProps<T>;
  onClose: () => void;
  toggle: () => void;
  open: boolean;
  openDialog: () => void;
  closeDialog: (customTrigger?: CustomTriggerRef) => void;
  debouncedBlur: (e?: React.FocusEvent<I>) => void;
  debouncedFocus: (e?: React.FocusEvent<I>) => void;
}

export const useDatePickerDialog = <
  T extends HTMLElement = HTMLButtonElement,
  I extends HTMLElement = HTMLInputElement
>({
  initialFocus = 0,
  offsetDistance,
  onBlur,
  onFocus,
  onToggle,
  clickOutsideIgnoreRefs = [],
  placement,
  trigger,
}: UseDatePickerProps<I>): UseDataPickerDialogResponse<T, I> => {
  const [open, setOpen] = useState(false);

  if (typeof offsetDistance === 'string') {
    offsetDistance = parseInt(offsetDistance);
  }

  const toggle = () => {
    if (open) closeDialog();
    else {
      openDialog();
    }
  };

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = (customTrigger?: CustomTriggerRef) => {
    setOpen(false);
    setTimeout(() => {
      if (customTrigger?.current) {
        customTrigger.current.focus();
      }
      if (trigger?.current) {
        trigger.current.focus();
      }
    }, 0);
  };

  useDidUpdate(() => {
    onToggle?.(open);
  }, [open]);

  const { blur, focus } = useDebouncedBlur<I, I>({
    onBlur,
    onFocus,
  });

  const nodeId = useFloatingNodeId();

  const { x, y, reference, floating, strategy, refs, update, context } = useFloating({
    nodeId,
    open,
    middleware: [
      offset(
        Array.isArray(offsetDistance)
          ? {
              mainAxis: offsetDistance[0],
              alignmentAxis: offsetDistance[1],
            }
          : offsetDistance
      ),
      flip(),
      shift(),
    ],
    placement,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([useClick(context), useRole(context)]);

  useOnClickOutside({
    active: open,
    ref: refs.floating,
    handler: useCallback(() => {
      setOpen(false);
    }, []),
    ignoreRefs: [...clickOutsideIgnoreRefs, refs.reference],
  });

  useLayoutEffect(() => {
    if (refs.reference.current && refs.floating.current && open) {
      return autoUpdate(refs.reference.current, refs.floating.current, update);
    }
    return;
  }, [open, update, refs.reference, refs.floating]);

  return {
    toggle,
    closeDialog,
    openDialog,
    open,
    debouncedBlur: (e) => blur(e),
    debouncedFocus: (e) => focus(e),
    onClose: () => setOpen(false),
    triggerProps: getReferenceProps({ ref: reference }) as TriggerProps<T>,
    dialogProps: {
      context,
      descriptionId: `${nodeId}-description`,
      initialFocus,
      labelId: `${nodeId}-label`,
      open,
      nodeId,
      ...getFloatingProps({
        onKeyDown: (e: React.KeyboardEvent) => {
          if (e.key === KeyNames.Escape) closeDialog();
        },
        ref: floating,
        style: {
          margin: 'unset',
          position: strategy,
          top: y ?? 'unset',
          left: x ?? 'unset',
        },
      }),
    },
  };
};
