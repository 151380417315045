import { css } from '@emotion/react';
import React, { HTMLProps } from 'react';

interface Props extends HTMLProps<HTMLAnchorElement> {
  children: React.ReactNode;
  trackingId?: string;
}

export const NakedA = ({ children, trackingId, ...rest }: Props) => (
  <a
    css={css`
      color: currentColor;
      text-decoration: none;
    `}
    data-trackingid={trackingId}
    {...rest}
  >
    {children}
  </a>
);
