import { FilterHeader, FilterMapComponent } from '../atoms';
import { getDefaultFieldValue, isFilterDisabled } from '../utils';
import { useStyles } from '../../../../use-styles';

export const Filter = ({
  render,
  filterConfig,
  filterValue,
  seedValues,
  setFilter,
  id,
  getFieldProps,
  options,
  gotoPage,
}) => {
  const filterSectionStyles = useStyles('TableCommonStyles', 'tableFilterStyles', {
    type: 'filterSection',
  });

  return (
    <div css={filterSectionStyles} key={id}>
      <FilterHeader
        render={render}
        label={filterConfig?.label}
        disabled={isFilterDisabled(filterValue)}
        onClick={() => {
          if (filterConfig) {
            seedValues({
              [id]: getDefaultFieldValue(filterConfig.type),
            });
          }
          gotoPage(0);
          setFilter();
        }}
      />
      <FilterMapComponent
        type={filterConfig?.type}
        fieldProps={getFieldProps(id)}
        label={filterConfig?.label ?? render('Header')}
        options={options}
      />
    </div>
  );
};
