/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const DeleteIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M15.6620581,12.3942911 L17.0762716,13.8085047 C17.564427,14.29666 17.564427,15.0881163 17.0762716,15.5762716 C16.5881163,16.064427 15.79666,16.064427 15.3085047,15.5762716 L13.8942911,14.1620581 L12.4800776,15.5762716 C11.9919222,16.064427 11.200466,16.064427 10.7123106,15.5762716 C10.2241552,15.0881163 10.2241552,14.29666 10.7123106,13.8085047 L12.1265242,12.3942911 L10.7123106,10.9800776 C10.2241552,10.4919222 10.2241552,9.70046597 10.7123106,9.2123106 C11.200466,8.72415524 11.9919222,8.72415524 12.4800776,9.2123106 L13.8942911,10.6265242 L15.3085047,9.2123106 C15.79666,8.72415524 16.5881163,8.72415524 17.0762716,9.2123106 C17.564427,9.70046597 17.564427,10.4919222 17.0762716,10.9800776 L15.6620581,12.3942911 Z M9.26116282,3.75 L19.4175644,3.75 C21.2105203,3.75 22.6647778,5.20200214 22.6675605,6.99495589 L22.6830849,16.9949559 C22.6858667,18.7898792 21.2330522,20.2472103 19.4381289,20.25 L19.4330848,20.25 L9.26490964,20.25 C8.40267611,20.25 7.57578106,19.9073684 6.966044,19.2973282 L1.97054631,14.2971122 C0.702366149,13.0283014 0.70206558,10.9719142 1.9696773,9.70293047 L6.9618231,4.70314631 C7.57141708,4.09289253 8.39860006,3.75 9.26116282,3.75 Z M8.73074344,6.4697591 L3.73859764,11.4695433 C3.44602629,11.7624313 3.44609565,12.2369822 3.73895006,12.5299823 L8.73444775,17.5301982 C8.87511073,17.6709312 9.06593267,17.75 9.26490964,17.75 L19.4330848,17.75 L19.4342488,17.75 C19.8484619,17.7493562 20.1837268,17.413049 20.1830848,16.998836 L20.1675635,6.99883597 C20.1669214,6.58507742 19.8313235,6.25 19.4175644,6.25 L9.26116282,6.25 C9.06210988,6.25 8.8712215,6.32912904 8.73074344,6.4697591 Z'
    />
  </Icon>
);
