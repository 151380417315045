import { Icon, IconProps } from '../Icon.component';

export const DocumentErrorIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M8.48987 1.9873H13.0148C14.2851 1.9873 15.5078 2.47081 16.4345 3.33962L18.9299 5.67905C19.9381 6.62428 20.5102 7.94469 20.5102 9.32673V17.0127C20.5102 19.7741 18.2716 22.0127 15.5102 22.0127H8.48987C5.72844 22.0127 3.48987 19.7741 3.48987 17.0127V6.98731C3.48987 4.22588 5.72844 1.9873 8.48987 1.9873ZM8.48987 4.4873C7.10916 4.4873 5.98987 5.60659 5.98987 6.98731V17.0127C5.98987 18.3934 7.10915 19.5127 8.48987 19.5127H15.5102C16.8909 19.5127 18.0102 18.3934 18.0102 17.0127V9.32673C18.0102 8.63571 17.7242 7.97551 17.22 7.50289L14.7246 5.16346C14.2613 4.72906 13.6499 4.4873 13.0148 4.4873H8.48987Z'
    />
    <path
      fillRule='evenodd'
      d='M8.33658 14.0457C7.88973 14.4926 7.88973 15.2172 8.33658 15.6641C8.78344 16.111 9.50794 16.111 9.9548 15.6641L12 13.6187L14.0452 15.6641C14.4921 16.111 15.2166 16.111 15.6634 15.6641C16.1103 15.2172 16.1103 14.4927 15.6634 14.0458L13.6182 12.0004L15.6649 9.95354C16.1117 9.50664 16.1117 8.78207 15.6649 8.33518C15.218 7.88828 14.4935 7.88827 14.0466 8.33517L12 10.382L9.95336 8.33519C9.5065 7.88829 8.782 7.88829 8.33514 8.33519C7.88829 8.78209 7.88829 9.50666 8.33514 9.95356L10.3818 12.0004L8.33658 14.0457Z'
    />
  </Icon>
);
