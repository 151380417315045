/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const AnalyticsInfoIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M9.91624277,7.98188965 L13.032632,19.5901887 C13.2474277,20.3902851 14.0701603,20.8647652 14.8702567,20.6499696 C15.3430435,20.5230442 15.7241509,20.1734013 15.8912455,19.7132744 L18.3180739,13.0305396 L20.6887207,13.0305396 C21.3790766,13.0305396 21.9387207,12.4708956 21.9387207,11.7805396 C21.9387207,11.0901837 21.3790766,10.5305396 20.6887207,10.5305396 L17.6173257,10.5305396 C16.9863294,10.5305396 16.4227983,10.925433 16.207415,11.518532 L14.6244476,15.8775348 L11.5327326,4.36114553 C11.4082338,3.89739755 11.0692437,3.52128115 10.6208815,3.34942819 C9.84732976,3.05293321 8.97988554,3.43966409 8.68339056,4.21321578 L6.26202063,10.5305396 L3.25,10.5305396 C2.55964406,10.5305396 2,11.0901837 2,11.7805396 C2,12.4708956 2.55964406,13.0305396 3.25,13.0305396 L6.94966975,13.0305396 C7.57096038,13.0305396 8.1279484,12.6475273 8.350309,12.0673913 L9.91624277,7.98188965 Z'
      transform='translate(11.969360, 11.975651) scale(-1, 1) translate(-11.969360, -11.975651) '
    />
  </Icon>
);
