import { Icon, IconProps } from '../Icon.component';

export const UserMinusIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} fill='none' viewBox='0 0 24 24'>
    <path
      d='M15 20V18.3333C15 17.4493 14.6348 16.6014 13.9846 15.9763C13.3345 15.3512 12.4528 15 11.5333 15H5.46667C4.54725 15 3.66549 15.3512 3.01536 15.9763C2.36524 16.6014 2 17.4493 2 18.3333V20'
      stroke='#202328'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M8.5 11C10.433 11 12 9.433 12 7.5C12 5.567 10.433 4 8.5 4C6.567 4 5 5.567 5 7.5C5 9.433 6.567 11 8.5 11Z'
      stroke='#202328'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path d='M22 11H16' stroke='#202328' strokeWidth='2.5' strokeLinecap='round' strokeLinejoin='round' />
  </Icon>
);
