/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const CalendarConfirmedIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M7.25 2C6.55964 2 6 2.55964 6 3.25V3.75H4.66667C3.05913 3.75 1.75 5.04372 1.75 6.64706V17.3529C1.75 18.9563 3.05913 20.25 4.66667 20.25H10.5C11.1904 20.25 11.75 19.6904 11.75 19C11.75 18.3096 11.1904 17.75 10.5 17.75H4.66667C4.43326 17.75 4.25 17.5689 4.25 17.3529V11.75H16.75V12.53C16.75 13.2204 17.3096 13.78 18 13.78C18.6904 13.78 19.25 13.2204 19.25 12.53V6.64706C19.25 5.04372 17.9409 3.75 16.3333 3.75H15V3.25C15 2.55964 14.4404 2 13.75 2C13.0596 2 12.5 2.55964 12.5 3.25V3.75H8.5V3.25C8.5 2.55964 7.94036 2 7.25 2ZM16.75 9.25V6.64706C16.75 6.4311 16.5667 6.25 16.3333 6.25H15V6.75C15 7.44036 14.4404 8 13.75 8C13.0596 8 12.5 7.44036 12.5 6.75V6.25H8.5V6.75C8.5 7.44036 7.94036 8 7.25 8C6.55964 8 6 7.44036 6 6.75V6.25H4.66667C4.43326 6.25 4.25 6.4311 4.25 6.64706V9.25H16.75ZM13.4213 17.4005C12.9775 17.9293 13.0465 18.7178 13.5754 19.1615L16.6395 21.7327C17.1684 22.1764 17.9568 22.1075 18.4006 21.5786L22.5787 16.5993C23.0224 16.0705 22.9535 15.282 22.4246 14.8383C21.8958 14.3945 21.1073 14.4635 20.6636 14.9924L17.289 19.0141L15.1823 17.2464C14.6535 16.8027 13.865 16.8717 13.4213 17.4005Z'
    />
  </Icon>
);
