import { Icon, IconProps } from '../Icon.component';

export const CrownIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 22 17'>
    <path
      xmlns='http://www.w3.org/2000/svg'
      d='M1.42291 5.20466L4.47782 14.6538C4.54454 14.8602 4.73669 15 4.95358 15H17.0464C17.2633 15 17.4555 14.8602 17.5222 14.6538L20.5493 5.2906C20.696 4.83683 20.1846 4.45481 19.7911 4.72422L15.7948 7.46024C15.5581 7.62232 15.2338 7.55238 15.0849 7.30712L11.6838 1.70414C11.4891 1.3834 11.0237 1.3834 10.829 1.70414L7.41719 7.32479C7.27239 7.56333 6.96035 7.63718 6.72398 7.48884L2.16445 4.62735C1.76896 4.37914 1.27928 4.76038 1.42291 5.20466Z'
      strokeWidth='2.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
