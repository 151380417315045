import { TableData } from './../table-data-type';
import Papa from 'papaparse';
import { ColumnInstance } from 'react-table';
import { FileTypes } from '../table-types';

type ExportData<T extends TableData> = {
  columns: ColumnInstance<T>[];
  data: T[][];
  fileType: FileTypes;
  fileName: string;
};

export const getExportFileBlob = <T extends TableData>({ columns, data, fileType }: ExportData<T>) => {
  const headerNames = columns.map((column) => column.exportValue);

  switch (fileType) {
    case 'csv': {
      const csvString = Papa.unparse({ fields: headerNames, data });
      return new Blob([csvString], { type: 'text/csv' });
    }

    default: {
      return false;
    }
  }
};
