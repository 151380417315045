import { useCallback, useEffect, useRef } from 'react';

type AnyFn = (...args: any[]) => void;
export function useDebouncedFn<const T extends AnyFn>(callback: T, delay: number) {
  const callbackRef = useRef(callback);
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const fn = (...args: Parameters<T>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      if (timeoutRef.current) {
        callbackRef.current(...args);
      }
    }, delay);
  };
  return useCallback(fn, [delay]);
}
