/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const HomeIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M16.25,20.6655113 L19.75,20.6655113 L19.75,10.4768438 L12,3.58795495 L4.25,10.4768438 L4.25,20.6655113 L7.75,20.6655113 L7.75,12.1655113 C7.75,11.3370841 8.42157288,10.6655113 9.25,10.6655113 L14.75,10.6655113 C15.5784271,10.6655113 16.25,11.3370841 16.25,12.1655113 L16.25,20.6655113 Z M11.0034542,1.12888602 C11.5717833,0.623704659 12.4282167,0.623704659 12.9965458,1.12888602 L21.4948186,8.68290636 C21.9751675,9.10988309 22.25,9.72189225 22.25,10.3645773 L22.25,20.9155113 C22.25,22.1581519 21.2426407,23.1655113 20,23.1655113 L4,23.1655113 C2.75735931,23.1655113 1.75,22.1581519 1.75,20.9155113 L1.75,10.3645773 C1.75,9.72189225 2.02483254,9.10988309 2.50518136,8.68290636 L11.0034542,1.12888602 Z M10.25,13.1655113 L10.25,20.6655113 L13.75,20.6655113 L13.75,13.1655113 L10.25,13.1655113 Z'
    />
  </Icon>
);
