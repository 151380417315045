import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

import {
  CheckboxField,
  ChecklistField,
  DateField,
  DatePickerField,
  DateRangeField,
  DropdownField,
  EmailField,
  MoneyField,
  MultiselectField,
  NumberField,
  OptionSwitchField,
  PhoneField,
  PostalCodeField,
  RadioField,
  SearchField,
  SelectListField,
  SwitchField,
  TimeField,
  TimeRangeField,
} from '../../../';

export const fieldComponentsMap = {
  checkbox: CheckboxField,
  multiselect: MultiselectField,
  checklist: ChecklistField,
  selectlist: SelectListField,
  optionswitch: OptionSwitchField,
  dropdown: DropdownField,
  switch: SwitchField,
  text: SearchField,
  radio: RadioField,
  datePicker: DatePickerField,
  dateRange: DateRangeField,
  date: DateField,
  email: EmailField,
  money: MoneyField,
  phone: PhoneField,
  postalCode: PostalCodeField,
  time: TimeField,
  timeRange: TimeRangeField,
  number: NumberField,
};

const fieldHasOptions = {
  multiselect: true,
  checklist: true,
  optionswitch: true,
  dropdown: true,
  selectlist: true,
  radio: true,
};

const extraProps = {
  radio: { labelPlacement: 'left', label: undefined },
  checklist: { labelPlacement: 'left', label: undefined },
  optionswitch: { label: undefined },
};

export const FieldFilterWithOptions = ({ type, fieldProps, label, options }) => {
  const Component = fieldComponentsMap[type];
  return (
    <Component {...fieldProps} label={label} {...extraProps[type]}>
      {options.map((data, idx) => {
        switch (type) {
          case 'radio':
            return (
              <Component.Radio key={`${data}-${idx}`} value={data}>
                {data}
              </Component.Radio>
            );
          case 'checklist':
            return (
              <Component.Option key={`${data}-${idx}`} name={data}>
                {data}
              </Component.Option>
            );

          default:
            return (
              <Component.Option key={data} value={data}>
                {data}
              </Component.Option>
            );
        }
      })}
    </Component>
  );
};

export const FilterMapComponent = ({ type, fieldProps, label, options }) => {
  const hasOptions = !!fieldHasOptions[type];
  const Component = fieldComponentsMap[type];

  return hasOptions ? (
    <section
      css={css`
        margin-bottom: ${theme.spacing(2)};
      `}
    >
      <FieldFilterWithOptions type={type} fieldProps={fieldProps} label={label} options={options} />
    </section>
  ) : (
    <section
      css={css`
        margin-bottom: ${theme.spacing(2)};
      `}
    >
      <Component {...fieldProps} label={label} />{' '}
    </section>
  );
};
