/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const VolumeIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M7.56152368,6.75 L13.4691312,2.02391399 C14.2875854,1.36915064 15.5,1.95186724 15.5,3 L15.5,20.5 C15.5,21.5481328 14.2875854,22.1308494 13.4691312,21.476086 L7.56152368,16.75 L3,16.75 C2.30964406,16.75 1.75,16.1903559 1.75,15.5 L1.75,8 C1.75,7.30964406 2.30964406,6.75 3,6.75 L7.56152368,6.75 Z M13,5.60078106 L8.78086881,8.97608601 C8.5592275,9.15339906 8.28383937,9.25 8,9.25 L4.25,9.25 L4.25,14.25 L8,14.25 C8.28383937,14.25 8.5592275,14.3466009 8.78086881,14.523914 L13,17.8992189 L13,5.60078106 Z M19.0409831,8.20875 C18.5529014,7.72052093 18.5530209,6.92906471 19.04125,6.44098307 C19.5294791,5.95290142 20.3209353,5.95302093 20.8090169,6.44125 C23.7368815,9.36999889 23.7368815,14.1175011 20.8090169,17.04625 C20.3209353,17.5344791 19.5294791,17.5345986 19.04125,17.0465169 C18.5530209,16.5584353 18.5529014,15.7669791 19.0409831,15.27875 C20.9928928,13.3262507 20.9928928,10.1612493 19.0409831,8.20875 Z'
    />
  </Icon>
);
