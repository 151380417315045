/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const UnmarkIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M7.05289714,0.750000001 C7.74325307,0.750000001 8.30289714,1.30964406 8.30289714,2 C8.30289714,2.69035594 7.74325307,3.25 7.05289714,3.25 L7.05289714,3.25 L4,3.25 C3.58578644,3.25 3.25,3.58578644 3.25,4 L3.25,4 L3.25,18 C3.25,18.4142136 3.58578644,18.75 4,18.75 L4,18.75 L18,18.75 C18.4142136,18.75 18.75,18.4142136 18.75,18 L18.75,18 L18.75,15.0270182 C18.75,14.3366623 19.3096441,13.7770182 20,13.7770182 C20.6903559,13.7770182 21.25,14.3366623 21.25,15.0270182 L21.25,15.0270182 L21.25,18 C21.25,19.7949254 19.7949254,21.25 18,21.25 L18,21.25 L4,21.25 C2.20507456,21.25 0.75,19.7949254 0.75,18 L0.75,18 L0.75,4 C0.75,2.20507456 2.20507456,0.750000001 4,0.750000001 L4,0.750000001 Z M11.6442911,3.08058262 L14.472,5.909 L17.3011454,3.08058262 C17.7893007,2.59242725 18.580757,2.59242725 19.0689123,3.08058262 C19.5570677,3.56873798 19.5570677,4.36019421 19.0689123,4.84834957 L16.24,7.676 L19.0689123,10.5052038 C19.5570677,10.9933592 19.5570677,11.7848154 19.0689123,12.2729708 C18.580757,12.7611261 17.7893007,12.7611261 17.3011454,12.2729708 L14.472,9.444 L11.6442911,12.2729708 C11.1561358,12.7611261 10.3646795,12.7611261 9.87652416,12.2729708 C9.3883688,11.7848154 9.3883688,10.9933592 9.87652416,10.5052038 L12.705,7.676 L9.87652416,4.84834957 C9.3883688,4.36019421 9.3883688,3.56873798 9.87652416,3.08058262 C10.3646795,2.59242725 11.1561358,2.59242725 11.6442911,3.08058262 Z'
    />
  </Icon>
);
