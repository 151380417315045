/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const MarkReadIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M10.8248669,2.25269348 C11.5482616,1.81132514 12.4582877,1.81383478 13.179237,2.25918629 L13.179237,2.25918629 L21.9259759,7.66230331 C22.2853811,7.88431841 22.5750233,8.20303073 22.761757,8.58196783 C22.9360464,8.93565205 23.0088879,9.31291933 22.9915036,9.68074173 C22.9970417,9.72452984 23.0000097,9.76906919 23,9.81385221 L23,19.5 C23,20.6045695 22.1045695,21.5 21,21.5 L3,21.5 C1.8954305,21.5 1,20.6045695 1,19.5 L1,9.48818372 C0.99999507,9.43315977 1.00548769,9.3794197 1.01595858,9.32748269 C1.07221922,8.63325087 1.44820036,7.97372524 2.08863236,7.58297531 L2.08863236,7.58297531 Z M12.51734,4.78865536 C12.1969007,4.59071638 11.7924236,4.58961594 11.4709121,4.78580839 L11.4709121,4.78580839 L3.775,9.482 L11.5597282,13.3814592 C11.839587,13.5216436 12.1689397,13.5227036 12.449695,13.3843233 L12.449695,13.3843233 L20.227,9.551 Z'
    />
  </Icon>
);
