/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const InfoIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M13.1957391,15.3000864 C13.1957391,15.9003959 12.6360951,16.3870429 11.9457391,16.3870429 C11.2553832,16.3870429 10.6957391,15.9003959 10.6957391,15.3000864 L10.6957391,11.5000864 C10.6957391,10.8997769 11.2553832,10.4131298 11.9457391,10.4131298 C12.6360951,10.4131298 13.1957391,10.8997769 13.1957391,11.5000864 L13.1957391,15.3000864 Z M10.6956522,8.43231838 C10.6956522,7.74104187 11.2546522,7.18181818 11.9456522,7.18181818 C12.6356522,7.18181818 13.1956522,7.74104187 13.1956522,8.43231838 C13.1956522,9.12259449 12.6356522,9.68181818 11.9456522,9.68181818 C11.2546522,9.68181818 10.6956522,9.12259449 10.6956522,8.43231838 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z M12,20.5555556 C16.7251029,20.5555556 20.5555556,16.7251029 20.5555556,12 C20.5555556,7.27489714 16.7251029,3.44444444 12,3.44444444 C7.27489714,3.44444444 3.44444444,7.27489714 3.44444444,12 C3.44444444,16.7251029 7.27489714,20.5555556 12,20.5555556 Z'
    />
  </Icon>
);
