/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const GraduationCapIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M11.4855 2.14251C11.8021 1.9525 12.1978 1.9525 12.5145 2.14251L22.5144 8.14251C22.8156 8.32323 22.9999 8.64874 22.9999 9V16C22.9999 16.5523 22.5522 17 21.9999 17C21.4476 17 20.9999 16.5523 20.9999 16V10.7662L20.0001 11.3661V16C20.0001 16.3513 19.8158 16.6768 19.5146 16.8575L12.5145 21.0575C12.1979 21.2475 11.8022 21.2475 11.4855 21.0575L4.4855 16.8575C4.1843 16.6768 4 16.3513 4 16V11.3662L1.4855 9.85749C1.1843 9.67677 1 9.35126 1 9C1 8.64874 1.1843 8.32323 1.4855 8.14251L11.4855 2.14251ZM6 12.5662V15.4338L12 19.0339L18.0001 15.4338V12.5661L12.5145 15.8575C12.1978 16.0475 11.8021 16.0475 11.4855 15.8575L6 12.5662ZM20.0563 9L12 13.8338L3.94364 9L12 4.16619L20.0563 9Z'
    />
  </Icon>
);
