/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const MoonIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M10.5572053,1.77532266 C10.823868,1.72087763 11.110109,1.75273856 11.3717136,1.88537011 C11.9874549,2.19754632 12.233543,2.94977208 11.9213668,3.56551337 C11.4820093,4.43210891 11.25,5.39167645 11.25,6.38712894 C11.25,9.83890862 14.0482203,12.6371289 17.5,12.6371289 C18.5052954,12.6371289 19.4739637,12.4004982 20.3471029,11.9527666 C20.9614029,11.6377635 21.7147518,11.8803916 22.0297548,12.4946915 C22.189849,12.8068976 22.205908,13.1550199 22.1023435,13.4632606 C22.1060235,13.5540281 22.0998028,13.6466078 22.0827926,13.739714 C21.1994221,18.5748827 16.9704302,22.1371289 12,22.1371289 C6.33908131,22.1371289 1.75,17.5480476 1.75,11.8871289 C1.75,6.88501743 5.35704957,2.63749707 10.2321702,1.78922065 C10.3420704,1.7700979 10.4511131,1.76595612 10.5572053,1.77532266 Z M8.89711233,4.78202113 C6.1398901,5.98631448 4.25,8.73955107 4.25,11.8871289 C4.25,16.1673357 7.71979319,19.6371289 12,19.6371289 C15.1462091,19.6371289 17.9003527,17.7484928 19.1051018,14.9900173 C18.5802908,15.0873875 18.0435117,15.1371289 17.5,15.1371289 C12.6675084,15.1371289 8.75,11.2196205 8.75,6.38712894 C8.75,5.84363203 8.79973755,5.30685219 8.89711233,4.78202113 Z'
    />
  </Icon>
);
