/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const WarningIndicator: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <circle cx='12' cy='12' r='12' fill='#FFC700' />
    <path
      d='M12 4.75C11.3096 4.75 10.75 5.30964 10.75 6V13.5C10.75 14.1904 11.3096 14.75 12 14.75C12.6904 14.75 13.25 14.1904 13.25 13.5V6C13.25 5.30964 12.6904 4.75 12 4.75Z'
      fill='#7A4900'
    />
    <path
      d='M12 19.25C12.6904 19.25 13.25 18.6904 13.25 18C13.25 17.3096 12.6904 16.75 12 16.75C11.3096 16.75 10.75 17.3096 10.75 18C10.75 18.6904 11.3096 19.25 12 19.25Z'
      fill='#7A4900'
    />
  </Icon>
);
