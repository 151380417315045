import { useCallback, useRef, MutableRefObject } from 'react';
import { CellPositionCord, CellPositionsConfig } from '../table-types';
import { keyPressHandler, isCopy } from './utils';

export const useEventListenersOnTableBody = ({
  currentCellPositionRef,
  cellPositionsConfig,
  isFocusable,
  tableId,
  rows,
}: {
  currentCellPositionRef: MutableRefObject<CellPositionCord>;
  cellPositionsConfig: CellPositionsConfig;
  isFocusable?: boolean;
  tableId: string;
  rows: any[];
}) => {
  const bodyRef = useRef<HTMLDivElement | null>(null);

  const handleKeyDown = (e: KeyboardEvent) => {
    let keyPressed = e.key;
    if (isCopy(e)) keyPressed = 'Copy';
    const handler = keyPressHandler(keyPressed, e);
    if (handler) {
      handler({ currentCellPositionRef, cellPositionsConfig, tableId, rows });
    }

    if (bodyRef?.current && currentCellPositionRef.current) {
      const { x, y } = currentCellPositionRef.current;
      const cellElem = document.querySelector(`[table-cell-position="${tableId}-${x}-${y}"]`) as HTMLElement;

      const bodyElem = document.querySelector(`#${tableId}`) as Element;
      const stickyColumns = document.querySelectorAll<HTMLElement>(
        `#${tableId} [role="columnheader"][data-sticky-td="true"]`
      );

      const stickyElem = stickyColumns ? Array.from(stickyColumns).reduce((acc, elem) => acc + elem.clientWidth, 0) : 0;

      const toScrollTo =
        cellElem.getBoundingClientRect().left +
        bodyElem.scrollLeft -
        bodyElem.getBoundingClientRect().left -
        stickyElem;

      bodyElem.scrollTo({
        left: toScrollTo,
        behavior: 'smooth',
      });
    }
  };

  const bodyWithListenersRefCb: React.RefCallback<HTMLDivElement> = useCallback(
    (bodyNode: HTMLDivElement | null) => {
      if (bodyRef.current) {
        bodyRef.current.removeEventListener('keydown', handleKeyDown);
      }

      if (bodyNode) {
        if (isFocusable) {
          bodyNode.addEventListener('keydown', handleKeyDown);
        }
      }

      bodyRef.current = bodyNode;
    },
    [isFocusable, rows]
  );

  return bodyWithListenersRefCb;
};
