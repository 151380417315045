import { Icon, IconProps } from '../Icon.component';

export const AttachmentIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 3C9.65279 3 7.75 4.90279 7.75 7.25L7.75 18.5C7.75 19.8807 8.86929 21 10.25 21C11.6307 21 12.75 19.8807 12.75 18.5L12.75 8.25C12.75 7.83579 12.4142 7.5 12 7.5C11.5858 7.5 11.25 7.83579 11.25 8.25L11.25 16.5C11.25 17.0523 10.8023 17.5 10.25 17.5C9.69772 17.5 9.25 17.0523 9.25 16.5L9.25 8.25C9.25 6.73122 10.4812 5.5 12 5.5C13.5188 5.5 14.75 6.73122 14.75 8.25L14.75 18.5C14.75 20.9853 12.7353 23 10.25 23C7.76472 23 5.75 20.9853 5.75 18.5L5.75 7.25C5.75 3.79822 8.54822 1 12 1C15.4518 0.999999 18.25 3.79822 18.25 7.25L18.25 16.5C18.25 17.0523 17.8023 17.5 17.25 17.5C16.6977 17.5 16.25 17.0523 16.25 16.5L16.25 7.25C16.25 4.90279 14.3472 3 12 3Z'
    />
  </Icon>
);
