import { RadioOption } from './radio-option.component';
import type { BasicFormFieldProps, InlineLabelPlacement } from '../../layouts';
import { RadioFieldLayout } from '../../layouts';

type Props = Omit<BasicFormFieldProps<'radio'>, 'label'> & {
  children: Array<React.ReactElement | null>;
  label?: string;
  labelPlacement?: InlineLabelPlacement;
};

export const RadioField = ({ children, ...rest }: Props) => <RadioFieldLayout {...rest}>{children}</RadioFieldLayout>;

RadioField.Radio = RadioOption;
