import { css } from '@emotion/react';
import { useMemo } from 'react';
import { Row, TableInstance } from 'react-table';
import { useThemeValues } from '../../../hooks';
import { IconButton, PopoverMenuItem, PopoverMenu, usePopoverMenu } from '../../';
import { BulkAction, TableBulkActions } from '../table-types';
import { MoreIcon } from '../../../icon';
import { TableData } from '../table-data-type';

type BulkActionsProps<T extends TableData> = {
  tableInstance: TableInstance<T>;
  selectedRows: Row<T>[];
  bulkActions?: TableBulkActions<T>[];
};

export const BulkActions = <T extends TableData>({ tableInstance, selectedRows, bulkActions }: BulkActionsProps<T>) => {
  if (bulkActions?.length === 0) return null;
  const { getTriggerProps, getMenuProps, getItemProps, close } = usePopoverMenu<
    HTMLButtonElement | HTMLAnchorElement,
    false,
    HTMLMenuElement,
    HTMLAnchorElement | HTMLButtonElement
  >({
    interactionOptions: {
      listNavigation: {
        openOnArrowKeyDown: false,
      },
    },
    placement: 'right-start',
  });
  const { isMobile } = tableInstance;
  const { spacing } = useThemeValues();
  const selectedRowsData = useMemo(
    () => selectedRows.map((selectedRow) => selectedRow.original),
    [selectedRows]
  ) as T[];

  const renderActions = () => {
    return bulkActions?.map(({ onClick, Icon, ...rest }: TableBulkActions<T>, id) => {
      const { hide, ...dynamicProps } = Object.entries(rest).reduce(
        (acc, [key, value]) => ({
          ...acc,
          ...(key !== 'onClick' ? { [key]: typeof value !== 'function' ? value : value(selectedRowsData) } : {}),
        }),
        {} as Omit<BulkAction<T>, 'onClick' | 'Icon'>
      );

      const itemProps = getItemProps({
        index: id,
        onClick: (e) => {
          e.stopPropagation();
          onClick?.(selectedRows, selectedRowsData);
          close();
        },
      });

      if (isMobile && !hide) {
        return (
          <PopoverMenuItem
            key={dynamicProps.label + id}
            Icon={Icon}
            {...itemProps}
            {...(dynamicProps as any)} //TODO: Fix this assertion
          >
            {dynamicProps.label}
          </PopoverMenuItem>
        );
      }

      return !hide ? (
        <IconButton
          key={id}
          css={css`
            margin: 0 5px;
          `}
          color='light'
          onClick={(e) => {
            e.stopPropagation();
            onClick?.(selectedRows, selectedRowsData);
          }}
          {...dynamicProps}
          label={dynamicProps.label}
          showLabelOnHover
        >
          <Icon />
        </IconButton>
      ) : null;
    });
  };

  const triggerProps = getTriggerProps();
  const menuProps = getMenuProps();

  return (
    <div
      css={css`
        margin-left: ${isMobile ? spacing(1) : spacing(2)};
        display: flex;
        align-items: center;
      `}
    >
      {isMobile ? (
        <>
          <IconButton
            css={css`
              margin-left: 8px;
            `}
            color='light'
            label='Action menu'
            {...triggerProps}
          >
            <MoreIcon size={20} />
          </IconButton>
          <PopoverMenu {...menuProps} title='' css={{ maxWidth: 320 }}>
            {renderActions()}
          </PopoverMenu>
        </>
      ) : (
        renderActions()
      )}
    </div>
  );
};
