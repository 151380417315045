import { css } from '@emotion/react';
import type { TableInstance } from 'react-table';
import { FilterContainer } from './filter.container';

import {
  TableIconActions,
  TableButtonActions,
  TableActionIcon,
  TableActionButton,
  ManualFilters,
} from '../table-types';
import { PrimaryButton, SecondaryButton, PopoverMenuItem, usePopoverMenu, PopoverMenu } from '../../';
import { IconButton } from '../../icon-button';
import { useThemeValues } from '../../../hooks';
import { MoreIcon } from '../../../icon';
import { FilterColumnsContainer } from './filter-columns.container';
import { TableData } from '../table-data-type';
import { onlyText } from 'react-children-utilities';

type TableActionsProps<T extends TableData> = {
  tableInstance: TableInstance<T>;
  isFilterEnabled?: boolean;
  tableActions?: (TableIconActions<T> | TableButtonActions<T>)[];
  manualFiltersRender?: ManualFilters<T>;
};

export const TableActions = <T extends TableData>({
  tableInstance,
  isFilterEnabled,
  tableActions,
  manualFiltersRender,
}: TableActionsProps<T>) => {
  const { spacing } = useThemeValues();
  const { isMobile, isLoading, uniqueFilterColumnsId, hasFilterColumns, tableTrackingIds } = tableInstance;
  const { getTriggerProps, getMenuProps, getItemProps, close } = usePopoverMenu<
    HTMLButtonElement | HTMLAnchorElement,
    false,
    HTMLMenuElement,
    HTMLAnchorElement | HTMLButtonElement
  >({
    interactionOptions: {
      listNavigation: {
        openOnArrowKeyDown: false,
      },
    },
    placement: 'left-start',
  });

  const triggerProps = getTriggerProps();
  const menuProps = getMenuProps();

  const renderActions = () => {
    return tableActions?.map((tableAction: TableIconActions<T> | TableButtonActions<T>, id) => {
      const { onClick, label, Icon, ...rest } = tableAction;

      const itemProps = getItemProps({
        index: id,
        onClick: (e) => {
          e.stopPropagation();
          onClick?.(tableInstance);
          close();
        },
      });

      if (tableAction?.type === 'button') {
        const { onClick, label, size, Icon, ref, ...rest } = tableAction;
        const { hide, variant, disabled, ...dynamicProps } = Object.entries(rest).reduce(
          (acc, [key, value]) => ({
            ...acc,
            ...{ [key]: typeof value !== 'function' ? value : value(tableInstance) },
          }),
          {} as Omit<TableActionButton<T>, 'onClick' | 'label' | 'size' | 'Icon'>
        );

        if (isMobile && !hide && tableActions?.length > 1) {
          return (
            <PopoverMenuItem
              key={onlyText(label as React.ReactNode) + id}
              Icon={Icon}
              disabled={isLoading || disabled}
              // TODO: Fix this assertion
              ref={ref as any}
              {...itemProps}
              {...dynamicProps}
            >
              {label}
            </PopoverMenuItem>
          );
        }

        return !hide ? (
          variant === 'secondary' ? (
            <SecondaryButton
              key={id}
              size={size ?? 'tiny'}
              css={css`
                margin-left: ${spacing(2)};
              `}
              disabled={isLoading || disabled}
              trackingId={tableTrackingIds.dynamicTableActionItem?.(onlyText(label as React.ReactNode))}
              onClick={(e) => {
                e.stopPropagation();
                onClick?.(tableInstance);
              }}
              {...dynamicProps}
            >
              {label}
            </SecondaryButton>
          ) : (
            <PrimaryButton
              key={id}
              size={size ?? 'tiny'}
              css={css`
                margin-left: ${spacing(2)};
              `}
              trackingId={tableTrackingIds.dynamicTableActionItem?.(onlyText(label as React.ReactNode))}
              disabled={isLoading || disabled}
              onClick={(e) => {
                e.stopPropagation();
                onClick?.(tableInstance);
              }}
              {...dynamicProps}
            >
              {label}
            </PrimaryButton>
          )
        ) : null;
      }

      const { hide, type, disabled, ...dynamicProps } = Object.entries(rest).reduce(
        (acc, [key, value]) => ({
          ...acc,
          ...{ [key]: typeof value !== 'function' ? value : value(tableInstance) },
        }),
        {} as Omit<TableActionIcon<T>, 'onClick' | 'Icon' | 'label'>
      );

      if (isMobile && !hide && tableActions?.length > 1) {
        return (
          <PopoverMenuItem
            key={onlyText(label as React.ReactNode) + id}
            Icon={Icon}
            disabled={isLoading || disabled}
            trackingId={tableTrackingIds.dynamicTableActionItem?.(onlyText(label as React.ReactNode))}
            {...itemProps}
            {...(dynamicProps as any)} //TODO: Fix this assertion
          >
            {label}
          </PopoverMenuItem>
        );
      }

      return (
        !hide && (
          <IconButton
            key={id}
            css={css`
              margin-left: ${spacing(1)};
            `}
            color='light'
            disabled={isLoading || disabled}
            onClick={(e) => {
              e.stopPropagation();
              onClick?.(tableInstance);
            }}
            {...dynamicProps}
            label={onlyText(label as React.ReactNode)}
            trackingId={tableTrackingIds.dynamicTableActionItem?.(onlyText(label as React.ReactNode))}
            showLabelOnHover
          >
            {Icon && <Icon />}
          </IconButton>
        )
      );
    });
  };

  const renderActionsSection = () => {
    if (isMobile && tableActions && tableActions?.length > 1) {
      return (
        <>
          <IconButton
            css={css`
              margin-left: ${spacing(1)};
            `}
            color='light'
            label='Action menu'
            disabled={isLoading}
            trackingId={tableTrackingIds.rowActionMenu}
            {...triggerProps}
          >
            <MoreIcon size={20} />
          </IconButton>
          <PopoverMenu {...menuProps} css={{ maxWidth: 320 }}>
            {renderActions()}
          </PopoverMenu>
        </>
      );
    }
    return renderActions();
  };

  const shouldRenderContainer =
    isFilterEnabled || !!tableActions?.length || (!!uniqueFilterColumnsId && hasFilterColumns);

  return shouldRenderContainer ? (
    <div
      css={css`
        padding: ${spacing(0, 1)};
        display: flex;
        align-items: center;
        flex-shrink: 1;
        justify-content: flex-end;
      `}
    >
      {isFilterEnabled && <FilterContainer manualFiltersRender={manualFiltersRender} tableInstance={tableInstance} />}
      {!!uniqueFilterColumnsId && hasFilterColumns && (
        <FilterColumnsContainer tableInstance={tableInstance} isFilterEnabled={isFilterEnabled} />
      )}
      {!!tableActions?.length && renderActionsSection()}
    </div>
  ) : null;
};
