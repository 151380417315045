import { css } from '@emotion/react';
import { Text } from '../../..';
import { useThemeValues } from '../../../../hooks';
import { NakedUl } from '../../../../naked';
import type { ListboxChildren } from '../../../listbox';

export type OptionGroupProps = {
  children: ListboxChildren;
  label: string;
};

export const OptionGroup = ({ children, label }: OptionGroupProps) => {
  const { spacing } = useThemeValues();
  return (
    <li>
      <NakedUl
        css={css`
          li {
            padding: ${spacing(1, 2, 1, 4)};
          }
        `}
      >
        <Text
          size='large'
          weight='bold'
          css={css`
            margin: ${spacing(1, 0.5, 0.5, 2)};
          `}
        >
          {label}
        </Text>
        {children}
      </NakedUl>
    </li>
  );
};
