import { SerializedStyles } from '@emotion/react';
import { ReactNode } from 'react';
import { PopoverMenu, PopoverMenuProps } from '../popover';

export const ComboboxBase = <T extends HTMLElement = HTMLElement>({
  Input,
  menuProps,
  children,
  menuStyles,
}: {
  Input: ReactNode;
  menuProps: Omit<PopoverMenuProps<T>, 'initialFocus' | 'children'>;
  children: ReactNode;
  menuStyles?: SerializedStyles;
}) => {
  return (
    <>
      {Input}
      <PopoverMenu css={menuStyles} {...menuProps} initialFocus={-1}>
        {children}
      </PopoverMenu>
    </>
  );
};
