import { isArray, isEqual } from 'lodash-es';
import { IdType, Row } from 'react-table';
import { TableData } from '../table-data-type';
import dayjs from 'dayjs';

export const BLANK_CELL_VALUES = [undefined, null, ''];
export const BLANK_OPTION_VALUE = 'null';

const getFilterRowCbWithBlanksAccountedFor = (colId, filterValues) => {
  // * 👇 If the user selected "blank" value, include all possible blank values
  const filterValuesWithBlanksAccountedFor = filterValues.reduce((acc, val) => {
    if (val === BLANK_OPTION_VALUE) {
      BLANK_CELL_VALUES.forEach((val) => acc.add(val));
    } else {
      acc.add(val);
    }

    return acc;
  }, new Set());

  const filterRowCb = (row) => {
    let currentCellValue = row.original[colId];

    if (isArray(currentCellValue) && currentCellValue.length) {
      const doesCurrentCellValueArrHaveFilterValues = Boolean(
        currentCellValue.find((subValue) => filterValuesWithBlanksAccountedFor.has(subValue))
      );
      return doesCurrentCellValueArrHaveFilterValues;
    }

    if (isEqual(currentCellValue, [])) currentCellValue = null;
    return filterValuesWithBlanksAccountedFor.has(currentCellValue);
  };

  return filterRowCb;
};

type MultiSelectFilterType = <D extends TableData>(
  rows: Array<Row<D>>,
  columnIds: Array<IdType<D>>,
  filterValue: string[]
) => Array<Row<D>>;

const filterRowsWithMultiSelectValues: MultiSelectFilterType = (rows, columnIds, filterValues) => {
  const hasSelectedValues = filterValues.length > 0;
  if (!hasSelectedValues) return rows;

  const colId = columnIds[0];
  const filterRowCb = getFilterRowCbWithBlanksAccountedFor(colId, filterValues);

  return rows.filter(filterRowCb);
};

type DateRangeFilterType = <D extends TableData>(
  rows: Array<Row<D>>,
  columnIds: Array<IdType<D>>,
  filterValue: [string, string]
) => Array<Row<D>>;

const filterDatesByRange: DateRangeFilterType = (rows, columnIds, filterValues) => {
  const hasSelectedValues = filterValues?.length > 0;
  if (!hasSelectedValues) return rows;

  const startDate = new Date(filterValues[0]).setHours(0, 0, 0, 0);
  const endDate = new Date(filterValues[1]).setHours(0, 0, 0, 0);

  const colId = columnIds[0];

  const filterRowCb = (row) => {
    const currentCellValue = row.values[colId].setHours(0, 0, 0, 0);
    const isCurrentCellValueInRange = currentCellValue >= startDate && currentCellValue <= endDate;
    return isCurrentCellValueInRange;
  };

  return rows.filter(filterRowCb);
};

type DateFilterType = <D extends TableData>(
  rows: Array<Row<D>>,
  columnIds: Array<IdType<D>>,
  filterValue: string
) => Array<Row<D>>;

const filterRowsByDate: DateFilterType = (rows, columnIds, filterValues) => {
  const hasSelectedValues = Boolean(filterValues);
  if (!hasSelectedValues) return rows;

  const colId = columnIds[0];

  const filterDate = new Date(filterValues);

  const filterRowCb = (row) => {
    const currentCellValue = row.values[colId];

    const currentCellDate = new Date(currentCellValue.toDateString());
    const isSameDate = dayjs(currentCellDate).isSame(filterDate);
    return isSameDate;
  };
  return rows.filter(filterRowCb);
};

type FilterByTimeType = <D extends TableData>(
  rows: Array<Row<D>>,
  columnIds: Array<IdType<D>>,
  filterValue: [string, string]
) => Array<Row<D>>;

const filterByTime: FilterByTimeType = (rows, columnIds, filterValues) => {
  const hasSelectedValues = Boolean(filterValues);
  if (!hasSelectedValues) return rows;

  const colId = columnIds[0];

  const filterRowCb = (row) => {
    const currentCellValue = row.values[colId];

    const isCurrentCellValueInRange = currentCellValue === filterValues;
    return isCurrentCellValueInRange;
  };
  return rows.filter(filterRowCb);
};

type FilterByTimeRangeType = <D extends TableData>(
  rows: Array<Row<D>>,
  columnIds: Array<IdType<D>>,
  filterValue: [string, string]
) => Array<Row<D>>;

const filterByTimeRange: FilterByTimeRangeType = (rows, columnIds, filterValues) => {
  const hasSelectedValues = filterValues?.length > 0;
  if (!hasSelectedValues) return rows;

  const colId = columnIds[0];
  const startTime = filterValues[0];
  const endTime = filterValues[1];

  const filterRowCb = (row) => {
    const currentCellValue = row.values[colId];

    const isCurrentCellValueInRange = currentCellValue > startTime && currentCellValue < endTime;
    return isCurrentCellValueInRange;
  };
  return rows.filter(filterRowCb);
};

export const filterTypeMap = {
  multiSelect: filterRowsWithMultiSelectValues,
  dateRange: filterDatesByRange,
  date: filterRowsByDate,
  time: filterByTime,
  timeRange: filterByTimeRange,
} as const;
