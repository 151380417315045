import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useThemeValues } from '../../../hooks';

interface Props {
  fullWidth?: boolean;
}

const MIN_WIDTH = 60;
const MAX_WIDTH = 100;

export const TableLoadingSkeleton = ({ fullWidth }: Props) => {
  const theme = useThemeValues();
  function randomWidth(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min) + min);
  }

  const randomInteger = useMemo(() => randomWidth(MIN_WIDTH, MAX_WIDTH), []);

  return (
    <div
      css={css`
        height: 1.5em;
        width: ${fullWidth ? MAX_WIDTH : randomInteger}%;
        background-color: #ededed;
        border-radius: ${theme.borderRadius.medium};
        animation: 1s loading alternate infinite;

        @keyframes loading {
          from {
            opacity: 0.3;
          }
          to {
            opacity: 1;
          }
        }
      `}
    ></div>
  );
};
