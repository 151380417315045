import React, { useLayoutEffect } from 'react';
import { css } from '@emotion/react';
import { HeaderGroup, TableInstance, Row } from 'react-table';
import { Icon } from '@frontend/icons';
import { useTooltip } from '../../../components';
import { useDidUpdate } from '../../../hooks';
import { NakedButton } from '../../../naked';
import { useStyles } from '../../../use-styles';
import { setFocusableElements } from '../hooks';
import { focusCell } from '../hooks/utils';
import { TableData } from '../table-data-type';
import { CellAlignType, CellPositionCord } from '../table-types';
import { getCellStyles, persistUserPreference } from '../utils';
import { reservedCells } from './cells';

const handleSortingLabel = (isSorted: boolean, isSortedDesc?: boolean) => {
  if (!isSorted) {
    return 'Click to sort descending';
  }
  return isSortedDesc ? 'Click to sort ascending' : 'Click to cancel sorting';
};

const getCellPositionProp = ({ tableId, x, y }: CellPositionCord) => ({
  'table-cell-position': `${tableId}-${x}-${y}`,
});

type TableHeaderProps<T extends TableData> = {
  column: HeaderGroup<T>;
  row?: Row<T>;
  tableInstance: TableInstance<T>;
  tableId: string;
  headerYPosition: number;
  headersXPosition: number;
  currentCellPositionRef: React.MutableRefObject<CellPositionCord>;
};

export const TableHeader = <T extends TableData>({
  column,
  row,
  tableInstance,
  tableId,
  headerYPosition,
  headersXPosition,
  currentCellPositionRef,
}: TableHeaderProps<T>) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'top',
  });

  const {
    id: columnId,
    getHeaderProps,
    getSortByToggleProps,
    render,
    isSorted,
    isSortedDesc,
    disableSortBy,
    accessor,
    styleConfig = {},
    startingSortBy,
    headerAlign,
    cellAlign,
  } = column;

  const { isMobile, isLoading, gotoPage, scrollToTop, uniqueResizeColumnsId, hasResizeColumns, tableTrackingIds } =
    tableInstance;
  const tableCellStyles = useStyles('TableCommonStyles', 'tableHeaderStyles', {
    canSort: !disableSortBy,
    isMobile,
  });
  const sortingCaretStyles = useStyles('TableCommonStyles', 'sortingCaretStyles', {
    isSorted,
    isSortedDesc,
  });

  useLayoutEffect(() => {
    if (startingSortBy === 'desc') {
      column.toggleSortBy(true);
    }
    if (startingSortBy === 'asc') {
      column.toggleSortBy(false);
    }
  }, []);

  const cellPosition: CellPositionCord = { tableId, x: headersXPosition, y: -headerYPosition };

  const shouldHideSortIcon = disableSortBy || !accessor;
  const handleHeaderClick = (e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>) => {
    if (!shouldHideSortIcon && !isLoading) {
      // * 👇 Trigger react-table header onClick manually for sorting to occur
      gotoPage(0);
      getHeaderProps(getSortByToggleProps()).onClick(e);
      scrollToTop();
    }
    focusCell(cellPosition, currentCellPositionRef, true);
  };

  const { columns: columnStyles = [] } = styleConfig;

  const headerValue = render('Header');

  const cellStyles = getCellStyles({
    column,
    columnId,
    columnStyles,
    cellValue: typeof headerValue === 'string' ? headerValue : undefined,
    isHeaderStyle: true,
    isMobile,
  });

  const conditionalTriggerProps = !shouldHideSortIcon ? triggerProps : {};
  const Component = !shouldHideSortIcon ? NakedButton : 'div';

  useDidUpdate(() => {
    if (hasResizeColumns && uniqueResizeColumnsId && !column.isResizing) {
      persistUserPreference(uniqueResizeColumnsId, tableInstance.state.columnResizing.columnWidths);
    }
  }, [column.isResizing]);

  const { key, ...headerProps } = getHeaderProps(
    getSortByToggleProps({
      title: undefined,
    })
  );

  return (
    <>
      <Component
        css={[
          tableCellStyles,
          css`
            ${column.maxWidth ? `max-width: ${column.maxWidth}px;` : null}
          `,
          cellStyles,
          isMobile &&
            css`
              /* 
              To override all custom widths set by developers, these CSS properties 
              need to be set to !important. When the layout is mobile, we want all 
              of these properties to have a value of 50% at all times
              */
              width: 50% !important;
              min-width: 50% !important;
              max-width: 50% !important;
            `,
          css`
            /* Needed to escape stacking context */
            transform-style: preserve-3d;
          `,
        ]}
        key={key}
        {...headerProps}
        {...getCellPositionProp(cellPosition)}
        className={`table__header cell__${tableId}  ${row?.isSelected ? 'selected' : row?.isExpanded ? 'expanded' : ''}
        ${column.id === reservedCells.ACTIONS && 'hideforprint'} ${column.isResizing ? `resizing` : ''}
        `}
        onClick={handleHeaderClick}
        role={isMobile ? 'rowheader' : 'columnheader'}
        aria-sort={isSorted ? (isSortedDesc ? 'descending' : 'ascending') : undefined}
        ref={(node) => {
          if (node) {
            setFocusableElements(node, cellPosition, currentCellPositionRef);
          }
        }}
      >
        <div
          css={[
            css`
              display: flex;
              align-items: center;
              height: 100%;
              width: 100%;
            `,
            headerAlignStyle(cellAlign),
            headerAlignStyle(headerAlign),
          ]}
          {...conditionalTriggerProps}
        >
          <span
            css={css`
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            `}
          >
            {headerValue}
          </span>
          {!shouldHideSortIcon && !isLoading && (
            <span
              css={[
                css`
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  width: 8px;
                  min-width: 8px;
                  height: 16px;
                `,
                sortingCaretStyles,
              ]}
            >
              <Icon name='alt-caret-up-tiny' />
              <Icon name='alt-caret-down-tiny' />
            </span>
          )}
        </div>
        {hasResizeColumns && uniqueResizeColumnsId && column.canResize && (
          <Resizer
            data-trackingid={tableTrackingIds.columnResize}
            onClick={(e) => {
              e.stopPropagation();
            }}
            {...(column.getResizerProps ? column.getResizerProps() : {})}
          />
        )}
      </Component>
      {!shouldHideSortIcon && !isLoading && (
        <Tooltip {...tooltipProps}>{handleSortingLabel(isSorted, isSortedDesc)}</Tooltip>
      )}
    </>
  );
};

export const Resizer = (props: React.ComponentPropsWithoutRef<'div'>) => {
  const resizerStyles = useStyles('TableCommonStyles', 'columnResizerStyles');

  return <div className='table-header-resizer' {...props} css={resizerStyles} />;
};

const headerAlignStyle = (position: CellAlignType | undefined) => {
  switch (position) {
    case 'left':
      return css`
        justify-content: flex-start;
      `;
    case 'right':
      return css`
        justify-content: flex-end;
      `;
    case 'center':
      return css`
        justify-content: center;
      `;
    default:
      return;
  }
};
