import { useState } from 'react';
import { css } from '@emotion/react';
import type { TableInstance } from 'react-table';
import {
  CustomToolbarRender,
  GlobalSearchConfig,
  ManualFilters,
  TableActionsProps,
  TableBulkActions,
} from '../table-types';
import { BulkRowsUtilities } from './bulk-rows-utilities.component';
import { GlobalFilterComponent } from './global-filter.component';
import { TableActions } from './table-actions.component';
import { useThemeValues } from '../../../hooks';
import { TableData } from '../table-data-type';

type TableToolbarProps<T extends TableData> = {
  tableInstance: TableInstance<T>;
  bulkActions?: TableBulkActions<T>[];
  isSelectable?: boolean;
  hideBulkSelection?: boolean;
  hasGlobalSearch?: boolean;
  manualGlobalSearch?: boolean;
  globalSearchConfig?: GlobalSearchConfig;
  isFilterEnabled?: boolean;
  tableActions?: TableActionsProps<T>;
  manualFiltersRender?: ManualFilters<T>;
  customToolbarRender?: CustomToolbarRender<T>;
};

export const TableToolbar = <T extends TableData>({
  tableInstance,
  bulkActions,
  isSelectable,
  hideBulkSelection,
  hasGlobalSearch,
  manualGlobalSearch,
  globalSearchConfig,
  isFilterEnabled,
  tableActions,
  manualFiltersRender,
  customToolbarRender,
}: TableToolbarProps<T>) => {
  const { spacing } = useThemeValues();
  const { isMobile, rowSelectionConfig } = tableInstance;
  const [mobileGlobalSearch, setMobileGlobalSearch] = useState(false);
  const shouldShowActions = isMobile ? !mobileGlobalSearch : true;
  const { isSingleSelect } = rowSelectionConfig ?? {};

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        width: 100%;
        padding: ${spacing(0, 1)};
        margin-bottom: ${spacing(2)};
        min-height: ${spacing(5)};
      `}
      className='table-toolbar'
    >
      {!hideBulkSelection && !!isSelectable && shouldShowActions && !isSingleSelect && (
        <BulkRowsUtilities tableInstance={tableInstance} bulkActions={bulkActions} />
      )}
      {!!customToolbarRender && !mobileGlobalSearch && customToolbarRender(tableInstance, isMobile)}
      {!!hasGlobalSearch && (
        <GlobalFilterComponent
          mobileGlobalSearch={mobileGlobalSearch}
          setMobileGlobalSearch={setMobileGlobalSearch}
          manualGlobalSearch={manualGlobalSearch}
          globalSearchConfig={globalSearchConfig}
          tableInstance={tableInstance}
        />
      )}
      {shouldShowActions && (
        <TableActions
          tableActions={tableActions}
          isFilterEnabled={isFilterEnabled}
          tableInstance={tableInstance}
          manualFiltersRender={manualFiltersRender}
        />
      )}
    </div>
  );
};
