import { ReactNode } from 'react';
import { BoolListLayout } from '../../layouts';
import type { BasicFormFieldProps, FieldGroupOrientation, InlineLabelPlacement } from '../../layouts';
import { CheckListOption } from './checklist-option.component';

type Props = BasicFormFieldProps<'checklist'> & {
  children: ReactNode;
  className?: string;
  label: string;
  labelPlacement?: InlineLabelPlacement;
  labelDisabled?: boolean;
  orientation?: FieldGroupOrientation;
};

export const ChecklistField = ({ children, maxAllowed, minRequired, orientation = 'vertical', ...rest }: Props) => (
  <BoolListLayout orientation={orientation} {...rest}>
    {children}
  </BoolListLayout>
);

ChecklistField.Option = CheckListOption;
