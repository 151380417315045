import { css } from '@emotion/react';
import { useStyles } from '../../../../use-styles';
import { BoolInput } from '../../atoms';
import type { BoolInputProps } from '../../layouts';
import { Icon } from '@frontend/icons';

export const Switch = ({ active, disabled, error, value, indeterminate, ...rest }: BoolInputProps<'switch'>) => {
  const style = useStyles('Switch', { disabled, active, error, value, indeterminate });
  return (
    <div css={style}>
      {indeterminate && (
        <Icon name='minus' color='primary' className='icon' onClickCapture={(e) => e.preventDefault()} />
      )}
      <BoolInput
        css={css`
          cursor: ${disabled ? 'not-allowed' : 'pointer'};
        `}
        {...rest}
        type='checkbox'
        checked={value}
        disabled={disabled}
      />
    </div>
  );
};
