import { Icon, IconProps } from '../Icon.component';

export const PhoneParkedCallSmallIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.1667 0C9.79848 0 9.5 0.298477 9.5 0.666667V7.33333C9.5 7.70152 9.79848 8 10.1667 8H10.4229C10.7911 8 11.0896 7.70152 11.0896 7.33333V5.15742H12.9925C13.5149 5.15742 13.9627 5.04148 14.3358 4.8096C14.7164 4.57771 15.0037 4.26587 15.1978 3.87406C15.3993 3.48226 15.5 3.05048 15.5 2.57871C15.5 2.09895 15.403 1.66717 15.209 1.28336C15.0149 0.891554 14.7276 0.57971 14.347 0.347827C13.9664 0.115942 13.5149 0 12.9925 0H10.1667ZM13.5634 1.7991C13.7724 1.991 13.8769 2.25087 13.8769 2.57871C13.8769 2.90655 13.7724 3.17041 13.5634 3.37031C13.3619 3.56222 13.1007 3.65817 12.7799 3.65817H11.0896V1.49925H12.7799C13.1007 1.49925 13.3619 1.5992 13.5634 1.7991ZM6.47468 6.29286C6.72845 7.05061 7.24989 7.76456 8.05843 8.44082C8.82862 9.08501 9.48113 9.55297 10.0029 9.84332C10.6456 9.18407 11.6431 8.99951 12.485 9.40044L14.9019 10.5513C15.0562 10.6248 15.1968 10.7241 15.3176 10.8449C15.9034 11.4307 15.9034 12.3805 15.3176 12.9663L14.1269 14.157C13.3907 14.8932 12.3649 15.2634 11.3282 15.1671C8.69241 14.9222 6.34575 13.7439 4.31457 11.6581C2.30155 9.59089 1.23968 7.35895 1.16763 4.98335C1.13837 4.01871 1.50872 3.0848 2.19114 2.40238L3.48588 1.10764C3.59988 0.993635 3.73151 0.898758 3.87572 0.826657C4.61668 0.456173 5.51769 0.756509 5.88818 1.49748L6.986 3.69313C7.42914 4.5794 7.21302 5.64761 6.47468 6.29286ZM5.49529 4.43805L4.50088 2.44922L3.36965 3.58045C3.0122 3.93791 2.8182 4.4271 2.83353 4.93239C2.89209 6.86325 3.77089 8.71037 5.50862 10.4949C7.27093 12.3046 9.25343 13.3 11.4824 13.5071C12.0255 13.5576 12.5628 13.3636 12.9484 12.978L13.9722 11.9542L11.7685 10.9048C11.5629 10.8069 11.3171 10.8602 11.1707 11.0346L10.48 11.8568L9.92681 11.6468C9.13717 11.3471 8.16899 10.7056 6.98915 9.71882C5.73408 8.66909 4.96431 7.46822 4.71387 6.1269L4.62075 5.62819L5.3553 5.05614C5.54325 4.90976 5.60183 4.65112 5.49529 4.43805Z'
      fill='white'
    />
  </Icon>
);
