import { genUID } from '../../helpers';
import { useExecutionCounter, useOnClickOutside } from '../../hooks';
import { isFunction } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { UseFlyoutStateProps } from './types';

/**
 * Hook for low-level flyout handling. See useListbox, useFlyout and useFlyoutMenu for implementations.
 * @param {function} [onToggle] Optional function to run whenever the active state is changed
 */
export function useFlyoutState<F extends HTMLElement, T extends HTMLElement>(props?: UseFlyoutStateProps<F>) {
  const { onToggle, ignoreRefs = [] } = props ?? {};
  const runCount = useExecutionCounter();
  const [active, setActive] = useState(false);
  const idRef = useRef(genUID());
  const flyoutRef = useRef<F | null>(null);
  const triggerRef = useRef<T | null>(null);

  useOnClickOutside({
    active: active,
    ref: flyoutRef,
    handler: useCallback(() => {
      setActive(false);
    }, []),
    ignoreRefs: [triggerRef, ...ignoreRefs],
  });

  useEffect(() => {
    if (runCount.current > 1 && isFunction(onToggle)) {
      onToggle?.({ active, flyoutRef });
    }
  }, [active, onToggle]);

  return {
    active,
    flyoutId: idRef,
    flyoutRef,
    setActive,
    triggerRef,
  };
}
