import { create, Mutate, StoreApi, UseBoundStore } from 'zustand';
import { devtools } from 'zustand/middleware';
import { devTools } from './devtools';
import { withImmer } from './withImmer';
import { StateCreator } from './zustand';

/**
 * Helper function that creates a Zustand store that integrates with Redux Devtools and enables tracing by default.
 * It also implements immer middleware that allows working with immutable state
 * in a more convenient way.
 * @param config - Store configuration method
 * @param options - redux devtools options
 * @returns A Zustand store integrated with Redux Devtools and immer.
 */

type AdditionalMethods = {
  reset: () => void;
};

type StoreReturnType<T> = UseBoundStore<Mutate<StoreApi<T>, [['zimmer', never], ['zustand/devtools', never]]>> &
  AdditionalMethods;

const resetSet = new Set<AdditionalMethods['reset']>();

export const createStore = <T>(
  config: StateCreator<T, [['zimmer', never], ['zustand/devtools', never]]>,
  options: Parameters<typeof devtools>[1]
) => {
  const store = create<T>()(withImmer(devTools(config, options))) as StoreReturnType<T>;

  const initialState = store.getState();

  const reset = () => {
    store.setState(initialState, true, 'RESET_STORE');
  };
  resetSet.add(reset);
  store.reset = reset;
  return store;
};

export const resetStore = () => resetSet.forEach((reset) => reset());
