import React from 'react';
import { RadioFieldLayout, type BasicFormFieldProps } from '../../layouts';
import { RadioCardOption } from './radio-card-option.component';
import { useStyles } from '../../../../use-styles';

type Props = Omit<BasicFormFieldProps<'radio'>, 'label'> & {
  children: Array<React.ReactNode | null>;
  label?: string;
};

export const RadioCardField = ({ children, ...rest }: Props) => {
  const radioFieldStyles = useStyles('RadioCardField');
  return (
    <RadioFieldLayout {...rest}>
      <div css={radioFieldStyles}>{children}</div>
    </RadioFieldLayout>
  );
};

RadioCardField.Option = RadioCardOption;
