/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const TrashIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M17.5,5 L20.75,5 C21.4403559,5 22,5.55964406 22,6.25 C22,6.94035594 21.4403559,7.5 20.75,7.5 L20,7.5 L20,8.07432432 L20,19.4256757 C20,21.1235781 18.5890186,22.5 16.8484848,22.5 L7.15151515,22.5 C5.41098139,22.5 4,21.1235781 4,19.4256757 L4,8.07432432 L4,7.5 L3.25,7.5 C2.55964406,7.5 2,6.94035594 2,6.25 C2,5.55964406 2.55964406,5 3.25,5 L6.5,5 L6.5,4.5 C6.5,2.70507456 7.89181045,1.25 9.60869565,1.25 L14.3913043,1.25 C16.1081895,1.25 17.5,2.70507456 17.5,4.5 L17.5,5 Z M15.1086957,5 L15.1086957,4.5 C15.1086957,4.08578644 14.7875086,3.75 14.3913043,3.75 L9.60869565,3.75 C9.21249138,3.75 8.89130435,4.08578644 8.89130435,4.5 L8.89130435,5 L15.1086957,5 Z M17.2755455,7.5 L6.72445452,7.5 C6.54252676,7.62895626 6.42424242,7.83816524 6.42424242,8.07432432 L6.42424242,19.4256757 C6.42424242,19.8174993 6.74985352,20.1351351 7.15151515,20.1351351 L16.8484848,20.1351351 C17.2501465,20.1351351 17.5757576,19.8174993 17.5757576,19.4256757 L17.5757576,8.07432432 C17.5757576,7.83816524 17.4574732,7.62895626 17.2755455,7.5 Z M10,9.75 C10.6903559,9.75 11.25,10.3096441 11.25,11 L11.25,16.5 C11.25,17.1903559 10.6903559,17.75 10,17.75 C9.30964406,17.75 8.75,17.1903559 8.75,16.5 L8.75,11 C8.75,10.3096441 9.30964406,9.75 10,9.75 Z M14,9.75 C14.6903559,9.75 15.25,10.3096441 15.25,11 L15.25,16.5 C15.25,17.1903559 14.6903559,17.75 14,17.75 C13.3096441,17.75 12.75,17.1903559 12.75,16.5 L12.75,11 C12.75,10.3096441 13.3096441,9.75 14,9.75 Z'
    />
  </Icon>
);
