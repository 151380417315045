import { css } from '@emotion/react';
import { InputProps } from '../../atoms/types';
import { ListboxChildren } from '../../../listbox/listbox.provider';
import { useDropdownContext } from '../../providers/dropdown-provider';
import { getSelectedChild } from '../dropdown/utils';
import { useStyles } from '../../../../use-styles/use-styles';
import { DropdownInput } from '../../atoms/dropdown-input.component';
import { MultiselectSelection } from './multiselect-selection';
import { Text } from '../../../text';

type Props = InputProps<'multiselect'> & {
  options: ListboxChildren;
};

export const MultiselectInput = ({ options, value, ...rest }: Props) => {
  const { triggerProps } = useDropdownContext();
  const visibleChild = getSelectedChild({ children: options, value });
  const remainder = value.length - 1;
  const placeholderStyle = useStyles('DropdownInput', 'placeholderStyle');
  const remainderStyles = useStyles('MultiselectSelection', 'remainderStyles');

  return (
    <DropdownInput
      as='div'
      css={[
        css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `,
        !value.length && placeholderStyle,
      ]}
      displayValue={
        value.length > 0 ? (
          <>
            <MultiselectSelection
              selectionCount={value.length}
              onRemove={() => {
                rest.onChange({ name: rest.name, value: visibleChild?.props.value });
                setTimeout(() => {
                  triggerProps.ref.current?.focus();
                }, 0);
              }}
            >
              {visibleChild?.props.children}
            </MultiselectSelection>
            {remainder > 0 && (
              <Text color='light' css={remainderStyles}>
                +{remainder} more
              </Text>
            )}
          </>
        ) : null
      }
      value=''
      {...rest}
      {...triggerProps}
    />
  );
};
