/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const CellPhoneIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M7.5,0.75 L16.5,0.75 C18.2949254,0.75 19.75,2.20507456 19.75,4 L19.75,20 C19.75,21.7949254 18.2949254,23.25 16.5,23.25 L7.5,23.25 C5.70507456,23.25 4.25,21.7949254 4.25,20 L4.25,4 C4.25,2.20507456 5.70507456,0.75 7.5,0.75 Z M7.5,3.25 C7.08578644,3.25 6.75,3.58578644 6.75,4 L6.75,20 C6.75,20.4142136 7.08578644,20.75 7.5,20.75 L16.5,20.75 C16.9142136,20.75 17.25,20.4142136 17.25,20 L17.25,4 C17.25,3.58578644 16.9142136,3.25 16.5,3.25 L7.5,3.25 Z M12,20 C11.1715729,20 10.5,19.3284271 10.5,18.5 C10.5,17.6715729 11.1715729,17 12,17 C12.8284271,17 13.5,17.6715729 13.5,18.5 C13.5,19.3284271 12.8284271,20 12,20 Z'
    />
  </Icon>
);
