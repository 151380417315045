import { Children, ReactElement } from 'react';
import type { ListboxChildren } from './listbox.provider';
import { useFormField } from '../forms/hooks/use-form-field/use-form-field';
import { SearchField } from '../forms/fields/search-field/search-field.component';

type ChildrenType = ReactElement | Array<ReactElement | ReactElement[] | null>;

const searchChildren = (children: ListboxChildren, search: string) => {
  // short circuit boolean values
  if (typeof children === 'boolean') return [];

  const searchValue = search.toLowerCase().trim();
  return searchValue
    ? (Children.map(children as ChildrenType, (child) => {
        if (!child) return child;
        const { props } = child as React.ReactElement;
        let value = props.searchValue ?? props.value;
        if (typeof props.children === 'string') {
          value = props.children;
        }
        if (value?.toLowerCase()?.includes(searchValue)) return child;
        return null;
      }) as ListboxChildren)
    : children;
};

export function useListboxSearch(children) {
  const searchFieldProps = useFormField({ type: 'text' });
  const matchingChildren = searchChildren(children, searchFieldProps.value) ?? null;
  return {
    matchingChildren,
    SearchField,
    searchFieldProps,
  };
}
