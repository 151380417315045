import type { BasicFormFieldProps, RadioFieldKey, InlineLabelPlacement } from './types';
import { shouldShowError } from '../atoms/utils';
import { GroupLabel } from './group-label.component';
import { HelperText } from './helper-text';
import { RadioProvider } from '../providers';

type Props<T extends RadioFieldKey> = Pick<
  BasicFormFieldProps<T>,
  'active' | 'disabled' | 'error' | 'id' | 'name' | 'onBlur' | 'onChange' | 'onFocus' | 'touched' | 'value'
> & {
  children: React.ReactNode;
  label?: string;
  labelPlacement?: InlineLabelPlacement;
  className?: string;
};

export const RadioFieldLayout = <T extends RadioFieldKey>({
  active,
  children,
  disabled,
  error,
  id,
  label,
  labelPlacement = 'right',
  name,
  onBlur,
  onChange,
  onFocus,
  touched,
  value,
  className,
  ...rest
}: Props<T>) => (
  <RadioProvider
    active={active}
    disabled={disabled}
    id={id}
    labelPlacement={labelPlacement}
    name={name}
    onBlur={onBlur}
    onChange={onChange}
    onFocus={onFocus}
    value={value}
    className={className}
  >
    {!!label && <GroupLabel id={id}>{label}</GroupLabel>}
    {children}
    {shouldShowError({ error, touched, ...rest }) && (
      <HelperText id={id} hasError disabled={disabled}>
        {error}
      </HelperText>
    )}
  </RadioProvider>
);
