/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const SearchIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M16.2595842,18.6273512 C14.8158943,19.559188 13.0961038,20.1 11.25,20.1 C6.14136606,20.1 2,15.9586339 2,10.85 C2,5.74136606 6.14136606,1.6 11.25,1.6 C16.3586339,1.6 20.5,5.74136606 20.5,10.85 C20.5,13.2089645 19.6169671,15.3616857 18.1634159,16.9956489 L21.5052038,20.3374369 C21.9933592,20.8255922 21.9933592,21.6170485 21.5052038,22.1052038 C21.0170485,22.5933592 20.2255922,22.5933592 19.7374369,22.1052038 L16.2595842,18.6273512 Z M15.685692,15.9380689 C17.1039013,14.7006663 18,12.8799807 18,10.85 C18,7.12207794 14.9779221,4.1 11.25,4.1 C7.52207794,4.1 4.5,7.12207794 4.5,10.85 C4.5,14.5779221 7.52207794,17.6 11.25,17.6 C12.85017,17.6 14.3202909,17.0431936 15.4771817,16.1127616 C15.4829808,16.1067289 15.4888523,16.1007401 15.4947962,16.0947962 C15.5542892,16.0353032 15.6182872,15.9830607 15.685692,15.9380689 Z'
    />
  </Icon>
);
