import React, { Fragment } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

import { BreadcrumbPath } from './breadcrumb-path';
import { CaretRightIconSmall } from '../../icon/icons-small/caret-right-small';

type BreadcrumbProps = {
  children: React.ReactElement[];
};

export const Breadcrumb = ({ children, ...rest }: BreadcrumbProps) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;

        span:first-of-type {
          cursor: pointer;
        }

        > p,
        > a {
          margin: 0;
        }

        > a:hover,
        > a:focus {
          color: ${theme.colors.primary};
        }
      `}
      {...rest}
    >
      {children.map((child, i) => {
        if (i !== 0 && child) {
          return (
            <Fragment key={i}>
              <CaretRightIconSmall
                css={css`
                  margin: ${theme.spacing(0, 2)};
                  color: ${theme.colors.neutral50};
                `}
              />
              {child}
            </Fragment>
          );
        }
        return child;
      })}
    </div>
  );
};

Breadcrumb.Path = BreadcrumbPath;
