/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const WarningIndicatorSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <circle cx='8' cy='8' r='8' fill='#FFC700' />
    <path
      d='M8.00033 3.16669C7.54009 3.16669 7.16699 3.53978 7.16699 4.00002V9.00002C7.16699 9.46026 7.54009 9.83335 8.00033 9.83335C8.46056 9.83335 8.83366 9.46026 8.83366 9.00002V4.00002C8.83366 3.53978 8.46056 3.16669 8.00033 3.16669Z'
      fill='#7A4900'
    />
    <path
      d='M8.00033 12.8334C8.46056 12.8334 8.83366 12.4603 8.83366 12C8.83366 11.5398 8.46056 11.1667 8.00033 11.1667C7.54009 11.1667 7.16699 11.5398 7.16699 12C7.16699 12.4603 7.54009 12.8334 8.00033 12.8334Z'
      fill='#7A4900'
    />
  </Icon>
);
