/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const PauseIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.25 4C8.55964 4 8 4.55964 8 5.25V18.75C8 19.4404 8.55964 20 9.25 20C9.94036 20 10.5 19.4404 10.5 18.75V5.25C10.5 4.55964 9.94036 4 9.25 4ZM15.25 4C14.5596 4 14 4.55964 14 5.25V18.75C14 19.4404 14.5596 20 15.25 20C15.9404 20 16.5 19.4404 16.5 18.75V5.25C16.5 4.55964 15.9404 4 15.25 4Z'
    />
  </Icon>
);
