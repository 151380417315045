import React, { HTMLAttributes, Children } from 'react';
import { css } from '@emotion/react';
import { SerializedStyles } from '@emotion/utils';
import { Node, Text as SlateText } from 'slate';
import urlRegex from 'url-regex';
import { useStyles } from '../../../use-styles';
import { Text } from '../../text';
import { useTemplateEditorValue, useTemplateSettings } from '../message-template.context';
import { isTagNode } from '../message-template.models';
import { TagType } from '../types';

const renderNode = (node: Node, linkStyle: SerializedStyles, shortenUrlsTo?: string) => {
  if (SlateText.isText(node)) {
    const text = Node.string(node);
    return shortenUrlsTo ? text.replaceAll(urlRegex(), shortenUrlsTo) : text;
  }

  if (isTagNode(node)) {
    if (node.tag.invalid) {
      // don't render invalid tags
      return '';
    }
    const tag = node.tag as TagType;
    const isUrl = urlRegex({ exact: true, strict: false }).test(tag.value);

    return (
      <Text weight='bold' css={isUrl && linkStyle} as='span'>
        {isUrl && shortenUrlsTo ? shortenUrlsTo : tag.value}
      </Text>
    );
  }

  // do nothing - unexpected node type
  return '';
};

const renderLine = (nodes: Node[], linkStyle: SerializedStyles, shortenUrlsTo?: string) => {
  if (!nodes.map) {
    console.error('Rendering line is not an array');
    return [<></>];
  }
  return nodes.map((node, idx) =>
    node.children ? (
      <React.Fragment key={idx}>
        {renderNode(node, linkStyle, shortenUrlsTo)}
        {renderLine(node.children as Node[], linkStyle, shortenUrlsTo)}
      </React.Fragment>
    ) : (
      <React.Fragment key={idx}>{renderNode(node, linkStyle, shortenUrlsTo)}</React.Fragment>
    )
  );
};

const isBlankRow = (node: Node) => {
  const children = node.children as Node[];

  return children.length === 1 && SlateText.isText(children[0]) && children[0].text.trim() === '';
};

const trimEmptyRowsEnd = (nodes: Node[]): Node[] => {
  // find last non blank node
  for (let i = nodes.length - 1; i > -1; i--) {
    if (!isBlankRow(nodes[i]!)) {
      return nodes.slice(0, i + 1);
    }
  }

  //all blank
  return [];
};

/**
 * Serializes the editor nodes into a plain text template with the tag keys.
 * @param nodes
 */
const renderPreview = (nodes: Node[], linkStyle: SerializedStyles, trim: boolean, shortenUrlsTo?: string) => {
  const trimmedNodes = trim ? trimEmptyRowsEnd(nodes) : nodes;

  // map the block elements to be lines
  return trimmedNodes.map((node, idx) => {
    const line = renderLine(node.children as Node[], linkStyle, shortenUrlsTo);
    const isBlankLine = line.length === 1 && line[0]?.props?.children === '';
    return isBlankLine ? (
      <br key={idx} />
    ) : (
      <Text key={idx} css={css({ margin: 0 })}>
        {line}
      </Text>
    );
  });
};

type TemplatePreviewProps = HTMLAttributes<HTMLDivElement> & {
  noPreviewText?: string;
  appendWith?: string;
  preserveWhitespace?: boolean;
};

export const TemplatePreview = ({
  noPreviewText = '',
  appendWith,
  preserveWhitespace = false,
  ...props
}: TemplatePreviewProps) => {
  const templatePreviewStyles = useStyles('MessageTemplate', 'templatePreview');
  const linkStyle = useStyles('MessageTemplate', 'linkStyle');
  const nodes = useTemplateEditorValue();
  const { shortenUrlsTo } = useTemplateSettings();

  // @ts-ignore
  const preview = renderPreview(nodes, linkStyle, !preserveWhitespace, shortenUrlsTo);
  const numberOfPreviewComponents = Children.count(preview);

  return (
    <div css={templatePreviewStyles} {...props}>
      {numberOfPreviewComponents > 0 && preview}
      {numberOfPreviewComponents > 0 && appendWith && <Text css={css({ margin: 0 })}>{appendWith}</Text>}
      {numberOfPreviewComponents < 1 && !!noPreviewText && (
        <Text color='light' css={css({ margin: 0 })}>
          {noPreviewText}
        </Text>
      )}
    </div>
  );
};
