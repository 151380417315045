/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const WarningIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      d='M8,12 C8.55228475,12 9,12.4477153 9,13 C9,13.5522847 8.55228475,14 8,14 C7.44771525,14 7,13.5522847 7,13 C7,12.4477153 7.44771525,12 8,12 Z M8,2 C8.55228475,2 9,2.44771525 9,3 L9,9 C9,9.55228475 8.55228475,10 8,10 C7.44771525,10 7,9.55228475 7,9 L7,3 C7,2.44771525 7.44771525,2 8,2 Z'
    />
  </Icon>
);
