import type { ComponentProps, FC, ReactElement } from 'react';
import { Children, useCallback, useMemo } from 'react';
import { useFade, useThemeValues } from '../../../../hooks';
import { styles } from '../../../../styles';
import { useStyles } from '../../../../use-styles';
import { AnimatedMenu } from '../../../flyouts';
import type { ListboxChildren } from '../../../listbox';
import { useListboxContext } from '../../../listbox';
import { DropdownInput } from '../../atoms';
import type { DropdownOptionData } from '../../fields/dropdown/use-dropdown-search';
import { FieldLayout } from '../../layouts';
import { useDropdownContext } from '../../providers';

import { DropdownIcon } from '../../fields/dropdown/dropdown-icon.component';
import { useDropdownSearch } from '../../fields/dropdown/use-dropdown-search';
import { getDisplayValue } from '../../fields/dropdown/utils';
import { activeDropdownStyle } from './old-styles';
import type { OptionGroupProps } from './option-group.component-old';
import { DropdownFieldProps } from '../../fields/dropdown/types';

export const DropdownBaseField: FC<React.PropsWithChildren<DropdownFieldProps>> = ({
  children,
  placeholder = 'Select one',
  icon,
  direction = 'down',
  ...rest
}: DropdownFieldProps) => {
  const { active, triggerProps } = useDropdownContext();
  const menuStyle = useFade(active);
  const flatChildren = useMemo(
    () =>
      Children.toArray(children).reduce((acc, child) => {
        const childProps = (child as ReactElement<OptionGroupProps | any>).props;

        return [
          // @ts-ignore TS thinks acc won't always be an array, and therefore won't always be spreadable
          ...acc,
          ...(Array.isArray(childProps?.children) && !!childProps.label ? childProps.children : [child]),
        ];
      }, []),
    [children]
  ) as ListboxChildren;
  const displayValue = getDisplayValue({
    children: flatChildren,
    value: rest.value,
  });
  const inputStyles = useStyles('DropdownInput', 'inputStyle', displayValue);
  const dropdownMenuStyle = useStyles('DropdownInput', 'dropdownMenuStyle', {
    active,
    direction,
  });

  const onFieldMatch = useCallback(
    (match: DropdownOptionData) => {
      rest.onChange({ name: rest.name, value: match.value });
    },
    [rest.name]
  );

  const fieldKeydownSearch = useDropdownSearch({
    children: flatChildren,
    onKeyDown: triggerProps.onKeyDown,
    onMatch: onFieldMatch,
  });

  const { listboxProps, setActiveItem } = useListboxContext();
  const menuKeydownSearch = useDropdownSearch({
    children: flatChildren,
    // @ts-ignore types of KeyboardEvent<HTMLUListElement> and KeyboardEvent<Element> no longer match
    onKeyDown: listboxProps.onKeyDown,
    onMatch: setActiveItem,
  });
  const theme = useThemeValues();
  return (
    <FieldLayout
      //TODO: this might be able to be improved. But this type cast just makes sure the field prop is any of the correct field types
      field={DropdownInput as ComponentProps<typeof FieldLayout>['field']}
      fieldComponentProps={{
        ...triggerProps,
        onKeyDown: fieldKeydownSearch,
        displayValue,
        css: inputStyles,
      }}
      endAdornment={<DropdownIcon active={active} icon={icon} />}
      css={active && activeDropdownStyle('bottom')}
      placeholder={placeholder}
      {...rest}
    >
      <AnimatedMenu
        css={[dropdownMenuStyle, styles.smallScrollbar(theme)]}
        {...listboxProps}
        onKeyDown={menuKeydownSearch}
        style={menuStyle}
      >
        {children}
      </AnimatedMenu>
    </FieldLayout>
  );
};
