import { Icon, IconProps } from '../Icon.component';

export const DialPadErrorState: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={144} {...props} viewBox='0 0 200 200'>
    <circle cx='100' cy='100' r='100' fill='#FFF1F0' />
    <circle cx='100' cy='100' r='72' fill='#FFB8B3' />
    <circle cx='146.16' cy='49.8402' r='20.16' fill='#6F7780' fillOpacity='0.3' />
    <rect x='127.839' y='28' width='40.32' height='40.32' rx='20.16' fill='white' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M147.999 68.32C159.133 68.32 168.159 59.2941 168.159 48.16C168.159 37.0259 159.133 28 147.999 28C136.865 28 127.839 37.0259 127.839 48.16C127.839 59.2941 136.865 68.32 147.999 68.32ZM159.4 56.5906C159.81 57.0006 159.81 57.6654 159.4 58.0755L157.915 59.5604C157.505 59.9705 156.84 59.9705 156.43 59.5604L147.966 51.0963L139.502 59.5604C139.092 59.9705 138.427 59.9705 138.017 59.5604L136.532 58.0755C136.122 57.6655 136.122 57.0007 136.532 56.5906L144.996 48.1265L136.532 39.6624C136.122 39.2524 136.122 38.5876 136.532 38.1775L138.017 36.6926C138.427 36.2825 139.092 36.2825 139.502 36.6926L147.966 45.1566L156.43 36.6926C156.84 36.2826 157.505 36.2826 157.915 36.6926L159.4 38.1775C159.81 38.5876 159.81 39.2524 159.4 39.6625L150.936 48.1265L159.4 56.5906Z'
      fill='#DD3131'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M83.125 79.75C86.2316 79.75 88.75 77.2316 88.75 74.125C88.75 71.0184 86.2316 68.5 83.125 68.5C80.0184 68.5 77.5 71.0184 77.5 74.125C77.5 77.2316 80.0184 79.75 83.125 79.75ZM83.125 114.625C86.2316 114.625 88.75 112.107 88.75 109C88.75 105.893 86.2316 103.375 83.125 103.375C80.0184 103.375 77.5 105.893 77.5 109C77.5 112.107 80.0184 114.625 83.125 114.625ZM88.75 91C88.75 94.1066 86.2316 96.625 83.125 96.625C80.0184 96.625 77.5 94.1066 77.5 91C77.5 87.8934 80.0184 85.375 83.125 85.375C86.2316 85.375 88.75 87.8934 88.75 91ZM116.875 79.75C119.982 79.75 122.5 77.2316 122.5 74.125C122.5 71.0184 119.982 68.5 116.875 68.5C113.768 68.5 111.25 71.0184 111.25 74.125C111.25 77.2316 113.768 79.75 116.875 79.75ZM122.5 109C122.5 112.107 119.982 114.625 116.875 114.625C113.768 114.625 111.25 112.107 111.25 109C111.25 105.893 113.768 103.375 116.875 103.375C119.982 103.375 122.5 105.893 122.5 109ZM116.875 96.625C119.982 96.625 122.5 94.1066 122.5 91C122.5 87.8934 119.982 85.375 116.875 85.375C113.768 85.375 111.25 87.8934 111.25 91C111.25 94.1066 113.768 96.625 116.875 96.625ZM105.625 74.125C105.625 77.2316 103.107 79.75 100 79.75C96.8934 79.75 94.375 77.2316 94.375 74.125C94.375 71.0184 96.8934 68.5 100 68.5C103.107 68.5 105.625 71.0184 105.625 74.125ZM100 114.625C103.107 114.625 105.625 112.107 105.625 109C105.625 105.893 103.107 103.375 100 103.375C96.8934 103.375 94.375 105.893 94.375 109C94.375 112.107 96.8934 114.625 100 114.625ZM105.625 125.875C105.625 128.982 103.107 131.5 100 131.5C96.8934 131.5 94.375 128.982 94.375 125.875C94.375 122.768 96.8934 120.25 100 120.25C103.107 120.25 105.625 122.768 105.625 125.875ZM100 96.625C103.107 96.625 105.625 94.1066 105.625 91C105.625 87.8934 103.107 85.375 100 85.375C96.8934 85.375 94.375 87.8934 94.375 91C94.375 94.1066 96.8934 96.625 100 96.625Z'
      fill='#DD3131'
    />
  </Icon>
);
