/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const ColumnsIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M16.1 5.41667H17.7C18.2796 5.41667 18.7 5.8536 18.7 6.33333V17.6667C18.7 18.1464 18.2796 18.5833 17.7 18.5833H16.1V5.41667ZM13.7526 18.5833H10.2745V5.41667H13.7526V18.5833ZM6.3 5.41667H7.9V18.5833H6.3C5.72039 18.5833 5.3 18.1464 5.3 17.6667V6.33333C5.3 5.85361 5.7204 5.41667 6.3 5.41667ZM6 3C4.09432 3 2.5 4.46584 2.5 6.33333V17.6667C2.5 19.5341 4.09432 21 6 21H18C19.9057 21 21.5 19.5341 21.5 17.6667V6.33333C21.5 4.46584 19.9057 3 18 3H6Z'
    />
  </Icon>
);
