import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

type Props = {
  //how many pixels of tolerance to allow for window resize. eg update every 10px
  tolerance?: number;
};
export function useWindowDimensions({ tolerance = 1 }: Props = { tolerance: 1 }) {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      const windowDimensions = getWindowDimensions();
      setWindowDimensions((prev) =>
        Math.abs(prev.width - windowDimensions.width) >= tolerance ||
        Math.abs(prev.height - windowDimensions.height) >= tolerance
          ? windowDimensions
          : prev
      );
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [tolerance]);

  return windowDimensions;
}

export const useWindowWidth = (props?: Props) => {
  return useWindowDimensions(props).width;
};
