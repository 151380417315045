import { Icon, IconProps } from '../Icon.component';

export const UserVoiceIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M21.2868 15.58L19.1214 14.3298C19.9191 12.9626 20.3761 11.3722 20.3761 9.6751C20.3761 6.92995 19.1803 4.4641 17.2811 2.77004L19.0512 1C21.4017 3.14851 22.8761 6.23963 22.8761 9.6751C22.8761 11.8277 22.2973 13.845 21.2868 15.58ZM17.8761 9.6751C17.8761 10.9168 17.5408 12.0801 16.9559 13.0796L14.7904 11.8294C15.1626 11.1977 15.3761 10.4613 15.3761 9.6751C15.3761 8.31035 14.7328 7.09584 13.7329 6.31824L15.5094 4.54178C16.9579 5.77982 17.8761 7.62023 17.8761 9.6751ZM8.6 8.47968C7.85442 8.47968 7.25 9.08409 7.25 9.82968C7.25 10.5753 7.85442 11.1797 8.6 11.1797C9.34558 11.1797 9.95 10.5753 9.95 9.82968C9.95 9.08409 9.34558 8.47968 8.6 8.47968ZM4.75 9.82968C4.75 7.70338 6.4737 5.97968 8.6 5.97968C10.7263 5.97968 12.45 7.70338 12.45 9.82968C12.45 11.956 10.7263 13.6797 8.6 13.6797C6.4737 13.6797 4.75 11.956 4.75 9.82968ZM6.75 17.2797C5.23122 17.2797 4 18.5109 4 20.0297V20.9047C4 21.595 3.44036 22.1547 2.75 22.1547C2.05964 22.1547 1.5 21.595 1.5 20.9047V20.0297C1.5 17.1302 3.85051 14.7797 6.75 14.7797H10.45C13.3495 14.7797 15.7 17.1302 15.7 20.0297V21.2297C15.7 21.92 15.1404 22.4797 14.45 22.4797C13.7596 22.4797 13.2 21.92 13.2 21.2297V20.0297C13.2 18.5109 11.9688 17.2797 10.45 17.2797H6.75Z'
    />
  </Icon>
);
