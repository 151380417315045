import { css } from '@emotion/react';
import { IconButton } from '../../../icon-button/icon-button.component';
import { Row } from 'react-table';
import { CaretDownIconSmall } from '../../../../icon';
import { TableData } from '../../table-data-type';

type RowExpandedProps<T extends TableData> = {
  rowObject: Row<T>;
  isExpandableRowDisabled: boolean | undefined;
};

export const RowExpandCell = <T extends TableData>({ rowObject, isExpandableRowDisabled }: RowExpandedProps<T>) => {
  return (
    <IconButton
      disabled={!!rowObject?.isDisabled || isExpandableRowDisabled}
      color='light'
      css={css`
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      `}
      size='small'
      label={rowObject.isExpanded ? 'Condense row' : 'Expand row'}
    >
      <CaretDownIconSmall
        css={css`
          transition: transform 200ms ease-out;
          transform: ${rowObject.isExpanded ? `rotate(180deg)` : null};
        `}
        color='light'
        size={16}
      />
    </IconButton>
  );
};
