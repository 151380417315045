/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const EditIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M3.25,20.3964445 L17.75,20.3964445 L17.75,15.0353334 C17.75,14.3449775 18.3096441,13.7853334 19,13.7853334 C19.6903559,13.7853334 20.25,14.3449775 20.25,15.0353334 L20.25,20.7020001 C20.25,21.9139583 19.2675138,22.8964445 18.0555556,22.8964445 L2.94444444,22.8964445 C1.73248624,22.8964445 0.75,21.9139583 0.75,20.7020001 L0.75,5.59088895 C0.75,4.37893075 1.73248624,3.3964445 2.94444444,3.3964445 L8.61111111,3.3964445 C9.30146705,3.3964445 9.86111111,3.95608857 9.86111111,4.6464445 C9.86111111,5.33680044 9.30146705,5.8964445 8.61111111,5.8964445 L3.25,5.8964445 L3.25,20.3964445 Z M22.7634611,4.82842712 C23.3492475,5.41421356 23.3492475,6.36396103 22.7634611,6.94974747 L13.5290898,16.1841187 C13.2701075,16.443101 12.92532,16.5983805 12.559743,16.6206766 L8.36181726,16.8767028 C7.53492657,16.9271338 6.82371681,16.2976888 6.7732858,15.4707981 C6.76939792,15.3696064 6.76939792,15.3696064 6.77461723,15.2684746 L7.08212863,11.1246882 C7.10873102,10.7662149 7.26317916,10.4292141 7.51735507,10.1750382 L16.7530534,0.939339828 C17.3388398,0.353553391 18.2885873,0.353553391 18.8743738,0.939339828 L22.7634611,4.82842712 Z M9.54778258,11.6801446 L9.35249887,14.3116371 L12.0292917,14.1483829 L20.2885873,5.8890873 L17.8137136,3.41421356 L9.54778258,11.6801446 Z'
    />
  </Icon>
);
