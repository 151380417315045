import { css } from '@emotion/react';
import { ButtonBar, PrimaryButton, SecondaryButton } from '../../';
import { useForm } from '../../';
import { Tray } from '../../';
import React from 'react';
import type { TableInstance } from 'react-table';
import { FormConfig } from '../../forms/hooks';
import { ModalControlModalProps } from '../../modal';
import { FilterSidebarHeader } from './atoms';
import { Filter } from './molecules';
import { convertObjectToArray } from './utils';
import { useThemeValues } from '../../../hooks';
import { styles } from '../../../styles';
import { useStyles } from '../../../use-styles';
import { TableData } from '../table-data-type';

export const TableFilters = <T extends TableData>({
  tableInstance,
  ...modalProps
}: ModalControlModalProps & {
  tableInstance: TableInstance<T>;
}) => {
  const theme = useThemeValues();
  const buttonBarStyles = useStyles('TableCommonStyles', 'tableFilterStyles', { type: 'filterSidebarButtons' });
  const { allColumns, setAllFilters, gotoPage, tableTrackingIds } = tableInstance;
  const filterableColumns = React.useMemo(() => allColumns.filter((col) => !!col.filterConfig), [allColumns]);

  const renderFilter = (): FormConfig => {
    return filterableColumns.reduce((acc, current) => {
      const { filterConfig } = current;
      const { label, ...rest } = filterConfig!;
      return { ...acc, [current.id]: { ...rest } };
    }, {});
  };

  const { formProps, getFieldProps, seedValues, reset } = useForm({
    fields: renderFilter(),
    onSubmit: (values) => {
      gotoPage(0);
      setAllFilters(convertObjectToArray(values));
    },
  });

  return (
    <Tray
      /*The "+1" is included because filters should appear above the modal when the table is rendered on a modal. */
      zIndex={theme.zIndex.modals + 1}
      width='small'
      {...modalProps}
      css={{ padding: 0 }}
    >
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}
      >
        <FilterSidebarHeader closeFilterSidebar={modalProps.onClose} />
        <form
          {...formProps}
          id='table-filters'
          css={[
            css`
              flex: 1;
              overflow-y: auto;
            `,
            styles.smallScrollbar(theme),
          ]}
        >
          {filterableColumns.map(({ render, id, setFilter, filterConfig, filterValue, preFilteredRows }) => {
            const options = React.useMemo(() => {
              const uniqueOptions = new Set<any>(preFilteredRows.map((row) => row.values[id]));
              return [...uniqueOptions.values()];
            }, [id, preFilteredRows]);

            return (
              <Filter
                key={id}
                render={render}
                id={id}
                setFilter={setFilter}
                filterConfig={filterConfig}
                filterValue={filterValue}
                seedValues={seedValues}
                getFieldProps={getFieldProps}
                options={options}
                gotoPage={gotoPage}
              />
            );
          })}
        </form>
        <ButtonBar css={buttonBarStyles}>
          <SecondaryButton
            trackingId={tableTrackingIds.clientFilterActions?.('clear-all')}
            onClick={() => {
              reset();
              setAllFilters([]);
            }}
          >
            Clear All Filters
          </SecondaryButton>
          <PrimaryButton
            trackingId={tableTrackingIds.clientFilterActions?.('apply')}
            form='table-filters'
            type='submit'
            onClick={() => modalProps.onClose()}
          >
            Apply Filters
          </PrimaryButton>
        </ButtonBar>
      </div>
    </Tray>
  );
};
