import { XIconSmall } from '../../../../icon';
import { NakedButton } from '../../../../naked';
import { useStyles } from '../../../../use-styles';
import { styles } from '../../../../styles';
import { Text } from '../../../text';

type Props = {
  children: React.ReactNode;
  onRemove: () => void;
  selectionCount: number;
};

export const MultiselectSelection = ({ children, onRemove, selectionCount }: Props) => {
  const themedText = useStyles('MultiselectSelection', 'textStyles', {
    selectionCount,
  });
  const themedButton = useStyles('MultiselectSelection', 'buttonStyles');

  return (
    <Text size='medium' color='light' css={themedText}>
      <span css={styles.truncate}>{children}</span>
      <NakedButton css={themedButton} onClick={onRemove} aria-label='Remove option' type='button'>
        <XIconSmall />
      </NakedButton>
    </Text>
  );
};
