import dayjs from 'dayjs';
import { Row, ColumnInstance } from 'react-table';
import { TableData } from '../table-data-type';

type CustomSortType = <T extends TableData>(rowA: Row<T>, rowB: Row<T>, columnId: ColumnInstance['id']) => number;

const handleDateSort: CustomSortType = (rowA, rowB, columnId) => {
  const rowAValue = rowA.values[columnId];
  const rowBValue = rowB.values[columnId];
  if (rowAValue === rowBValue) return 0;
  if (dayjs(rowAValue).isBefore(rowBValue)) return 1;
  return -1;
};

const handleCaseInsensitiveSort: CustomSortType = (rowA, rowB, columnId) => {
  const rowAValue = rowA.values[columnId];
  const rowBValue = rowB.values[columnId];
  return rowAValue.localeCompare(rowBValue, undefined, { sensitivity: 'base' });
};

export const sortTypeMap = {
  date: handleDateSort,
  caseInsensitive: handleCaseInsensitiveSort,
} as const;
