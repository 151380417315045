import { isFunction } from 'lodash-es';
import type { FocusEvent } from 'react';
import { useMemo, useRef } from 'react';

type UseDebouncedBlurProps<TBlur extends HTMLElement, TFocus extends HTMLElement> = {
  clear?: () => void;
  delay?: number;
  onBlur?: (e?: FocusEvent<TBlur>) => void;
  onFocus?: (e?: FocusEvent<TFocus>) => void;
};

/**
 * This debounces a blur action so it can be cancelled by a subsequent focus action.
 * The main use case is for fields that have logic tied to focus + blur but have internal
 * elements that need to receive focus and would trigger blur effects before they should happen.
 * @param {number} [props.delay=10] The amount of time (in ms) to debounce the blur call.
 * @param {Function} [props.onBlur] The blur function to debounce
 * @param {Function} [props.onFocus] This will be wrapped so it cancels the debounced blur.
 */
export function useDebouncedBlur<TBlur extends HTMLElement, TFocus extends HTMLElement>({
  delay = 10,
  onBlur,
  onFocus,
}: UseDebouncedBlurProps<TBlur, TFocus>) {
  const timerRef = useRef<number | null>(null);

  const clearTimer = () => {
    if (timerRef.current) {
      window.clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  return useMemo(
    () => ({
      clear: clearTimer,
      blur: (e?: FocusEvent<TBlur>) => {
        clearTimer();
        e?.persist();
        timerRef.current = window.setTimeout(() => {
          if (isFunction(onBlur)) onBlur?.(e);
        }, delay);
      },
      focus: (e?: FocusEvent<TFocus>) => {
        clearTimer();
        e?.persist();
        if (isFunction(onFocus)) onFocus?.(e);
      },
    }),
    [delay, onBlur, onFocus]
  );
}
