/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const GiftIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M13.6283957,6.05498509 L16.5789705,6.05498509 C16.7336307,6.03739555 16.8585873,6.01019127 16.952689,5.97594106 C17.8499051,5.64938107 18.304716,4.83974759 18.0841993,4.23388277 C17.8636825,3.62801796 16.9948548,3.30014952 16.0976386,3.62670951 C15.5536175,3.82471701 14.6987675,4.64015978 13.6283957,6.05498509 Z M20.3132985,6.05498509 L21.0777778,6.05498509 C22.0013206,6.05498509 22.75,6.65413343 22.75,7.39322038 L22.75,11.2167498 C22.75,11.9558367 22.0013206,12.5549851 21.0777778,12.5549851 L20.75,12.5549851 L20.75,21.2567092 C20.75,22.0565699 20.0088498,22.7049851 19.0945946,22.7049851 L4.90540541,22.7049851 C3.99115025,22.7049851 3.25,22.0565699 3.25,21.2567092 L3.25,12.5549851 L2.92222222,12.5549851 C1.99867939,12.5549851 1.25,11.9558367 1.25,11.2167498 L1.25,7.39322038 C1.25,6.65413343 1.99867939,6.05498509 2.92222222,6.05498509 L3.68670148,6.05498509 C3.31121367,5.22259054 3.23835804,4.2805851 3.56656918,3.37883241 C4.29051497,1.38980771 6.63512013,0.505026976 8.75741175,1.27747795 C9.74742681,1.63781397 10.8025185,2.56458132 12,4.07242436 C13.1974815,2.56458132 14.2525732,1.63781397 15.2425882,1.27747795 C17.3648799,0.505026976 19.709485,1.38980771 20.4334308,3.37883241 C20.761642,4.2805851 20.6887863,5.22259054 20.3132985,6.05498509 Z M10.3716043,6.05498509 C9.30123255,4.64015978 8.44638254,3.82471701 7.9023614,3.62670951 C7.00514521,3.30014952 6.13631749,3.62801796 5.91580073,4.23388277 C5.69528397,4.83974759 6.15009485,5.64938107 7.04731104,5.97594106 C7.14077691,6.00995985 7.26594981,6.03729019 7.42170533,6.05498509 L10.3716043,6.05498509 Z M10.75,7.96674979 L3.63888889,7.96674979 L3.63888889,10.6432204 L10.75,10.6432204 L10.75,7.96674979 Z M13.25,7.96674979 L13.25,10.6432204 L20.3611111,10.6432204 L20.3611111,7.96674979 L13.25,7.96674979 Z M10.75,12.7739506 L5.61486486,12.7739506 L5.61486486,20.6360196 L10.75,20.6360196 L10.75,12.7739506 Z M13.25,12.7739506 L13.25,20.6360196 L18.3851351,20.6360196 L18.3851351,12.7739506 L13.25,12.7739506 Z'
    />
  </Icon>
);
