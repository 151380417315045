import type { ListboxProviderProps } from './listbox.provider';
import { ListboxProvider } from './listbox.provider';
import { ListboxList } from './listbox-list.component';
import { ListboxOption } from './listbox-option.component';

export type ListboxProps = Required<Pick<ListboxProviderProps, 'children' | 'labelId' | 'onSelect' | 'value'>> & {
  className?: string;
};

export const Listbox = ({ children, className, ...rest }: ListboxProps) => (
  <ListboxProvider {...rest}>
    <ListboxList className={className}>{children}</ListboxList>
  </ListboxProvider>
);

Listbox.Option = ListboxOption;
