import { useStyles } from '../../use-styles';
import { Avatar, AvatarProps } from '../avatar/avatar.component';
import { useTooltip } from '../tooltip';
import { Text } from '../text/text.component';
import { SIZE_MAP } from './avatar-group.styles';
import { Children, ReactNode } from 'react';
import { createGetSpecificChildren } from '../../helpers';

type AvatarGroupChildrenTypes = 'Avatar';

export type AvatarGroupProps = {
  children: ReactNode;
  size?: AvatarProps['size'];
  maxAvatars?: number;
  borderColor?: string;
  fallbackName: string;
  hideOverflow?: boolean;
};

export const AvatarGroup = ({
  children,
  size = 'medium',
  maxAvatars = 3,
  borderColor,
  fallbackName,
  hideOverflow,
}: AvatarGroupProps) => {
  const getChild = createGetSpecificChildren<AvatarGroupChildrenTypes>(children, true);
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ trigger: 'hover' });
  const avatarChildren = getChild('Avatar');
  const avatarsToDisplay = avatarChildren.slice(0, maxAvatars);
  const overflowAvatars = avatarChildren.slice(maxAvatars);
  const showOverflow = !hideOverflow && overflowAvatars.length > 0;
  const avatarGroupStyles = useStyles('AvatarGroup', 'group');
  const avatarStyles = useStyles('AvatarGroup', 'avatar', { borderColor, size });
  const firstAvatarStyles = useStyles('AvatarGroup', 'firstAvatar', { borderColor, size });
  const textStyles = useStyles('AvatarGroup', 'text', { size });
  const tooltipContentStyles = useStyles('AvatarGroup', 'tooltipContent');
  const { textSize } = SIZE_MAP[size];

  return (
    <>
      <span css={avatarGroupStyles}>
        {Children.map(avatarsToDisplay, ({ props }, index) => {
          return <Avatar {...props} size={size} css={index === 0 ? firstAvatarStyles : avatarStyles} />;
        })}
        {showOverflow && (
          <Text color='light' css={textStyles} size={textSize} {...triggerProps}>{`+${overflowAvatars.length}`}</Text>
        )}
      </span>
      {showOverflow && (
        <Tooltip css={tooltipContentStyles} {...tooltipProps}>
          {Children.map(overflowAvatars, (child) => {
            return (
              <span>
                {child}
                <Text color='white'>{child.props.name || fallbackName}</Text>
              </span>
            );
          })}
        </Tooltip>
      )}
    </>
  );
};

AvatarGroup.displayName = 'AvatarGroup';
