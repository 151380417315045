/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const VoicemailOverrideIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M18.25,12 C21.1494949,12 23.5,14.3505051 23.5,17.25 C23.5,20.1494949 21.1494949,22.5 18.25,22.5 C18.1690445,22.5 18.0885169,22.4981676 18.0084565,22.4945421 L18,22.5 L5.75,22.5 C2.85050506,22.5 0.5,20.1494949 0.5,17.25 C0.5,14.3505051 2.85050506,12 5.75,12 C8.64949494,12 11,14.3505051 11,17.25 C11,18.2585743 10.7155982,19.200723 10.2226166,20.0006243 L13.7773834,20.0006243 C13.2844018,19.200723 13,18.2585743 13,17.25 C13,14.3505051 15.3505051,12 18.25,12 Z M5.75,14.5 C4.23121694,14.5 3,15.7312169 3,17.25 C3,18.7687831 4.23121694,20 5.75,20 C7.26878306,20 8.5,18.7687831 8.5,17.25 C8.5,15.7312169 7.26878306,14.5 5.75,14.5 Z M18.25,14.5 C16.7312169,14.5 15.5,15.7312169 15.5,17.25 C15.5,18.7687831 16.7312169,20 18.25,20 C19.7687831,20 21,18.7687831 21,17.25 C21,15.7312169 19.7687831,14.5 18.25,14.5 Z M16.2606602,1.61611652 L19.4426407,4.79809704 C19.9307961,5.2862524 19.9307961,6.07770863 19.4426407,6.56586399 L16.2606602,9.74784451 C15.7725048,10.2359999 14.9810486,10.2359999 14.4928932,9.74784451 C14.0047379,9.25968914 14.0047379,8.46823292 14.4928932,7.98007755 L15.523,6.949 L5.45,6.95 C4.75964406,6.95 4.2,6.39035594 4.2,5.7 C4.2,5.00964406 4.75964406,4.45 5.45,4.45 L15.558,4.449 L14.4928932,3.38388348 C14.0047379,2.89572811 14.0047379,2.10427189 14.4928932,1.61611652 C14.9810486,1.12796116 15.7725048,1.12796116 16.2606602,1.61611652 Z'
    />
  </Icon>
);
