import { css } from '@emotion/react';
import { TableInstance } from 'react-table';
import { TableHeader } from '../atoms';
import { TableData } from '../table-data-type';
import { CellPositionCord } from '../table-types';

type TableHeadersProps<T extends TableData> = {
  tableInstance: TableInstance<T>;
  tableId: string;
  currentCellPositionRef: React.MutableRefObject<CellPositionCord>;
};

export const TableHeaders = <T extends TableData>({
  tableInstance,
  tableId,
  currentCellPositionRef,
}: TableHeadersProps<T>) => {
  const { headerGroups } = tableInstance;

  return (
    <div
      className='header'
      role='rowgroup'
      css={css`
        /* Needed to escape stacking context */
        transform-style: preserve-3d;
      `}
    >
      {headerGroups.map((headerGroup, headerGroupIndex) => (
        <div
          {...headerGroup.getHeaderGroupProps()}
          key={headerGroupIndex}
          className={`table__row thead headers__${tableId}`}
        >
          {headerGroup.headers.map((column, headerIndex) => (
            <TableHeader
              key={column.id}
              headerYPosition={headerGroupIndex + 1}
              headersXPosition={headerIndex}
              tableId={tableId}
              column={column}
              tableInstance={tableInstance}
              currentCellPositionRef={currentCellPositionRef}
            />
          ))}
        </div>
      ))}
    </div>
  );
};
