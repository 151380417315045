import React, { Children } from 'react';
import type { ListboxChildren } from '../../../listbox';
import { ListboxOption } from '../../../listbox';
import { getListMaxError } from '../../hooks';
import type { BasicFormFieldProps } from '../../layouts';
import { isMaxed } from '../list-field';
import { ListFieldItem } from '../list-field-item';
import { ListFieldUl } from '../list-field-ul';
import { DropdownFieldInternal } from './dropdown-field-internal.component';

type Props = BasicFormFieldProps<'selectlist'> & {
  children: ListboxChildren;
};

export const SelectListField = ({
  children,
  className,
  helperText,
  maxAllowed,
  minRequired,
  value,
  ...rest
}: Props) => {
  const options = Children.toArray(children) as Array<React.ReactElement>;
  // remove the selected options from the list
  const availableOptions = options.filter((child) => !value.includes((child as React.ReactElement).props.value));
  const getDisplayValue = (option: string) => {
    const child = options.find(({ props }) => props.value === option);
    if (!child) return option;
    return child.props.children;
  };
  // disable if at upper limit
  const atMax = isMaxed({ maxAllowed, count: value.length });
  return (
    <div className={className}>
      <DropdownFieldInternal
        value=''
        {...rest}
        disabled={atMax || rest.disabled}
        helperText={atMax ? getListMaxError(maxAllowed as number) : helperText}
      >
        {availableOptions}
      </DropdownFieldInternal>
      {value.length > 0 && (
        <ListFieldUl>
          {value.map((item) => (
            <ListFieldItem
              key={item}
              onRemove={() => {
                rest.onChange({ name: rest.name, value: item });
              }}
            >
              {getDisplayValue(item)}
            </ListFieldItem>
          ))}
        </ListFieldUl>
      )}
    </div>
  );
};

SelectListField.Option = ListboxOption;
