import { Link } from '@tanstack/react-location';
import { css } from '@emotion/react';
import { NakedButton } from '../../naked/naked-button.component';
import { Text } from '../text/text.component';

export type BreadcrumbPathProps = {
  to?: string;
  label: string;
  onClick?: () => void;
};

export const BreadcrumbPath = ({ to, label, onClick }: BreadcrumbPathProps) => {
  if (onClick) {
    return (
      <Text as={NakedButton} color='light' onClick={onClick} data-trackingid={`shrd-nav-breadcrumb-${label}`}>
        {label}
      </Text>
    );
  } else if (to) {
    return (
      <Text
        //@ts-ignore - TS doesn't pick up the to prop since we are rendering as a link
        to={to}
        color='light'
        as={Link}
        data-trackingid={`shrd-nav-breadcrumb-${label}`}
        css={css`
          text-decoration: none;
        `}
      >
        {label}
      </Text>
    );
  }
  return <Text color='light'>{label}</Text>;
};
