import React from 'react';
import { NavSize, NavSizes } from './breakpoints';
import { useBreakpoint } from './use-media-matches';

export const useNavSize = () => {
  const sizes = React.useMemo(() => {
    return Object.values(NavSize) as NavSizes[];
  }, []);

  const size = useBreakpoint();

  const state = React.useMemo(() => {
    const currentIndex = sizes.indexOf(size);

    const isLt = (compareSize: NavSizes) => {
      const compareIndex = sizes.indexOf(compareSize);
      return currentIndex < compareIndex;
    };
    const isLte = (compareSize: NavSizes) => {
      const compareIndex = sizes.indexOf(compareSize);
      return currentIndex <= compareIndex;
    };
    const isGt = (compareSize: NavSizes) => {
      const compareIndex = sizes.indexOf(compareSize);
      return currentIndex > compareIndex;
    };
    const isGte = (compareSize: NavSizes) => {
      const compareIndex = sizes.indexOf(compareSize);
      return currentIndex >= compareIndex;
    };

    return {
      size,
      isLt,
      isLte,
      isGt,
      isGte,
    };
  }, [size]);

  return state;
};
