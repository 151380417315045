/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const LabelIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M11.2495618,0.287979057 C12.4666534,-0.755242333 14.2626141,-0.755242333 15.4797057,0.287979057 L15.4797057,0.287979057 L20.4797057,4.57369334 C21.2000549,5.19113549 21.6146338,6.09252142 21.6146338,7.0412773 L21.6146338,7.0412773 L21.6146338,19.1214075 C21.6146338,20.9163329 20.1595592,22.3714075 18.3646338,22.3714075 L18.3646338,22.3714075 L8.36463376,22.3714075 C6.56970832,22.3714075 5.11463376,20.9163329 5.11463376,19.1214075 L5.11463376,19.1214075 L5.11463376,7.0412773 C5.11463376,6.09252142 5.52921263,5.19113549 6.2495618,4.57369334 L6.2495618,4.57369334 Z M13.8527273,2.18612056 C13.57186,1.94537716 13.1574075,1.94537716 12.8765402,2.18612056 L12.8765402,2.18612056 L7.87654023,6.47183485 C7.71030581,6.6143215 7.61463376,6.82233363 7.61463376,7.0412773 L7.61463376,7.0412773 L7.61463376,19.1214075 C7.61463376,19.535621 7.9504202,19.8714075 8.36463376,19.8714075 L8.36463376,19.8714075 L18.3646338,19.8714075 C18.7788473,19.8714075 19.1146338,19.535621 19.1146338,19.1214075 L19.1146338,19.1214075 L19.1146338,7.0412773 C19.1146338,6.82233363 19.0189617,6.6143215 18.8527273,6.47183485 L18.8527273,6.47183485 Z M13.3646338,6.62140745 C14.1930609,6.62140745 14.8646338,7.29298033 14.8646338,8.12140745 C14.8646338,8.94983458 14.1930609,9.62140745 13.3646338,9.62140745 C12.5362066,9.62140745 11.8646338,8.94983458 11.8646338,8.12140745 C11.8646338,7.29298033 12.5362066,6.62140745 13.3646338,6.62140745 Z'
      transform='translate(13.364634, 10.938485) rotate(55.000000) translate(-13.364634, -10.938485) '
    />
  </Icon>
);
