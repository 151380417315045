import { css } from '@emotion/react';
import { NakedUl } from '../../../naked';
import { ReactNode } from 'react';
import { useThemeValues } from '../../../hooks';

export const ListFieldUl: React.FC<React.PropsWithChildren<{ children: ReactNode }>> = ({ children }) => {
  const theme = useThemeValues();
  return (
    <NakedUl
      css={css`
        margin: ${theme.spacing(2, 0, 0)};
      `}
    >
      {children}
    </NakedUl>
  );
};
