import { FieldKey } from '../../';
import { isEmpty } from 'lodash-es';

export function convertObjectToArray(obj: object): { id: string; value: string }[] {
  const arr: { id: string; value: string }[] = [];
  for (const key in obj) {
    if (key && !isEmpty(obj[key])) {
      arr.push({ id: key, value: obj[key] });
    }
  }
  return arr;
}

const defaultValueByType = {
  checkbox: false,
  checklist: [],
  dateRange: [],
  list: [],
  multiselect: [],
  selectlist: [],
  timeRange: [],
  switch: false,
};

export const getDefaultFieldValue = (type: FieldKey) => defaultValueByType[type] ?? '';

export const isFilterDisabled = (filterValue: any) => {
  if (Array.isArray(filterValue)) {
    return filterValue.length === 0;
  }
  return !filterValue;
};
