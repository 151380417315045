import { KeyNames } from '../../../../constants';
import { css } from '@emotion/react';
import React, { useEffect, useRef, useState } from 'react';
import { styles } from '../../../../styles';
import { Text } from '../../../text';
import { DatePickerBaseField, DatePickerBaseFieldRef } from '../date-picker/date-picker-base-field.component';
import { RangeFieldType, useRangeProps } from './use-range-props';
import { useDatePickerDialog } from '../date-picker/use-date-picker';
import { DatePickerDialog } from '../date-picker/date-picker-dialog.component';
import { BasicFormFieldProps } from '../../layouts/types';
import { useCalendar } from '../../../calendar/provider/use-calendar';
import { FormRow } from '../../atoms/form-row.component';
import { useThemeValues } from '../../../../hooks/use-theme-values';
import { useDidUpdate } from '../../../../hooks/use-did-update';

type DateRangeFieldProps = Omit<BasicFormFieldProps<'dateRange'>, 'helperText'> & {
  labelMin?: string;
  labelMax?: string;
  showLabel?: boolean;
  className?: string;
};

type RangeLabel = 'min' | 'max';

export const DateRangeField = ({
  value: fieldValue,
  onChange: fieldOnChange,
  required = false,
  labelMin = 'Start Date',
  labelMax = 'End Date',
  name,
  hidden,
  label,
  showLabel = false,
  minDate,
  maxDate,
  blackoutDates,
  today,
  className,
  disabled,
}: DateRangeFieldProps) => {
  const [editField, setEditField] = useState<RangeLabel>('min');
  const startField = useRef<HTMLInputElement>(null);
  const endField = useRef<HTMLInputElement>(null);
  const startTrigger = useRef<HTMLButtonElement>(null);
  const endTrigger = useRef<HTMLButtonElement>(null);

  const handleCalendarClick = (side: RangeLabel) => {
    if (flyoutActive && editField === side) {
      closeFlyout();
    } else {
      openFlyout();
      setEditField(side);
    }
  };

  const handleKeyDown = (clear: () => void, type: RangeFieldType) => (e: React.KeyboardEvent) => {
    switch (e.key) {
      case KeyNames.Escape:
        e.preventDefault();
        if (flyoutActive) closeFlyout();
        break;
      case KeyNames.Enter:
      case KeyNames.Space:
        e.preventDefault();
        if (!flyoutActive) {
          clear();
          openFlyout();
          setEditField(type);
        }
        break;
      case KeyNames.Down:
        e.preventDefault();
        if (!flyoutActive) {
          clear();
          openFlyout();
          setEditField(type);
        }
        break;
      default:
        break;
    }
  };

  const {
    closeDialog: closeFlyout,
    openDialog: openFlyout,
    open: flyoutActive,
    dialogProps: flyoutProps,
    triggerProps,
  } = useDatePickerDialog<HTMLDivElement>({
    placement: editField === 'min' ? 'bottom-start' : 'bottom-end',
    clickOutsideIgnoreRefs: [startField, endField],
    offsetDistance: [0, 8],
    initialFocus: -20,
    trigger: editField === 'min' ? startField : endField,
  });

  const minProps = useRangeProps({
    type: 'min',
    flyoutActive: editField === 'min' && flyoutActive,
    required,
    label: labelMin,
    groupName: name,
    onCalendarClick: handleCalendarClick,
    value: fieldValue[0],
    onKeyDown: handleKeyDown,
  });

  const maxProps = useRangeProps({
    type: 'max',
    flyoutActive: editField === 'max' && flyoutActive,
    required,
    label: labelMax,
    groupName: name,
    onCalendarClick: handleCalendarClick,
    value: fieldValue[1],
    onKeyDown: handleKeyDown,
  });

  useDidUpdate(() => {
    fieldOnChange({
      value: [minProps.value, maxProps.value],
      name,
    });
  }, [maxProps.value, minProps.value]);

  const handleDateSelect = (value: string | string[]) => {
    minProps.onChange({
      value: value[0],
      name: name + ` ${editField}`,
    });
    maxProps.onChange({
      value: value[1],
      name: name + ` ${editField}`,
    });

    if (editField === 'min' && !maxProps.value) {
      setEditField('max');
    } else if (editField === 'max' && !minProps.value) {
      setEditField('min');
    } else {
      // short delay so users get visual feedback of the change
      // have to force the trigger value because it won't have updated in time
      setTimeout(() => closeFlyout(editField === 'min' ? startTrigger : endTrigger), 100);
    }
  };

  const startRef = useRef<DatePickerBaseFieldRef>({ buttonRef: startTrigger, fieldRef: startField });
  const endRef = useRef<DatePickerBaseFieldRef>({ buttonRef: endTrigger, fieldRef: endField });

  const calendarProps = useCalendar({
    onSelect: handleDateSelect,
    dayNameFormat: 'one',
    value: [minProps.value, maxProps.value],
    isRange: true,
    focusedRange: editField,
    maxDate,
    minDate,
    blackoutDates,
    today,
  });

  useEffect(() => {
    // update focus if moving between range fields with active flyout
    if (flyoutActive) {
      calendarProps.reset();
    }
  }, [editField]);

  const id = `date-range-${minProps.id}`; // useFormField generates a unique id
  const theme = useThemeValues();
  return (
    <div role='group' aria-labelledby={id} className={className}>
      <Text
        as='label'
        id={id}
        className='date-range__label'
        css={[
          css`
            margin: ${theme.spacing(0, 0, 1, 0)};
          `,
          !showLabel && styles.visuallyHidden,
        ]}
      >
        {label}
      </Text>
      <FormRow
        ref={triggerProps.ref}
        cols={[50, 50]}
        hidden={hidden}
        css={css`
          position: relative;
          min-width: 320px;
          margin-bottom: 0;
        `}
      >
        <DatePickerBaseField disabled={disabled} ref={startRef} {...minProps} />
        <DatePickerBaseField disabled={disabled} ref={endRef} {...maxProps} />

        <DatePickerDialog
          {...flyoutProps}
          onFocus={() => {
            const rangeProps = editField === 'min' ? minProps : maxProps;
            rangeProps.onFocus();
          }}
        >
          <DatePickerDialog.Calendar {...calendarProps} />
        </DatePickerDialog>
      </FormRow>
    </div>
  );
};
