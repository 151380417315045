import { Icon, IconProps } from '../Icon.component';

export const DisconnectIconSmall: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={16} {...props} viewBox='0 0 16 16'>
    <path
      d='M2.69944 2.68985L12.8759 13.3101M7.5642 2.68985C8.32622 1.92792 9.35969 1.49992 10.4373 1.5C11.5149 1.50008 12.5483 1.92822 13.3102 2.69025C14.0721 3.45228 14.5001 4.48577 14.5 5.56337C14.4999 6.64097 14.0718 7.6744 13.3098 8.43632M8.49051 13.3101C7.7196 14.0724 6.67916 14.5 5.59498 14.5C4.5108 14.5 3.47036 14.0724 2.69944 13.3101C2.31946 12.9344 2.01779 12.487 1.8119 11.9939C1.60601 11.5007 1.5 10.9716 1.5 10.4373C1.5 9.90288 1.60601 9.37381 1.8119 8.88068C2.01779 8.38755 2.31946 7.94017 2.69944 7.56443'
      stroke='white'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </Icon>
);
