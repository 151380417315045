/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const ExportIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M22,15.7499978 C22.6903559,15.7499978 23.25,16.3096441 23.25,17 L23.25,17 L23.25,20.5 C23.25,21.4664983 22.4664983,22.2499978 21.5,22.2499978 L21.5,22.2499978 L2.49769024,22.2499978 C1.53119358,22.2482104 0.749141528,21.4632618 0.750921991,20.4967651 L0.750921991,20.4967651 L0.757396109,16.9976894 C0.758672225,16.3073346 1.31934983,15.748726 2.00970459,15.7499978 C2.70005934,15.7512783 3.25866795,16.3119559 3.25739184,17.0023106 L3.25739184,17.0023106 L3.25231275,19.7499978 L20.75,19.7499978 L20.75,17 C20.75,16.3096441 21.3096441,15.7499978 22,15.7499978 Z M11.7208124,2.45568564 C12.0190739,2.47087269 12.3129319,2.59236914 12.5407377,2.82017498 L12.5407377,2.82017498 L16.429825,6.70926227 C16.9179804,7.19741764 16.9179804,7.98887386 16.429825,8.47702923 C15.9416697,8.96518459 15.1502134,8.96518459 14.6620581,8.47702923 L14.6620581,8.47702923 L12.905919,6.72068564 L12.9068542,15.75 C12.9068542,16.3972087 12.4149796,16.9295339 11.7846595,16.9935464 L11.6568542,17 C10.9664983,17 10.4068542,16.4403559 10.4068542,15.75 L10.4068542,15.75 L10.405919,6.72168564 L8.65165043,8.47702923 C8.19603876,8.9326409 7.47621833,8.96301501 6.98537883,8.56815156 L6.88388348,8.47702923 C6.39572811,7.98887386 6.39572811,7.19741764 6.88388348,6.70926227 L6.88388348,6.70926227 L10.7729708,2.82017498 C11.0007766,2.59236914 11.2946346,2.47087269 11.5928961,2.45568564 Z'
    />
  </Icon>
);
