import { Text } from '../../../';
import { TextLink } from '../../../';
import { useStyles } from '../../../../use-styles';

export const FilterHeader = ({ render, label, onClick, disabled }) => {
  const filterSectionHeaderStyles = useStyles('TableCommonStyles', 'tableFilterStyles', {
    type: 'filterSectionHeader',
  });

  return (
    <section css={filterSectionHeaderStyles}>
      <Text weight='semibold'>{label ?? render('Header')}</Text>
      <TextLink disabled={disabled} weight='semibold' onClick={onClick}>
        Clear
      </TextLink>
    </section>
  );
};
