import { useCallback } from 'react';
import { useFormField, useDebouncedBlur } from '../../hooks';
import { DatePickerBaseFieldProps } from '../date-picker/date-picker-base-field.component';

export type RangeFieldType = 'min' | 'max';

type UseRangeProps = {
  flyoutActive: boolean;
  groupName: string;
  label: React.ReactNode;
  onCalendarClick?: (type: RangeFieldType) => void;
  onKeyDown: (clear: () => void, type: RangeFieldType) => (e: React.KeyboardEvent) => void;
  required?: boolean;
  type: RangeFieldType;
  value?: string;
};

export const useRangeProps = ({
  flyoutActive,
  groupName,
  onCalendarClick,
  onKeyDown,
  required,
  type,
  value,
  ...passThroughProps
}: UseRangeProps): Omit<DatePickerBaseFieldProps, 'ref'> => {
  const { onFocus, onBlur, active, ...fieldProps } = useFormField(
    {
      value,
      required: required,
      type: 'date',
    },
    [value]
  );

  const { blur, focus, clear } = useDebouncedBlur({
    delay: 200,
    onBlur,
    onFocus,
  });

  const handleCalendarClick = useCallback(() => {
    clear();
    onCalendarClick?.(type);
  }, [clear, onCalendarClick]);

  return {
    name: `${groupName}-${type === 'min' ? 'start' : 'end'}`,
    onCalendarIconClick: handleCalendarClick,
    onBlur: blur,
    onFocus: focus,
    onKeyDown: onKeyDown(clear, type),
    fieldActive: active ?? false,
    flyoutActive,
    ...fieldProps,
    ...passThroughProps,
  };
};
