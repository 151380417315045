/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const PrintIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M16.95,1.25 C17.9189347,1.25 18.75,2.00551388 18.75,3 L18.75,3 L18.75,7.25 L19.875,7.25 C21.0806205,7.25 22.156675,8.07065623 22.2442531,9.24487442 L22.25,9.4 L22.25,16.6 C22.25,17.856431 21.1330388,18.75 19.875,18.75 L19.875,18.75 L18.75,18.75 L18.75,21 C18.75,21.9664983 17.9664983,22.75 17,22.75 L7,22.75 C6.03350169,22.75 5.25,21.9664983 5.25,21 L5.25,18.75 L4.125,18.75 C2.91937951,18.75 1.84332503,17.9293438 1.75574691,16.7551256 L1.75,16.6 L1.75,9.4 C1.75,8.14356898 2.86696122,7.25 4.125,7.25 L4.125,7.25 L5.25,7.25 L5.25,3 C5.25,2.05523818 6.0000364,1.32614882 6.90578046,1.25560179 L7.05,1.25 Z M16.25,15.75 L7.75,15.75 L7.75,20.25 L16.25,20.25 L16.25,15.75 Z M14.5,18.25 C14.7761424,18.25 15,18.4738576 15,18.75 C15,19.0261424 14.7761424,19.25 14.5,19.25 L9.5,19.25 C9.22385763,19.25 9,19.0261424 9,18.75 C9,18.4738576 9.22385763,18.25 9.5,18.25 L14.5,18.25 Z M14.5,16.75 C14.7761424,16.75 15,16.9738576 15,17.25 C15,17.5261424 14.7761424,17.75 14.5,17.75 L9.5,17.75 C9.22385763,17.75 9,17.5261424 9,17.25 C9,16.9738576 9.22385763,16.75 9.5,16.75 L14.5,16.75 Z M19.75,9.75 L4.25,9.75 L4.25,16.25 L5.25,16.25 L5.25,15 C5.25,14.0335017 6.03350169,13.25 7,13.25 L17,13.25 C17.9664983,13.25 18.75,14.0335017 18.75,15 L18.75,16.25 L19.75,16.25 L19.75,9.75 Z M16.25,3.75 L7.75,3.75 L7.75,7.25 L16.25,7.25 L16.25,3.75 Z'
    />
  </Icon>
);
