import { useCallback, useMemo, useState } from 'react';
import { genUID } from '../../helpers';

export function useListboxState(initialValue: string | string[]) {
  const labelId = useMemo(() => genUID(), []);
  const [value, setValue] = useState(initialValue);

  const onSelect = useCallback((selectedValue: string | string[]) => {
    if (Array.isArray(initialValue)) {
      // allow bulk selection, such as ctrl + a or meta keys + home/end
      if (Array.isArray(selectedValue)) {
        setValue(selectedValue);
      } else {
        setValue((values) => {
          if (values.includes(selectedValue)) {
            return (values as string[]).filter((v) => v !== selectedValue);
          } else {
            return [...(values as string[]), selectedValue];
          }
        });
      }
    } else {
      setValue(selectedValue);
    }
  }, []);

  return { labelId, onSelect, value };
}
