/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const HamburgerMenuIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M2,13.0277778 C1.30964406,13.0277778 0.75,12.4681337 0.75,11.7777778 C0.75,11.0874218 1.30964406,10.5277778 2,10.5277778 L22,10.5277778 C22.6903559,10.5277778 23.25,11.0874218 23.25,11.7777778 C23.25,12.4681337 22.6903559,13.0277778 22,13.0277778 L2,13.0277778 Z M2,6.36111111 C1.30964406,6.36111111 0.75,5.80146705 0.75,5.11111111 C0.75,4.42075517 1.30964406,3.86111111 2,3.86111111 L22,3.86111111 C22.6903559,3.86111111 23.25,4.42075517 23.25,5.11111111 C23.25,5.80146705 22.6903559,6.36111111 22,6.36111111 L2,6.36111111 Z M2,19.6944444 C1.30964406,19.6944444 0.75,19.1348004 0.75,18.4444444 C0.75,17.7540885 1.30964406,17.1944444 2,17.1944444 L22,17.1944444 C22.6903559,17.1944444 23.25,17.7540885 23.25,18.4444444 C23.25,19.1348004 22.6903559,19.6944444 22,19.6944444 L2,19.6944444 Z'
    />
  </Icon>
);
