/**
 * Returns back an array of focusable elements
 * that exist within the passed parent HTMLElement, or
 * an empty array if no parent passed.
 *
 * @param {HTMLElement} parent HTML element
 * @returns {HTMLElement[]} The focusable elements that we can find
 */

import { isEqual } from 'lodash-es';
import { CellPositionCord } from '../table-types';

export const getAllFocusableElements = (parent: HTMLElement | null): HTMLElement[] => {
  if (!parent) {
    console.warn('You need to pass a parent HTMLElement');
    return [];
  }

  return Array.from(
    parent.querySelectorAll(
      `button:not([disabled]), 
      [href]:not(use), 
      input:not([disabled]), 
      select:not([disabled]), 
      textarea:not([disabled]), 
      [tabindex]:not([tabindex="-1"]):not([disabled]), 
      details:not([disabled]), 
      summary:not(:disabled),
      audio[controls], 
      video[controls], 
      [contenteditable]:not([contenteditable="false"])`
    )
  );
};

export const addFocusableState = (cell: HTMLElement, focusableElements: Element[]) => {
  if (!!cell.querySelector('[data-focusable]')) {
    cell.setAttribute('tabindex', '0');
    return;
  }

  addFocusableElementsState(cell, focusableElements);
};

export const addFocusableElementsState = (cell: HTMLElement, focusableElements: Element[]) => {
  if (focusableElements.length > 0) {
    focusableElements.forEach((element) => {
      const dataTabIndex = element.getAttribute('data-tabindex');
      if (dataTabIndex) {
        element.setAttribute('tabindex', dataTabIndex);
        return;
      }
      element.removeAttribute('tabindex');
      return;
    });
    return;
  }
  cell.setAttribute('tabindex', '0');
};

export const removeFocusableState = (cell: HTMLElement | null, focusableElements: Element[]) => {
  if (focusableElements.length > 0) {
    focusableElements.forEach((element) => {
      const currentTabIndex = element.getAttribute('tabindex');
      const dataTabIndex = element.getAttribute('data-tabindex');

      if (currentTabIndex && Number(currentTabIndex) > -1 && !dataTabIndex) {
        element.setAttribute('data-tabindex', currentTabIndex);
      }
      element.setAttribute('tabindex', '-1');
    });
  }

  const currentCellTabIndex = cell?.getAttribute('tabindex');

  if (currentCellTabIndex) {
    cell?.removeAttribute('tabindex');
  }

  if (cell?.tagName === 'BUTTON') {
    cell.setAttribute('tabindex', '-1');
  }
  return;
};

export const setFocusableElements = (
  cell: HTMLElement,
  cellPosition: CellPositionCord,
  currentCellPositionRef: React.MutableRefObject<CellPositionCord>
) => {
  const focusableElements = getAllFocusableElements(cell);

  if (isEqual(cellPosition, currentCellPositionRef.current)) {
    addFocusableState(cell, focusableElements);
    return;
  } else {
    removeFocusableState(cell, focusableElements);
  }
};

export const isInteractiveElement = () => {
  const activeElement = document.activeElement;

  if (activeElement?.getAttribute('role') === 'cell') return false;
  if (activeElement?.getAttribute('role') === 'columnheader') return false;

  const cellElem = activeElement?.closest('[role="cell"]');
  const shouldFocus = cellElem?.querySelector('[data-focusable]');

  if (!shouldFocus) return false;

  return true;
};

export const handleEscape = () => {
  const activeElement = document.activeElement;

  const activeElementRole = activeElement?.getAttribute('role');
  if (activeElementRole === 'cell' || activeElementRole === 'columnheader') return;

  const cellElem = activeElement?.closest('[role="cell"]') as HTMLElement | null;
  const hasInteractiveElement = cellElem?.querySelector('[data-focusable]');

  if (!hasInteractiveElement) return false;

  activeElement?.setAttribute('tabindex', '-1');
  cellElem?.setAttribute('tabindex', '0');
  cellElem?.focus();
  return;
};

export const handleEnter = () => {
  const activeElement = document.activeElement as HTMLElement | null;

  if (activeElement?.getAttribute('role') !== 'cell') return false;

  const interactiveElement = activeElement.querySelector('[data-focusable]');
  if (!interactiveElement) return false;

  const focusableElements = getAllFocusableElements(activeElement);
  addFocusableElementsState(activeElement, focusableElements);
  const focusableElementsa = getAllFocusableElements(activeElement);
  focusableElementsa[0]?.focus();

  return true;
};
