import { useListboxContext } from './listbox.provider';
import type { ListboxChildren } from './listbox.provider';
import { StyledListboxList } from './styled-listbox-list';

type Props = React.HTMLAttributes<HTMLUListElement> & {
  children: ListboxChildren;
};

export const ListboxList = ({ children, ...rest }: Props) => {
  const { listboxProps } = useListboxContext();
  return (
    <StyledListboxList {...listboxProps} {...rest}>
      {children}
    </StyledListboxList>
  );
};
