/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const MarkIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M19.75,16.0270182 L19.75,19 C19.75,19.4142136 19.4142136,19.75 19,19.75 L5,19.75 C4.58578644,19.75 4.25,19.4142136 4.25,19 L4.25,5 C4.25,4.58578644 4.58578644,4.25 5,4.25 L8.05289714,4.25 C8.74325307,4.25 9.30289714,3.69035594 9.30289714,3 C9.30289714,2.30964406 8.74325307,1.75 8.05289714,1.75 L5,1.75 C3.20507456,1.75 1.75,3.20507456 1.75,5 L1.75,19 C1.75,20.7949254 3.20507456,22.25 5,22.25 L19,22.25 C20.7949254,22.25 22.25,20.7949254 22.25,19 L22.25,16.0270182 C22.25,15.3366623 21.6903559,14.7770182 21,14.7770182 C20.3096441,14.7770182 19.75,15.3366623 19.75,16.0270182 Z M21.4834151,3.08945748 C22.0347579,3.50492405 22.1449073,4.28867788 21.7294407,4.84002064 L14.8085679,14.024329 C14.7555527,14.0946826 14.6965407,14.1578524 14.63278,14.2136629 L14.6829101,14.1524216 C14.2674435,14.7037644 13.4836897,14.8139138 12.932347,14.3984472 L8.53985165,11.0884646 C7.98850888,10.672998 7.87835946,9.8892442 8.29382604,9.33790143 C8.70929261,8.78655866 9.49304644,8.67640925 10.0443892,9.09187582 L13.4575137,11.662695 L19.7328519,3.33548308 C20.1483185,2.78414032 20.9320723,2.6739909 21.4834151,3.08945748 Z'
    />
  </Icon>
);
