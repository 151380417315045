import { css } from '@emotion/react';
import { TableInstance } from 'react-table';
import { useThemeValues } from '../../../hooks';
import { TableBulkActions } from '../table-types';
import { BulkActions } from './bulk-actions.component';
import { SelectAllRows } from './select-all-rows.component';
import { TableData } from '../table-data-type';
import { Text } from '../../';

type BulkRowsUtilitiesProps<T extends TableData> = {
  tableInstance: TableInstance<T>;
  bulkActions?: TableBulkActions<T>[];
};

export const BulkRowsUtilities = <T extends TableData>({ tableInstance, bulkActions }: BulkRowsUtilitiesProps<T>) => {
  const { selectedFlatRows, flatRows, isMobile, rowSelectionConfig } = tableInstance;
  const { spacing } = useThemeValues();

  const nonDisabledRows = !rowSelectionConfig?.isRowDisabled
    ? [...flatRows]
    : flatRows.filter((row) =>
        typeof rowSelectionConfig?.isRowDisabled === 'boolean'
          ? !rowSelectionConfig?.isRowDisabled
          : !rowSelectionConfig?.isRowDisabled?.(row.original, row)
      );

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        margin-right: auto;
        flex-shrink: 1;
        padding-left: ${spacing(1)};
      `}
    >
      <SelectAllRows tableInstance={tableInstance} nonDisabledRows={nonDisabledRows} />
      {!!selectedFlatRows.length && (
        <>
          {isMobile ? (
            <Text
              css={css`
                margin: 0;
                margin-left: ${spacing(1)};
              `}
              size='medium'
            >
              {selectedFlatRows.length}/{nonDisabledRows.length}
            </Text>
          ) : (
            <Text
              css={css`
                margin: 0;
                margin-left: ${spacing(2)};
              `}
              size='medium'
            >
              Selected {selectedFlatRows.length} of {nonDisabledRows.length}
            </Text>
          )}
          <BulkActions tableInstance={tableInstance} bulkActions={bulkActions} selectedRows={selectedFlatRows} />
        </>
      )}
    </div>
  );
};
