import { Icon, IconProps } from '../Icon.component';

export const DocumentWarningIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M8.48987 1.9873H13.0148C14.2851 1.9873 15.5078 2.47081 16.4345 3.33962L18.9299 5.67905C19.9381 6.62428 20.5102 7.94469 20.5102 9.32673V17.0127C20.5102 19.7741 18.2716 22.0127 15.5102 22.0127H8.48987C5.72844 22.0127 3.48987 19.7741 3.48987 17.0127V6.98731C3.48987 4.22588 5.72844 1.9873 8.48987 1.9873ZM8.48987 4.4873C7.10916 4.4873 5.98987 5.60659 5.98987 6.98731V17.0127C5.98987 18.3934 7.10915 19.5127 8.48987 19.5127H15.5102C16.8909 19.5127 18.0102 18.3934 18.0102 17.0127V9.32673C18.0102 8.63571 17.7242 7.97551 17.22 7.50289L14.7246 5.16346C14.2613 4.72906 13.6499 4.4873 13.0148 4.4873H8.48987Z'
    />
    <path
      fillRule='evenodd'
      d='M13 8.25C13 7.55964 12.4404 7 11.75 7C11.0596 7 10.5 7.55964 10.5 8.25V11.75C10.5 12.4404 11.0596 13 11.75 13C12.4404 13 13 12.4404 13 11.75L13 8.25ZM11.7803 16.5C12.4706 16.5 13.0303 15.9404 13.0303 15.25C13.0303 14.5596 12.4706 14 11.7803 14C11.0899 14 10.5303 14.5596 10.5303 15.25C10.5303 15.9404 11.0899 16.5 11.7803 16.5Z'
    />
  </Icon>
);
