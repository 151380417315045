/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const PayIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M13.25,12 C13.25,11.3096441 12.6903559,10.75 12,10.75 C14.6233526,10.75 16.75,12.8766474 16.75,15.5 C16.75,17.780311 15.1431684,19.6853265 13,20.1445516 L13,21.75 C13,22.4403559 12.4403559,23 11.75,23 C11.0596441,23 10.5,22.4403559 10.5,21.75 L10.5,20.0076265 C9.21079523,19.5787209 8.14800643,18.6087278 7.61342051,17.3245825 C7.3480997,16.6872473 7.64967698,15.9555 8.28701221,15.6901792 C8.92434744,15.4248584 9.65609473,15.7264357 9.92141553,16.3637709 C10.2679883,17.196284 11.0827078,17.75 12,17.75 C13.2426407,17.75 14.25,16.7426407 14.25,15.5 C14.25,14.2573593 13.2426407,13.25 12,13.25 C11.654822,13.25 11.342322,13.110089 11.1161165,12.8838835 C11.342322,13.110089 11.654822,13.25 12,13.25 C12.345178,13.25 12.657678,13.110089 12.8838835,12.8838835 C12.657678,13.110089 12.345178,13.25 12,13.25 C9.37664744,13.25 7.25,11.1233526 7.25,8.5 C7.25,6.40081121 8.6117117,4.61966724 10.5,3.99170319 L10.5,2.25 C10.5,1.55964406 11.0596441,1 11.75,1 C12.4403559,1 13,1.55964406 13,2.25 L13,3.85593044 C14.6285534,4.2058411 15.9745676,5.3945597 16.5084745,7.00160936 C16.7261324,7.65675542 16.3714782,8.36430282 15.7163321,8.58196072 C15.0611861,8.79961862 14.3536387,8.44496439 14.1359808,7.78981833 C13.8330998,6.87815253 12.9766849,6.25 12,6.25 C10.7573593,6.25 9.75,7.25735931 9.75,8.5 C9.75,9.74264069 10.7573593,10.75 12,10.75 C11.3096441,10.75 10.75,11.3096441 10.75,12 C10.75,11.3096441 11.3096441,10.75 12,10.75 C12.6903559,10.75 13.25,11.3096441 13.25,12 Z'
    />
  </Icon>
);
