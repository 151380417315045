import { filter, find, map, pipe } from '../../../../helpers';
import { isFunction } from 'lodash-es';
import { debounce } from 'lodash-es';
import type { ReactElement } from 'react';
import { Children, useCallback, useEffect, useRef } from 'react';
import { KeyNames } from '../../../../constants/key-names';
import type { ListboxChildren, ListboxOptionData } from '../../../listbox';

export type DropdownOptionData = ListboxOptionData & { searchValue?: string };

type Props = {
  children: ListboxChildren;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  onMatch: (match: DropdownOptionData) => void;
};

const searchChildren = (children: ReactElement[], searchStr: string): DropdownOptionData | undefined =>
  pipe(
    map(({ props }: ReactElement, index: number) => ({ ...props, index })),
    filter(({ disabled }) => !disabled),
    find(({ searchValue, value }: DropdownOptionData) => (searchValue ?? value)?.toLowerCase().includes(searchStr))
  )(children);

/**
 * Hook for handling searches against dropdown child components,
 * useful for searching while focused on the field or the menu (like a standard HTML select.)
 * @param {function[]} config.children The dropdown children (DropdownField.Option[])
 * @param {function} [config.onKeyDown] If another keydown handler is needed for the same element, this will process that first before moving to the search.
 */
export function useDropdownSearch({ children, onKeyDown, onMatch }: Props) {
  const searchValueRef = useRef('');
  const searchResetTimerRef = useRef<number>();
  const resetSearch = useCallback(
    debounce(
      () => {
        searchValueRef.current = '';
      },
      1000,
      { trailing: true }
    ),
    []
  );

  // always clean up stray timers
  useEffect(() => {
    return () => {
      window.clearTimeout(searchResetTimerRef.current);
    };
  }, []);

  const searchOnKeyDown = (e: React.KeyboardEvent) => {
    // pass it through the triggerprops handling first
    e.persist();
    if (isFunction(onKeyDown)) onKeyDown?.(e);

    if (e.shiftKey || e.metaKey || e.key == KeyNames.Tab) return;
    const char = e.key.toLowerCase();

    if (/[a-z0-9]/i.test(char)) {
      e.preventDefault();
      e.stopPropagation();
      resetSearch.cancel();
      searchValueRef.current = searchValueRef.current + char;
      const match = searchChildren(Children.toArray(children) as ReactElement[], searchValueRef.current);
      if (match) onMatch(match);
      resetSearch();
    }
  };

  return searchOnKeyDown;
}
