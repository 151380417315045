/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const PlayIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M5.25324601,2.87543658 L19.437533,11.1358568 C19.9147861,11.4137919 20.0763653,12.0259929 19.7984302,12.503246 C19.7113725,12.6527362 19.5870233,12.7770854 19.437533,12.8641432 L5.25324601,21.1245634 C4.77599291,21.4024985 4.16379191,21.2409193 3.88585681,20.7636662 C3.79687826,20.6108777 3.75,20.4372295 3.75,20.2604202 L3.75,3.73957977 C3.75,3.18729502 4.19771525,2.73957977 4.75,2.73957977 C4.92680925,2.73957977 5.1004575,2.78645803 5.25324601,2.87543658 Z'
    />
  </Icon>
);
