import { useState } from 'react';
import { SwitchChipGroupOptional } from './switch-chip-group';

export type UseSwitchChipGroupArgs = {
  defaultValue?: string[];
  onChange?: (value: string[], previousValue: string[]) => void;
};

export const useSwitchChipGroup = ({ defaultValue, onChange }: UseSwitchChipGroupArgs = {}): Omit<
  SwitchChipGroupOptional,
  'children'
> => {
  const [value, setValue] = useState<string[]>(defaultValue ?? []);

  const handleSetValue: typeof setValue = (newVal) => {
    if (typeof newVal === 'function') {
      setValue((prev) => {
        const calculatedNewValue = newVal(prev);
        onChange?.(calculatedNewValue, prev);
        return calculatedNewValue;
      });
    } else {
      setValue((prev) => {
        onChange?.(newVal, prev);
        return newVal;
      });
    }
  };

  return {
    value,
    setValue: handleSetValue,
  };
};
