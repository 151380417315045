/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const NotificationIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M4.68301061,12.844203 L4.68301061,8.43491916 C4.68301061,4.32872669 8.0117373,1 12.1179298,1 C16.2241222,1 19.5528489,4.32872669 19.5528489,8.43491916 L19.5528489,12.844203 L21.065491,14.9284124 C21.7953837,15.9341025 21.5718057,17.3410703 20.5661156,18.070963 C20.1818836,18.3498244 19.7192889,18.5 19.244528,18.5 L4.99133153,18.5 C3.74869084,18.5 2.74133153,17.4926407 2.74133153,16.25 C2.74133153,15.7752391 2.89150712,15.3126445 3.17036853,14.9284124 L4.68301061,12.844203 Z M17.0528489,13.655797 L17.0528489,8.43491916 C17.0528489,5.70943856 14.8434104,3.5 12.1179298,3.5 C9.39244918,3.5 7.18301061,5.70943856 7.18301061,8.43491916 L7.18301061,13.655797 L5.48167477,16 L18.7541848,16 L17.0528489,13.655797 Z M7.95993713,20.9433752 C7.57699656,20.3689644 7.73221389,19.5928777 8.30662475,19.2099371 C8.88103562,18.8269966 9.65712229,18.9822139 10.0400629,19.5566248 C10.467314,20.1975015 11.0723111,20.5 12,20.5 C12.9276889,20.5 13.532686,20.1975015 13.9599371,19.5566248 C14.3428777,18.9822139 15.1189644,18.8269966 15.6933752,19.2099371 C16.2677861,19.5928777 16.4230034,20.3689644 16.0400629,20.9433752 C15.1339807,22.3024985 13.7389777,23 12,23 C10.2610223,23 8.86601932,22.3024985 7.95993713,20.9433752 Z'
    />
  </Icon>
);
