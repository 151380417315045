import { FC, useState } from 'react';
import { css } from '@emotion/react';
import { useThemeValues } from '../../../../hooks/use-theme-values';
import { styles } from '../../../../styles';
import { Text } from '../../../text';
import { BasicFormFieldProps } from '../../layouts/types';
import { TimePickerInterval } from '../../shared/types';
import { TimeInterval } from '../time-field';
import { TimeField } from '../time-field/time-field';
import { isValidTimeRange, getAdjustedTime, daysDifference, AddOrSubtract, MathMethods } from '../time-field/utils';

type TimeRangeFieldProps = BasicFormFieldProps<'timeRange'> & {
  interval: TimePickerInterval;
  startLabel?: string;
  endLabel?: string;
  joiningText?: string;
  options?: TimeInterval[];
};

type TimeRangeChangeEventType = Parameters<Parameters<typeof TimeRangeField>[0]['onChange']>[0];

type SmartUpdateOppositeFieldProps = {
  changeHandler: (changeEvent: TimeRangeChangeEventType) => void;
  timeValue: string; // hh:mm:ss
  method: AddOrSubtract;
  minRangeDuration: number;
  name: string;
  setError: (errorMessage: string) => void;
};

/**
 * `smartUpdateOppositeField` forces the user into a valid time range entry.
 * This function assumes the user's most recent entry is correct and adjusts the opposite field accordingly
 *
 * @param changeHandler the changeHandler of the field that is now responsible for the invalid time range entry
 * @param timeValue the time value that the updated field needs to be based on
 * @param method 'add' | 'subtract'
 * @param minRangeDuration duration in minutes of the newly adjusted time range
 * @param name the `name` field from the event handler
 * @param setError let the user know that their input is bad and help them know how to correct it
 */
const smartUpdateOppositeField = ({
  changeHandler,
  method,
  minRangeDuration,
  name,
  setError,
  timeValue,
}: SmartUpdateOppositeFieldProps) => {
  const daysDiff = daysDifference({ method, timeInterval: minRangeDuration, timeValue });
  const adjustedTime = getAdjustedTime({
    method,
    timeInterval: minRangeDuration,
    timeValue,
  });
  // if one of the time conversions failed
  if (daysDiff === 'timeValue must be hh:mm:ss') {
    console.error(daysDiff);
    setError("We couldn't convert the time value. Please try again.");
  }
  // stop end time from being added past 24:00:00 to prevent endless loops
  // check to see if the adjusted time range goes into the next day
  if (typeof daysDiff === 'number' && daysDiff > 0) {
    changeHandler({
      name,
      value: getAdjustedTime({ timeValue, method: MathMethods.Add, timeInterval: 0 }),
    });
    setError('Please select an earlier start time.');

    // check to see if the adjusted time range goes into the previous day
  } else if (typeof daysDiff === 'number' && daysDiff < 0) {
    changeHandler({
      name,
      value: { value: '00:00:00', displayValue: '12:00am' },
    });
    setError('Please select a later end time.');

    // the user's input is bad, but the adjusted time range is within a 24hr range (aka 00:00:00 - 24:00:00)
    // correct the user's input
  } else {
    changeHandler({
      name,
      value: adjustedTime,
    });
    setError('');
  }
};

export const TimeRangeField: FC<React.PropsWithChildren<TimeRangeFieldProps>> = ({
  active,
  disabled = false,
  displayValue,
  endLabel = 'End Time',
  interval = 60,
  label,
  minRangeDuration = 60,
  minTime,
  maxTime,
  options,
  name,
  onBlur,
  onChange,
  onFocus,
  startLabel = 'Start Time',
  value,
  joiningText,
  ...rest
}: TimeRangeFieldProps) => {
  const [startActive, setStartActive] = useState(active);
  const [startTouched, setStartTouched] = useState(active);
  const [endActive, setEndActive] = useState(false);
  const [endTouched, setEndTouched] = useState(false);
  const [error, setError] = useState('');
  const { spacing } = useThemeValues();

  const startProps = {
    'aria-invalid': error !== '',
    active: startActive,
    displayValue: displayValue[0] ?? '',
    error: '',
    id: `${name}-start`,
    interval,
    label: startLabel,
    maxTime,
    minTime,
    options,
    name: `${name}-start`,
    touched: startTouched,
    value: value[0] ?? '',
  };

  const endProps = {
    'aria-invalid': error !== '',
    active: endActive,
    displayValue: displayValue[1] ?? '',
    error: '',
    id: `${name}-end`,
    interval,
    label: endLabel,
    maxTime,
    minTime,
    options,
    name: `${name}-end`,
    touched: endTouched,
    value: value[1] ?? '',
  };

  const startHandlers = {
    onChange: (event) => {
      onChange({
        name,
        value: {
          position: 0,
          value: event.target?.value ?? event.value,
        },
      });
      setError('');
      // check to make sure the user clicked from the dropdown and did not enter a typed value
      if (!event.target && !!event.value.value) {
        // check to see if user's input creates an invalid time range
        if (!isValidTimeRange(event.value.value, value[1])) {
          // assume the user's entry is correct and update the opposite field accordingly
          smartUpdateOppositeField({
            changeHandler: endHandlers.onChange,
            timeValue: event.value.value,
            method: MathMethods.Add,
            minRangeDuration,
            name,
            setError,
          });
        }
      }
    },
    onBlur: (event) => {
      // check to see if user's input creates an invalid time range
      if (!isValidTimeRange(value[0], value[1])) {
        // assume the user's entry is correct and update the opposite field accordingly
        smartUpdateOppositeField({
          changeHandler: endHandlers.onChange,
          timeValue: value[0]!,
          method: MathMethods.Add,
          minRangeDuration,
          name,
          setError,
        });
        setError('');
      }
      onBlur(event);
      setStartActive(false);
    },
    onFocus: (event) => {
      onFocus(event);
      setStartActive(true);
      setStartTouched(true);
    },
  };

  const endHandlers = {
    onChange: (event) => {
      onChange({
        name,
        value: {
          position: 1,
          value: event.target?.value ?? event.value,
        },
      });
      setError('');
      if (!event.target && !!event.value.value) {
        if (!isValidTimeRange(value[0], event.value.value)) {
          smartUpdateOppositeField({
            changeHandler: startHandlers.onChange,
            timeValue: event.value.value,
            method: MathMethods.Subtract,
            minRangeDuration,
            name,
            setError,
          });
        }
      }
    },
    onBlur: (event) => {
      if (!isValidTimeRange(value[0], value[1])) {
        smartUpdateOppositeField({
          changeHandler: startHandlers.onChange,
          timeValue: value[1]!,
          method: MathMethods.Subtract,
          minRangeDuration,
          name,
          setError,
        });
        setError('');
      }
      onBlur(event);
      setEndActive(false);
    },
    onFocus: (event) => {
      onFocus(event);
      setEndActive(true);
      setEndTouched(true);
    },
  };

  const { touched, ...others } = rest;

  return (
    <>
      <Text as='label' id='time-range-field__label' className='time-range-field__label' css={styles.visuallyHidden}>
        {label}
      </Text>
      <div css={css({ display: 'flex', alignItems: 'center' })} {...others}>
        <TimeField
          className='start-time'
          {...startProps}
          {...startHandlers}
          css={css({ width: 130, marginRight: spacing(1) })}
          disabled={disabled}
        />
        {joiningText ? (
          <Text as='span' size='large'>
            {joiningText}
          </Text>
        ) : (
          <Text as='span' size='large'>
            &mdash;
          </Text>
        )}
        <TimeField
          className='end-time'
          {...endProps}
          {...endHandlers}
          css={css({ width: 130, marginLeft: spacing(1) })}
          disabled={disabled}
        />
      </div>
      {error && (
        <Text size='small' color='error' css={css({ margin: spacing(1) })}>
          {error}
        </Text>
      )}
    </>
  );
};
