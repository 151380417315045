import { KeyNames } from '../../constants';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { EditIcon, ListsIcon, MoreIcon, TrashIcon } from '../../icon';
import React, { useState } from 'react';
import { ButtonBar, PrimaryButton, SecondaryButton } from '../buttons';
import { IconButton } from '../icon-button';
import { Text } from '../text';
import { PopoverMenu, PopoverMenuItem, usePopoverMenu } from '../popover';
import { useFormField } from '../forms';
import { styles } from '../../styles';

interface NoteProps {
  author: string;
  content?: string;
  dateCreated: string;
  editMode?: boolean;
  fallbackText?: string;
  onCancel?: () => void;
  onDelete?: () => void;
  onUpdate: (newVal: string) => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  moreButtonTrackingId?: string;
  formSubmitTrackingId?: string;
}

export const Note = ({
  author,
  content,
  dateCreated,
  editMode = false,
  fallbackText = 'Edit this note to add some text',
  onCancel,
  onDelete,
  onUpdate,
  primaryButtonText = 'Save',
  secondaryButtonText = 'Cancel',
  moreButtonTrackingId,
  formSubmitTrackingId,
}: NoteProps) => {
  const [edit, setEdit] = useState(editMode);
  const popoverMenuProps = usePopoverMenu();
  const formProps = useFormField({ type: 'text', value: content });

  const handleSubmit = () => {
    onUpdate(formProps.value);
    setEdit(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === KeyNames.Escape) {
      setEdit(false);
      e.stopPropagation();
    }
    if (!e.shiftKey && e.key === KeyNames.Enter) {
      handleSubmit();
    }
  };

  return (
    <article
      css={css`
        border-bottom: 1px solid ${theme.colors.neutral20};
        padding: ${theme.spacing(2, 1)};
      `}
    >
      <div css={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <Text size='large' weight='semibold' css={{ alignItems: 'center', display: 'flex', margin: 0 }}>
          <ListsIcon css={{ marginRight: theme.spacing(1) }} />
          {author}
        </Text>
        <Text size='medium' css={{ margin: 0 }}>
          <time dateTime={dateCreated}>{dateCreated}</time>
        </Text>
      </div>
      {edit ? (
        <form onSubmit={handleSubmit} data-trackingid={formSubmitTrackingId}>
          <textarea
            autoFocus
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              formProps.onChange(e);
            }}
            onKeyDown={handleKeyDown}
            value={formProps.value}
            css={css`
              border: none;
              font-size: ${theme.fontSize(16)};
              margin: ${theme.spacing(1)};
              outline: none;
              resize: none;
              width: 100%;

              &::placeholder {
                color: ${theme.colors.neutral70};
              }
            `}
          />
          <ButtonBar css={{ padding: 0 }}>
            <SecondaryButton
              css={{ width: 'auto' }}
              onClick={() => {
                onCancel?.();
                setEdit(false);
              }}
            >
              {secondaryButtonText}
            </SecondaryButton>
            <PrimaryButton css={{ width: 'auto' }} disabled={formProps.value.length < 1} type='submit'>
              {primaryButtonText}
            </PrimaryButton>
          </ButtonBar>
        </form>
      ) : (
        <span
          css={css`
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin: 0;
          `}
        >
          <Text
            css={[
              css`
                padding: ${theme.spacing(0.5)};
              `,
              styles.multilineTruncate(3),
            ]}
            color='light'
          >
            {content ? content : fallbackText}
          </Text>
          <IconButton
            {...popoverMenuProps.getTriggerProps()}
            label='more'
            css={{ marginLeft: theme.spacing(1) }}
            trackingId={moreButtonTrackingId}
          >
            <MoreIcon />
          </IconButton>
        </span>
      )}
      <PopoverMenu {...popoverMenuProps.getMenuProps()} title=''>
        <PopoverMenuItem
          {...popoverMenuProps.getItemProps({ index: 0 })}
          onClick={() => {
            popoverMenuProps.close();
            setEdit(true);
          }}
          Icon={EditIcon}
        >
          Edit
        </PopoverMenuItem>
        <PopoverMenuItem
          {...popoverMenuProps.getItemProps({ index: 1 })}
          onClick={() => {
            onDelete?.();
            popoverMenuProps.close();
          }}
          Icon={TrashIcon}
        >
          Delete
        </PopoverMenuItem>
      </PopoverMenu>
    </article>
  );
};
