import { css } from '@emotion/react';
import React, { Children } from 'react';
import type { BasicFormFieldProps } from '../../layouts';
import { RadioFieldLayout } from '../../layouts';
import { OptionSwitchOption } from './option-switch-option.component';

type Props = Omit<BasicFormFieldProps<'radio'>, 'label'> & {
  children: Array<React.ReactElement | null>;
  label?: string;
};

export const OptionSwitchField = ({ children, ...rest }: Props) => {
  const childCount = Children.toArray(children).length;
  return (
    <RadioFieldLayout {...rest}>
      <div
        css={css`
          height: 40px;
          display: flex;

          button {
            flex-basis: ${100 / childCount}%;
          }

          button[aria-checked='true'] + button {
            border-left: 0;
          }
        `}
      >
        {children}
      </div>
    </RadioFieldLayout>
  );
};

OptionSwitchField.Option = OptionSwitchOption;
