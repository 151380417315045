import { ReactNode } from 'react';
import { BackIcon } from '../../icon';
import { Text } from '../';
import { useStyles } from '../../use-styles';
import { FilePreviewImage } from './file-preview-image.component';
import { AcceptedFileType, OnFileUpload, useFileUpload } from './use-file-upload';

export type FileUploadProps = {
  // This method should simply set state for your component to retrieve the file list to be submitted
  onFileUpload?: OnFileUpload;
  helperText?: ReactNode;
  acceptedFileType?: AcceptedFileType;
  // max allowed file size in bytes
  maxFileSize?: number;
  // option to upload multiple files
  multiple?: boolean;
  // keeps the previously selected files in the array
  keepPreviouslySelected?: boolean;
  // optional validation check, returns an error message
  validateUpload?: (file: File) => Promise<string>;
  onFileProcess?: (file: File) => Promise<File>;
  trackingId?: string;
};

export const FileUpload = ({
  onFileUpload,
  acceptedFileType = 'any',
  helperText = 'Upload files or drag and drop them here.',
  maxFileSize = 52428800,
  multiple = false,
  keepPreviouslySelected = true,
  validateUpload,
  trackingId,
  onFileProcess,
  ...rest
}: FileUploadProps) => {
  const { validFiles, isDragging, error, inputProps, dropContainerProps } = useFileUpload({
    acceptedFileType,
    onFileUpload,
    maxFileSize,
    keepPreviouslySelected,
    validateUpload,
    onFileProcess,
  });

  const dropContainer = useStyles('FileUpload', 'container');
  const dropBox = useStyles('FileUpload', 'box', { isDragging });
  const buttonContainer = useStyles('FileUpload', 'button');
  const imagePreviewContainer = useStyles('FileUpload', 'imagePreviewContainer');
  const imagePreview = useStyles('FileUpload', 'imagePreview');
  const imagePreviewText = useStyles('FileUpload', 'imagePreviewText');
  const errorText = useStyles('FileUpload', 'errorText');
  const iconTranslate = useStyles('FileUpload', 'iconTranslate');

  return (
    <div css={dropContainer} data-trackingid={trackingId} {...rest}>
      <div id='drop-area' {...dropContainerProps} css={dropBox}>
        <BackIcon color='light' css={iconTranslate} />
        {typeof helperText === 'string' ? <Text>{helperText}</Text> : helperText}
        <label htmlFor='file-drop' css={buttonContainer}>
          Choose Files
        </label>
        <input type='file' id='file-drop' multiple={multiple} hidden {...inputProps} />
      </div>
      {validFiles.length > 0 && (
        <div className='image-preview-container' css={imagePreviewContainer}>
          {validFiles.map((file: File) => (
            <div key={file.name} css={imagePreview}>
              <FilePreviewImage file={file} />
              <Text css={imagePreviewText}>{file.name}</Text>
            </div>
          ))}
        </div>
      )}

      {!!error && <Text css={errorText}>{error}</Text>}
    </div>
  );
};
