/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const PlusIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M13.25,10.75 L20,10.75 C20.6903559,10.75 21.25,11.3096441 21.25,12 C21.25,12.6903559 20.6903559,13.25 20,13.25 L13.25,13.25 L13.25,20 C13.25,20.6903559 12.6903559,21.25 12,21.25 C11.3096441,21.25 10.75,20.6903559 10.75,20 L10.75,13.25 L4,13.25 C3.30964406,13.25 2.75,12.6903559 2.75,12 C2.75,11.3096441 3.30964406,10.75 4,10.75 L10.75,10.75 L10.75,4 C10.75,3.30964406 11.3096441,2.75 12,2.75 C12.6903559,2.75 13.25,3.30964406 13.25,4 L13.25,10.75 Z'
    />
  </Icon>
);
