import { Icon, IconProps } from '../Icon.component';

export const DocumentUndeterminedIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M8.48987 1.9873H13.0148C14.2851 1.9873 15.5078 2.47081 16.4345 3.33962L18.9299 5.67905C19.9381 6.62428 20.5102 7.94469 20.5102 9.32673V17.0127C20.5102 19.7741 18.2716 22.0127 15.5102 22.0127H8.48987C5.72844 22.0127 3.48987 19.7741 3.48987 17.0127V6.98731C3.48987 4.22588 5.72844 1.9873 8.48987 1.9873ZM8.48987 4.4873C7.10916 4.4873 5.98987 5.60659 5.98987 6.98731V17.0127C5.98987 18.3934 7.10915 19.5127 8.48987 19.5127H15.5102C16.8909 19.5127 18.0102 18.3934 18.0102 17.0127V9.32673C18.0102 8.63571 17.7242 7.97551 17.22 7.50289L14.7246 5.16346C14.2613 4.72906 13.6499 4.4873 13.0148 4.4873H8.48987Z'
    />
    <path
      fillRule='evenodd'
      d='M12.5986 16.1542C12.6531 16.8401 12.1413 17.4405 11.4553 17.495C10.7693 17.5496 10.169 17.0377 10.1144 16.3518C10.0599 15.6658 10.5717 15.0655 11.2577 15.0109C11.9437 14.9563 12.544 15.4682 12.5986 16.1542Z'
    />
    <path
      fillRule='evenodd'
      d='M9.23123 7.93128C9.8374 6.83968 11.0916 6.2795 12.3091 6.55656L12.1095 7.43352L12.3091 6.55656L12.3652 6.56933C13.1764 6.75394 13.8566 7.30344 14.2075 8.05774C14.744 9.21076 14.3999 10.5819 13.3825 11.345L12.9377 11.6787L12.3376 10.8787L12.9377 11.6787C12.5912 11.9386 12.3872 12.3465 12.3872 12.7796L12.3872 12.8376C12.3872 13.3898 11.9395 13.8376 11.3872 13.8376C10.835 13.8376 10.3872 13.3898 10.3872 12.8376L10.3872 12.7796L11.3872 12.7796H10.3872C10.3872 11.717 10.8875 10.7163 11.7376 10.0787L12.1825 9.74506C12.4435 9.54923 12.5319 9.19734 12.3942 8.90144C12.3041 8.70787 12.1296 8.56685 11.9214 8.51947L11.8653 8.5067C11.515 8.42699 11.1541 8.58816 10.9797 8.90223L10.9797 8.90223L10.9658 8.92732C10.6977 9.41015 10.0889 9.58421 9.60608 9.31609C9.12324 9.04797 8.94918 8.4392 9.2173 7.95637L9.23123 7.93128L9.23123 7.93128Z'
    />
  </Icon>
);
