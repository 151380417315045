import { ListboxOption } from '../../../listbox';
import { DropdownProvider } from '../../providers';
import { MultiselectBaseField, MultiselectFieldProps } from './multiselect-base-field';

export const MultiselectField = ({ children, autoClose = false, ...rest }: MultiselectFieldProps) => (
  <DropdownProvider
    id={rest.id}
    onBlur={rest.onBlur}
    onFocus={rest.onFocus}
    onChange={rest.onChange}
    value={rest.value}
    name={rest.name}
  >
    <MultiselectBaseField {...rest} autoClose={autoClose}>
      {children}
    </MultiselectBaseField>
  </DropdownProvider>
);

MultiselectField.Option = ListboxOption;
