import { css } from '@emotion/react';
import { useThemeValues } from '../../hooks';
import { ButtonBar, PrimaryButton } from '../buttons';
import { Listbox, SearchableListbox, useListboxState } from '../listbox';
import type { ListboxChildren } from '../listbox/listbox.provider';
import { ModalHeader } from './atoms';
import type { CenteredModalProps } from './molecules';
import { CenteredModal } from './molecules';
import type { ModalControlModalProps } from './provider';

type ValueType = string | string[];
type SelectFn<T extends ValueType> = React.Dispatch<React.SetStateAction<T>> | ((value: T) => void);

type Props<T extends ValueType> = Pick<ModalControlModalProps, 'onClose'> & {
  children: ListboxChildren;
  onSelect: SelectFn<T>;
  searchable?: boolean;
  titleAlignment?: 'center' | 'left';
  title: string;
  trackingId?: string;
  value: T;
};

const SelectionModalContent = <T extends ValueType>({
  children,
  onClose,
  onSelect,
  searchable,
  titleAlignment = 'center',
  title,
  trackingId,
  value,
}: Props<T>) => {
  const listboxProps = useListboxState(value);
  const ListboxComponent = searchable ? SearchableListbox : Listbox;
  const multiselect = Array.isArray(value);
  const onSingleSelect = (value: string | string[]) => {
    onSelect(value as T);
    onClose();
  };
  const theme = useThemeValues();
  return (
    <>
      <ModalHeader
        css={css`
          margin-right: ${theme.spacing(3)};
          margin-left: ${theme.spacing(3)};
        `}
        id={listboxProps.labelId}
        textAlign={titleAlignment}
      >
        {title}
      </ModalHeader>
      <ListboxComponent
        {...listboxProps}
        css={[
          css`
            overflow-y: auto;
            padding-top: ${theme.spacing(1)};

            > div:first-of-type,
            li {
              padding-bottom: ${theme.spacing(1)};
              padding-right: ${theme.spacing(3)};
              padding-left: ${theme.spacing(3)};
            }
          `,
          !multiselect &&
            css`
              margin-bottom: 0;

              ul {
                margin-bottom: 0;
              }
            `,
          !searchable &&
            css`
              margin-top: 0;
            `,
        ]}
        onSelect={multiselect ? listboxProps.onSelect : onSingleSelect}
      >
        {children ?? null}
      </ListboxComponent>
      {multiselect && (
        <ButtonBar
          css={css`
            padding: ${theme.spacing(0, 3)};
          `}
        >
          <PrimaryButton
            onClick={() => {
              onSelect(listboxProps.value as T);
              onClose();
            }}
            trackingId={trackingId}
          >
            Save
          </PrimaryButton>
        </ButtonBar>
      )}
    </>
  );
};

export type SelectionModalProps<T extends ValueType> = Pick<
  CenteredModalProps,
  'disableCloseOnEscape' | 'disableCloseOnOverlayClick' | 'maxWidth' | 'minWidth' | 'mountTarget' | 'prepend' | 'show'
> &
  Props<T> & { height?: number };

const selectionModalClassname = 'wv-selection-modal';

export const SelectionModal = <T extends ValueType>({
  disableCloseOnEscape,
  disableCloseOnOverlayClick,
  minWidth = 0,
  maxWidth = 400,
  mountTarget,
  onClose,
  prepend,
  show,
  height,
  ...rest
}: SelectionModalProps<T>) => {
  const theme = useThemeValues();

  return (
    <CenteredModal
      show={show}
      onClose={onClose}
      className={selectionModalClassname}
      minWidth={minWidth}
      maxWidth={maxWidth}
      disableCloseOnEscape={disableCloseOnEscape}
      disableCloseOnOverlayClick={disableCloseOnOverlayClick}
      mountTarget={mountTarget}
      prepend={prepend}
      flex
      css={[
        css`
          padding: ${theme.spacing(3, 0)};
        `,
        !!height &&
          css`
            height: ${height}px;
          `,
      ]}
    >
      <SelectionModalContent onClose={onClose} {...rest} />
    </CenteredModal>
  );
};

SelectionModal.Option = Listbox.Option;
