import {
  Children,
  ComponentProps,
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';
import { css } from '@emotion/react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  offset,
  size,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useListItem,
  useListNavigation,
  useRole,
} from '@floating-ui/react';
import { onlyText } from 'react-children-utilities';
import { Icon } from '@frontend/icons';
import type { WeaveTheme as OriginalTheme } from '@frontend/theme-original';
import type { WeaveTheme } from '@frontend/theme';
import { useThemeValues } from '../../../../hooks/use-theme-values';
import { Chip } from '../../../chip';
import { TagChipStylesProps } from '../../../chip/chip.styles';
import { Checkbox } from '../../atoms';
import { FieldLayout, FieldLayoutWithAction } from '../../layouts';
import { TagInput, TagType } from '../chip-field/chip-field';
import { Menu } from './atoms';
import { CheckListMenuProps, SelectContext } from './checklist-menu.component';
import { MAX_FLOATING_HEIGHT, MAX_FLOATING_WIDTH } from './const';

export const ChecklistTagMenuField = ({
  children,
  maxAllowed,
  placeholder = 'Select one',
  placement,
  isClearable,
  isRemovable,
  ...rest
}: CheckListMenuProps & {
  isRemovable?: boolean;
  isClearable?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const labelsRef = useRef<Array<string | null>>([]);
  const listItemsRef = useRef<Array<HTMLLIElement | null>>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const theme = useThemeValues();

  const nodeId = useFloatingNodeId();

  const { refs, context, floatingStyles } = useFloating<HTMLInputElement>({
    nodeId,
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: (nextOpen) => {
      setIsOpen(nextOpen);
      if (!nextOpen) {
        setActiveIndex(null);
      }
    },
    middleware: [
      offset(placement === 'top' ? 12 : 3),
      flip(),
      size({
        apply({ elements, rects }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
    placement,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'listbox' }),
    useDismiss(context, {
      outsidePress: (event) => {
        /**
         * Prevent closing when clicking on the svg icon.
         * This means that clicking on the svg icon is not considered an outside press,
         * preventing the dropdown from closing and opening again in quick succession
         **/
        return !(event?.target as HTMLElement)?.closest('svg');
      },
    }),
    useListNavigation(context, {
      activeIndex,
      listRef: listItemsRef,
      loop: true,
      onNavigate: setActiveIndex,
      openOnArrowKeyDown: true,
      selectedIndex,
      /**
       * Using virtual means that focus will stay on the floating element (Menu) even as the user navigates the list - the focus never truly enters the list items.
       * This will change the user experience with keyboard controls like Enter and Space.
       */
      // virtual: true,
    }),
  ]);

  const handleSelect = useCallback(
    (index: number | null) => {
      setSelectedIndex(index);
      if (index !== null) {
        /**
         * Check for item label before selecting it.
         * This will be a problem if multiple items have the same label.
         */
        const item = Children.toArray(children).find(
          (child) => isValidElement(child) && onlyText(child.props?.children) === labelsRef.current[index]
        );

        if (item && isValidElement(item) && !item.props.disabled) {
          rest.onChange({ name: rest.name, value: item.props?.value });
          if (tags.map((t) => t.value).includes(item.props?.value)) {
            setTags(tags.filter((tag) => tag.value !== item.props?.value));
          } else {
            setTags([
              ...tags,
              { label: onlyText(item.props?.children), color: item.props?.color, value: item.props?.value },
            ]);
          }
        }
      }
    },
    [children, rest.onChange, rest.name]
  );

  const clearSelection = () => {
    rest.onChange({ name: rest.name, value: [] });
    setTags([]);
  };

  const selectAll = useCallback(
    (filterFn: (child: ReactElement) => boolean) => {
      const items = Children.toArray(children).filter(
        (child) => isValidElement(child) && !child.props.disabled && filterFn(child)
        // Making an BIG assumption here - expect users to play by the rules
      ) as ReactElement[];

      /**
       * Because the multiselect field has special `onChange` logic, this trick allows us to set
       * exactly what we want as the value of the field.
       */
      rest.onChange({ name: rest.name, value: [] });
      rest.onChange({ name: rest.name, value: items });
      setTags(
        items.map((item) => ({
          label: onlyText(item.props.children),
          color: item.props.color,
          value: item.props.value,
        }))
      );
    },
    [children]
  );

  const selectContext = useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      selectedItems: rest.value,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, getItemProps, rest.value, selectedIndex, handleSelect]
  );
  const refProps = getReferenceProps({
    onClick: () => {
      /**
       * A little bit counterintuitive, but we're doing this to mark the TagInput as "touched", so that
       * the validation can kick in.
       */
      rest.onBlur();
    },
  });

  const [tags, setTags] = useState<TagType[]>(
    /**
     * We derive the initial tags using a combination of the children (which has all the data)
     * and the passed in value prop (which is a string array of values attached to each option).
     */
    Children.toArray(children).reduce((acc, child) => {
      if (isValidElement(child) && rest.value.includes(child.props?.value)) {
        return [
          ...acc,
          {
            label: onlyText(child.props?.children),
            value: child.props?.value,
            color: child.props?.color,
          },
        ];
      }
      return acc;
    }, [] as TagType[])
  );

  return (
    <FieldLayout
      /**
       * The types don't know that the fieldComponentProps are being passed to component here
       * We'll cast it for now until the types are fixed
       */
      field={TagInput as unknown as ComponentProps<typeof FieldLayoutWithAction>['field']}
      fieldComponentProps={{
        ref: refs.setReference,
        tags: tags,
        setTags,
        onChange: rest.onChange,
        isRemovable,
        isClearable,
      }}
      css={{
        display: 'grid',
        gap: theme.spacing(2),
        alignItems: 'center',
        gridTemplateColumns: '1fr auto',
        padding: theme.spacing(1, 1),
        cursor: 'pointer',
        minHeight: 40,
        height: 'auto',
      }}
      className='checklist-tag-menu-field'
      containerCss={css({
        ':focus': {
          outline: 'none',
        },
        ':focus .checklist-tag-menu-field': {
          outline: `1px solid ${(theme as WeaveTheme).colors.primary50 ?? (theme as OriginalTheme).colors.weaveBlue}`,
          borderColor: (theme as WeaveTheme).colors.primary50 ?? (theme as OriginalTheme).colors.weaveBlue,
        },
      })}
      endAdornment={
        /**
         * Because of how the FieldLayout works (and the grid layout), the endAdornment doesn't get any pointer events.
         * Adding an onClick here to toggle the dropdown when the icon is clicked.
         */
        <Icon
          onClick={() => {
            setIsOpen((prev) => !prev);
          }}
          name='caret-down'
          size={16}
          css={[
            css`
              cursor: pointer;
              transition: transform 250ms ease-out;
            `,

            isOpen &&
              css`
                transform: rotate(180deg);
              `,
          ]}
        />
      }
      placeholder={placeholder}
      {...refProps}
      {...rest}
      outerProps={{
        tabIndex: 0,
        onKeyDown: (e) => {
          /**
           * Make sure to only do this if it's not an li element in the menu
           */
          if ((e.target as HTMLElement).tagName !== 'LI' && (e.key === 'Enter' || e.key === ' ')) {
            setIsOpen((prev) => !prev);
          }
        },
      }}
      hasPadding={false}
      active={activeIndex !== null || rest.active}
      onChange={() => {}}
    >
      <FloatingNode id={nodeId}>
        <SelectContext.Provider value={selectContext}>
          {isOpen && (
            <FloatingPortal>
              <FloatingFocusManager context={context} initialFocus={0}>
                <div
                  style={{
                    ...floatingStyles,
                    boxShadow: theme.shadows.heavy,
                    borderRadius: theme.borderRadius.medium,
                    backgroundColor: theme.colors.white,
                    zIndex: theme.zIndex.popover,
                    overflow: 'hidden',
                    display: 'grid',
                    gridTemplateRows: 'minmax(0, 1fr)',
                    maxHeight: MAX_FLOATING_HEIGHT,
                    maxWidth: MAX_FLOATING_WIDTH,
                  }}
                  ref={refs.setFloating}
                >
                  <Menu
                    floatingProps={getFloatingProps({
                      onKeyDown: (e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          handleSelect(activeIndex);
                        }
                      },
                    })}
                    ref={refs.setFloating}
                    refs={{ listItemRefs: listItemsRef, labelsRef }}
                    clearSelection={clearSelection}
                    selectAll={selectAll}
                  >
                    {children}
                  </Menu>
                </div>
              </FloatingFocusManager>
            </FloatingPortal>
          )}
        </SelectContext.Provider>
      </FloatingNode>
    </FieldLayout>
  );
};

const Option = ({
  children,
  color,
  value,
  disabled,
  trackingId,
}: {
  children: ReactNode;
  color: TagChipStylesProps['color'];
  value: string;
  disabled?: boolean;
  trackingId?: string;
}) => {
  const { activeIndex, selectedItems, getItemProps, handleSelect } = useContext(SelectContext);
  const theme = useThemeValues();
  const id = useId();

  const { ref, index } = useListItem();
  const isActive = activeIndex === index;
  const isSelected = selectedItems.includes(value);

  return (
    <li
      aria-disabled={disabled}
      ref={ref}
      id={id}
      role='option'
      aria-selected={isActive && isSelected}
      tabIndex={-1}
      css={{
        cursor: disabled ? 'not-allowed' : 'pointer',
        background: isActive ? (theme as WeaveTheme).colors.neutral5 ?? (theme as OriginalTheme).colors.gray200 : '',
        fontWeight: isSelected ? 'bold' : '',
        listStyle: 'none',
        display: 'flex',
        gap: theme.spacing(1),
        padding: theme.spacing(1, 2),
        outline: 'none',
        color: disabled
          ? theme.font.colors.disabled
          : (theme as WeaveTheme).colors.neutral90 ?? (theme as OriginalTheme).colors.gray600,
      }}
      {...getItemProps({
        onClick: () => {
          handleSelect(index);
        },
      })}
    >
      <Checkbox
        disabled={disabled}
        name='test'
        id={`checkbox-${id}`}
        aria-invalid={false}
        value={isSelected}
        onBlur={() => {}}
        onChange={() => {}}
        onFocus={() => {}}
        active={isActive}
        error={false}
        tabIndex={-1}
        trackingId={trackingId}
      />
      <Chip.Tag color={color}>{children}</Chip.Tag>
    </li>
  );
};

ChecklistTagMenuField.Option = Option;
