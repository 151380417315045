import { useMemo } from 'react';
import { FlyoutMenu } from './flyout-menu.component';
import { useFlyoutState } from './use-flyout-state';
import { FlyoutProps, FlyoutRefType, ToggleCallback } from './types';

type FlyoutRoles = 'dialog' | 'menu';

export type FlyoutTriggerProps<T extends HTMLElement> = {
  'aria-haspopup': FlyoutRoles;
  'aria-controls': string;
  'aria-expanded': boolean;
  id: string;
  onClick: () => void;
  ref: FlyoutRefType<T>;
};

type UseFlyoutMenuResponse<F extends HTMLElement, T extends HTMLElement> = {
  active: boolean;
  close: () => void;
  FlyoutMenu: typeof FlyoutMenu;
  flyoutProps: FlyoutProps<F>;
  open: () => void;
  toggle: (active: boolean) => void;
  triggerProps: FlyoutTriggerProps<T>;
};

/**
 *
 * @deprecated use usePopoverMenu instead
 */
export function useFlyoutMenu<F extends HTMLElement = HTMLUListElement, T extends HTMLElement = HTMLButtonElement>(
  onToggle?: ToggleCallback<F>
): UseFlyoutMenuResponse<F, T> {
  const { active, flyoutId, flyoutRef, setActive, triggerRef } = useFlyoutState<F, T>({
    onToggle,
  });

  return useMemo(() => {
    const controlId = `flyout-trigger-${flyoutId.current}`;
    const mainId = `flyout-${flyoutId.current}`;

    const open = () => {
      setActive(true);
    };

    const close = () => {
      setActive(false);
      setTimeout(() => {
        triggerRef.current?.focus();
      }, 0);
    };

    return {
      active,
      open,
      close,
      FlyoutMenu,
      flyoutProps: {
        'aria-labelledby': controlId,
        id: mainId,
        onClose: close,
        role: 'menu',
        ref: flyoutRef,
      },
      toggle: setActive,
      triggerProps: {
        id: controlId,
        'aria-haspopup': 'menu',
        'aria-controls': mainId,
        'aria-expanded': active,
        onClick: () => {
          setActive((visible) => !visible);
        },
        ref: triggerRef,
      },
    };
  }, [active]);
}
