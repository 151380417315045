/**
 * This component was generated from a script. DO NOT MODIFY THIS FILE or your
 * changes will be overridden the next time icon components are generated. to
 * make persistent changes, modify the icon-template.hbs file and re run the
 * "npm run generate-icons" script
 */

import { Icon, IconProps } from '../Icon.component';

export const ListsIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M12.25,4.25 L6.25,4.25 L6.25,19.75 L17.75,19.75 L17.75,9.75 L15.5,9.75 C13.7050746,9.75 12.25,8.29492544 12.25,6.5 L12.25,4.25 Z M14.75,4.52395872 L14.75,6.5 C14.75,6.91421356 15.0857864,7.25 15.5,7.25 L17.4497588,7.25 L14.75,4.52395872 Z M6,1.75 L14.1658921,1.75 C15.0333023,1.75 15.8647206,2.0967463 16.475094,2.71306172 L19.3092019,5.57475992 C19.9118978,6.1833232 20.25,7.00519853 20.25,7.86169821 L20.25,20 C20.25,21.2426407 19.2426407,22.25 18,22.25 L6,22.25 C4.75735931,22.25 3.75,21.2426407 3.75,20 L3.75,4 C3.75,2.75735931 4.75735931,1.75 6,1.75 Z M8.75,8 L10.75,8 C11.4403559,8 12,8.55964406 12,9.25 C12,9.94035594 11.4403559,10.5 10.75,10.5 L8.75,10.5 C8.05964406,10.5 7.5,9.94035594 7.5,9.25 C7.5,8.55964406 8.05964406,8 8.75,8 Z M8.75,11.5 L12.75,11.5 C13.4403559,11.5 14,12.0596441 14,12.75 C14,13.4403559 13.4403559,14 12.75,14 L8.75,14 C8.05964406,14 7.5,13.4403559 7.5,12.75 C7.5,12.0596441 8.05964406,11.5 8.75,11.5 Z M8.75,15 L15.25,15 C15.9403559,15 16.5,15.5596441 16.5,16.25 C16.5,16.9403559 15.9403559,17.5 15.25,17.5 L8.75,17.5 C8.05964406,17.5 7.5,16.9403559 7.5,16.25 C7.5,15.5596441 8.05964406,15 8.75,15 Z'
    />
  </Icon>
);
