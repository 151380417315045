import React, { useMemo, useRef, useState } from 'react';
import { fileTypeMap } from './file-types';

export type AcceptedFileType = keyof typeof fileTypeMap | (keyof typeof fileTypeMap)[];
export type OnFileUpload = (files: File[]) => void;

export interface FileUploadParams {
  acceptedFileType?: AcceptedFileType;
  maxFileSize?: number;
  onFileUpload?: OnFileUpload;
  keepPreviouslySelected?: boolean;
  disabled?: boolean;
  validateUpload?: (file: File) => Promise<string>;
  onFileProcess?: (file: File) => Promise<File>;
}

export const useFileUpload = ({
  acceptedFileType = 'any',
  maxFileSize = 52428800,
  onFileUpload,
  keepPreviouslySelected = true,
  disabled = false,
  validateUpload,
  onFileProcess,
}: FileUploadParams) => {
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validFiles, setValidFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { allowedFiles, accept } = useMemo(() => {
    const allowedFiles = Array.isArray(acceptedFileType)
      ? acceptedFileType.reduce((prev, current) => [...prev, ...fileTypeMap[current].split(',')], [] as string[])
      : fileTypeMap[acceptedFileType].split(',');
    const accept = allowedFiles.join(',');
    return { allowedFiles, accept };
  }, [acceptedFileType]);

  const getValidFiles = async (files: FileList) => {
    const finalValidFiles = keepPreviouslySelected ? [...validFiles] : [];

    const uploadedFiles = Array.from(files);
    const newValidFiles: File[] = [];
    if (!keepPreviouslySelected) setError(null);
    await Promise.all(
      uploadedFiles.map(async (file: File) => {
        const validType = allowedFiles.some((allowedType) => {
          return new RegExp(allowedType.replace('*', '.*')).test(file.type);
        });
        const newFile = onFileProcess ? await onFileProcess(file) : file;
        const validSize = newFile.size < maxFileSize;

        const invalidFileMessage = validateUpload ? await validateUpload(newFile) : '';
        const validFile = !!!invalidFileMessage;

        if (!validType) {
          setError(`File type is not accepted: ${newFile.name}`);
        } else if (!validSize) {
          setError(`File size is larger than accepted value: ${newFile.name}`);
        } else if (!validFile) {
          setError(invalidFileMessage);
        }
        if (validType && validSize && validFile) newValidFiles.push(newFile);
      })
    );

    finalValidFiles.push(...newValidFiles);
    setValidFiles(finalValidFiles);
    onFileUpload?.(finalValidFiles);
  };

  const handleOnDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (disabled) return setIsDragging(false);
    const { files } = event.dataTransfer;
    getValidFiles(files);
    setIsDragging(false);
  };

  const handleDragOver = (event: React.MouseEvent) => {
    setIsDragging(true);
    event.preventDefault();
  };

  const handleDragLeave = (event: React.MouseEvent) => {
    setIsDragging(false);
    event.preventDefault();
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (disabled) return;
    const { files } = event.target;
    if (files) {
      getValidFiles(files);
    }
  };

  const handleOnClick = () => fileInputRef.current?.click();

  const resetFiles = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setValidFiles([]);
  };

  return {
    validFiles: disabled ? [] : validFiles,
    isDragging,
    error,
    handleOnClick,
    resetFiles,
    dropContainerProps: {
      onDragOver: handleDragOver,
      onDrop: handleOnDrop,
      onDragLeave: handleDragLeave,
    },
    inputProps: {
      accept,
      onChange: handleUpload,
      onDrop: handleOnDrop,
      ref: fileInputRef,
    },
  };
};
