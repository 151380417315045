import React from 'react';
import { ColumnInstance, ensurePluginOrder, PluginHook, Row, TableInstance } from 'react-table';
import { FileTypes } from './../table-types';
import { TableData } from './../table-data-type';

export function getFirstDefined<T>(...args: T[]): T | undefined {
  for (const arg of args) {
    if (typeof arg !== 'undefined') {
      return arg;
    }
  }
  return;
}

const defaultExportFileName = ({ all }: { all: boolean }) => {
  return `${all ? 'all-' : ''}data`;
};

const defaultHeaderExportValue = <T extends TableData>(col: ColumnInstance<T>) => {
  let name = col.Header;
  if (typeof name === 'object' || typeof name === 'function') {
    name = col.id;
  }
  return name as string;
};

const defaultCellExportValue = <T extends TableData>(
  rowData: Row<T>['original'],
  row: Row<T>,
  col: ColumnInstance<T>
) => {
  if (typeof row.values[col.id] !== 'undefined' && row.values[col.id] !== null) {
    return row.values[col.id];
  }

  if (typeof col.accessor === 'string' && !col.accessor.includes('.')) {
    return rowData[col.accessor];
  }

  return '';
};

const defaultGetExportFileBlob = () => {
  throw new Error('React Table: Export Blob is mandatory');
};

export const useExportData: PluginHook<any> = (hooks) => {
  hooks.useInstance.push(useInstance);
};

useExportData.pluginName = 'useExportData';

function useInstance<T extends TableData>(instance: TableInstance<T>) {
  const {
    rows,
    initialRows = [],
    allColumns,
    isPaginated,
    page,
    disableExport,
    exportFileName = defaultExportFileName,
    getExportFileBlob = defaultGetExportFileBlob,
    plugins,
  } = instance;
  ensurePluginOrder(
    plugins,
    ['useColumnOrder', 'useColumnVisibility', 'useFilters', 'useSortBy', 'usePagination'],
    'useExportData'
  );

  allColumns.forEach((column) => {
    const { accessor, headerExportValue = defaultHeaderExportValue } = column;

    const canExport = accessor
      ? getFirstDefined(
          column.disableExport === true ? false : undefined,
          disableExport === true ? false : undefined,
          true
        )
      : false;

    column.canExport = canExport;
    column.exportValue = headerExportValue(column);
  });

  const exportData = React.useCallback<(fileType: FileTypes, all: boolean) => void>(
    (fileType: FileTypes, all = false) => {
      const exportableColumns = allColumns.filter((col) => col.canExport && (all || col.isVisible));

      if (exportableColumns.length === 0) {
        console.warn('No exportable columns are available');
        return;
      }

      const exportableRows = (all ? (initialRows as Row<T>[]) : isPaginated ? page : rows).map((row) => {
        return exportableColumns.map((col) => {
          const { cellExportValue = defaultCellExportValue } = col;
          return cellExportValue(row['original'], row, col);
        });
      }) as T[][];

      const fileName = exportFileName({ fileType, all });

      const fileBlob = getExportFileBlob({
        columns: exportableColumns,
        data: exportableRows,
        fileName,
        fileType,
      });

      if (fileBlob) {
        downloadFileViaBlob(fileBlob, fileName, fileType);
      }
    },
    [getExportFileBlob, exportFileName, initialRows, rows, isPaginated, allColumns]
  );

  Object.assign(instance, {
    exportData,
  });
}

function downloadFileViaBlob(fileBlob: Blob, fileName: string, type: FileTypes) {
  if (!fileBlob) return;

  const dataUrl = URL.createObjectURL(fileBlob);
  const link = document.createElement('a');
  link.download = `${fileName}.${type}`;
  link.href = dataUrl;
  link.click();
}
