import { css } from '@emotion/react';
import { ColumnInstance, Row } from 'react-table';
import { onlyText } from 'react-children-utilities';

import { PopoverMenuItem, usePopoverMenu, PopoverMenu, IconButton } from '../../../';
import { MoreIcon } from '../../../../icon';

import { RowAction } from '../../table-types';
import { TableData } from '../../table-data-type';
import { TableTrackingId } from '../../utils';

type ActionCellProps<T extends TableData> = {
  rowData: Row<T>['original'];
  rowObject: Row<T>;
  column: ColumnInstance<T>;
  tableTrackingIds: TableTrackingId;
};

export const ActionsCell = <T extends TableData>({
  rowData,
  rowObject,
  column,
  tableTrackingIds,
}: ActionCellProps<T>) => {
  const { getTriggerProps, getMenuProps, getItemProps, close } = usePopoverMenu({
    interactionOptions: {
      listNavigation: {
        openOnArrowKeyDown: false,
      },
    },
    placement: 'left',
  });

  const triggerProps = getTriggerProps();
  const menuProps = getMenuProps();
  const actions = column?.actions;
  const menuLabel = column?.rowActionMenuLabel;

  const hiddenActions = () => {
    if (actions) {
      return actions?.reduce((acc, { hide }) => {
        if (typeof hide !== 'function' ? hide : hide(rowData)) {
          return acc + 1;
        }
        return acc;
      }, 0);
    }
    return 0;
  };

  const hasAllActionsHidden = hiddenActions() === actions?.length;

  const shouldRenderSingleAction = () => {
    if (!actions) {
      return false;
    }

    return (actions.length === 1 && !hasAllActionsHidden) || actions.length - hiddenActions() === 1;
  };

  const renderActions = (
    getItemProps: ReturnType<typeof usePopoverMenu>['getItemProps'],
    showSingleButton?: boolean
  ) => {
    return actions?.map(({ onClick, Icon, renderCustom, ...rest }, id) => {
      const { hide, label, disabled, ...dynamicProps } = Object.entries(rest).reduce(
        (acc, [key, value]) => ({
          ...acc,
          ...(key !== 'onClick' ? { [key]: typeof value !== 'function' ? value : value(rowData) } : {}),
        }),
        {} as Omit<RowAction<T>, 'onClick' | 'Icon'>
      );

      const itemProps = !renderCustom
        ? getItemProps?.({
            onClick: () => {
              onClick?.(rowData, rowObject);
              close();
            },
            index: id,
          })
        : null;

      if (renderCustom) {
        return !hide ? renderCustom(getItemProps, id, close, showSingleButton, rowData, rowObject) : null;
      }

      return !showSingleButton
        ? !hide && (
            <PopoverMenuItem
              trackingId={tableTrackingIds.dynamicRowActionItem?.(onlyText(label as React.ReactNode))}
              key={onlyText(label as React.ReactNode) + id}
              Icon={Icon}
              {...itemProps}
              {...dynamicProps}
              disabled={disabled}
              label={onlyText(label as React.ReactNode)}
            >
              {label ?? ''}
            </PopoverMenuItem>
          )
        : !hide && (
            <IconButton
              key={id}
              css={css`
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              `}
              onClick={() => {
                onClick?.(rowData, rowObject);
              }}
              {...dynamicProps}
              disabled={!!rowObject?.isDisabled || disabled}
              label={onlyText(label as React.ReactNode)}
              trackingId={tableTrackingIds.dynamicRowActionItem?.(onlyText(label as React.ReactNode))}
              showLabelOnHover
            >
              <Icon color='light' />
            </IconButton>
          );
    });
  };

  return !shouldRenderSingleAction() ? (
    <>
      <IconButton
        color='light'
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
        size='small'
        label={menuLabel ?? 'Action menu'}
        disabled={hasAllActionsHidden || !!rowObject?.isDisabled}
        trackingId={tableTrackingIds.rowActionMenu}
        {...triggerProps}
      >
        <MoreIcon color='light' size={16} />
      </IconButton>
      <PopoverMenu {...menuProps} title={menuLabel ?? ''} css={{ maxWidth: 320 }}>
        {renderActions(getItemProps)}
      </PopoverMenu>
    </>
  ) : (
    <>{renderActions(getItemProps, true)}</>
  );
};
