import { useImageReady } from '../../hooks';
import { ListsIconLarge } from '../../icon';

export type FilePreviewImageProps = {
  file: File;
};

export const FilePreviewImage = ({ file }) => {
  const imgProps = useImageReady();

  return file.type === 'application/pdf' ? (
    <ListsIconLarge />
  ) : (
    <img {...imgProps} src={URL.createObjectURL(file)} width='auto' height='auto' />
  );
};
