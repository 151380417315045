import type { Row, TableInstance } from 'react-table';

import { Checkbox, Text } from '../../';
import { useControlledField } from '../../';
import { TableData } from '../table-data-type';
import { css } from '@emotion/react';
import { useThemeValues } from '../../../hooks';

type SelectAllRowsProps<T extends TableData> = {
  tableInstance: TableInstance<T>;
  nonDisabledRows: Row<T>[];
};

export const SelectAllRows = <T extends TableData>({ tableInstance, nonDisabledRows }: SelectAllRowsProps<T>) => {
  const { selectedFlatRows, isMobile, flatRows, tableTrackingIds } = tableInstance;
  const { spacing } = useThemeValues();

  const allSelected = nonDisabledRows.every((row) => row.isSelected);
  const allUnselected = nonDisabledRows.every((row) => !row.isSelected);

  const selectAllProps = useControlledField({
    type: 'checkbox',
    value: !allUnselected,
    onChange: () => nonDisabledRows.forEach((row) => tableInstance.toggleRowSelected(row.id, !allSelected)),
  });

  const hasDisabledRows = nonDisabledRows.length !== flatRows.length;
  const checkboxFieldLabel = hasDisabledRows ? 'Select All Available' : 'Select All';

  return (
    <>
      <Checkbox
        data-trackingid={tableTrackingIds.selectAll}
        disabled={tableInstance.isLoading || !flatRows.length || !nonDisabledRows.length}
        name='select-all-rows'
        indeterminate={!allSelected}
        {...selectAllProps}
        error={false}
      />
      {!isMobile && !selectedFlatRows.length && (
        <Text
          css={css`
            margin: 0;
            margin-left: ${spacing(2)};
          `}
          size='medium'
          color={tableInstance.isLoading || !flatRows.length || !nonDisabledRows.length ? 'disabled' : 'default'}
        >
          {checkboxFieldLabel}
        </Text>
      )}
    </>
  );
};
