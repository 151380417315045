import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { PrimaryButton, SecondaryButton } from '../buttons';
import { CheckIconSmall, CopyIcon } from '../../icon';
import { Text } from '../text';
import { NakedButton } from '../../naked';
import { useThemeValues } from '../../hooks';
import { useTooltip } from '../tooltip';
import { useStyles } from '../../use-styles';

type Props = {
  children?: React.ReactNode;
  hasIcon?: boolean;
  hasSuccessTooltip?: boolean;
  textToCopy: string;
  variant?: 'primary' | 'secondary' | 'tertiary';
  tooltipText?: string;
  tooltipSuccessText?: string;
  icon?: typeof CopyIcon;
  trackingId?: string;
};

const getIconColor = (variant: Props['variant'], children: React.ReactNode) => {
  if (variant === 'primary') return 'white';
  if (variant === 'secondary') return 'default';
  if (variant === 'tertiary' && !!children) return 'primary';
  return 'default';
};

const getTextColor = (variant: Props['variant']) => {
  if (variant === 'primary') return 'white';
  if (variant === 'secondary') return 'default';
  return 'primary';
};

export const CopyToClipboardButton = ({
  children,
  hasIcon = true,
  hasSuccessTooltip = false,
  textToCopy,
  variant = 'primary',
  tooltipText = 'Copy',
  tooltipSuccessText = 'Text copied!',
  icon,
  ...rest
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const Component = variant === 'primary' ? PrimaryButton : variant === 'secondary' ? SecondaryButton : NakedButton;
  const theme = useThemeValues();

  const { triggerProps, tooltipProps, Tooltip } = useTooltip({ hoverDelay: 500 });
  const [copied, setCopied] = useState(false);
  const tooltipStyles = useStyles('CopyableText', 'tooltip', { copied });
  const Icon = icon ?? CopyIcon;

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  }, [tooltipProps.isOpen]);

  return (
    <>
      <CopyToClipboard
        text={textToCopy}
        onCopy={() => {
          if (hasSuccessTooltip) setCopied(true);
        }}
      >
        <Component
          css={css`
            align-items: center;
            display: flex;
            gap: ${theme.spacing(1)};
            padding: ${theme.spacing(1.5)};
            width: auto;
            .copy-icon {
              margin-left: 0;
            }
          `}
          {...triggerProps}
          {...rest}
        >
          {hasIcon && <Icon className='copy-icon' size={16} color={getIconColor(variant, children)} />}
          {children && (
            <Text as='span' color={getTextColor(variant)}>
              {children}
            </Text>
          )}
        </Component>
      </CopyToClipboard>
      {hasSuccessTooltip && (
        <Tooltip {...tooltipProps} css={tooltipStyles}>
          {copied ? (
            <CheckIconSmall className='copy-status-icon' size={16} />
          ) : (
            <CopyIcon className='copy-status-icon' size={16} />
          )}
          <Text css={{ margin: 0 }} color='white'>
            {copied ? tooltipSuccessText : tooltipText}
          </Text>
        </Tooltip>
      )}
    </>
  );
};
