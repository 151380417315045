import { Icon, IconProps } from '../Icon.component';

export const TrendUpIcon: React.FC<React.PropsWithChildren<IconProps>> = (props) => (
  <Icon size={24} {...props} viewBox='0 0 24 24'>
    <path
      fillRule='evenodd'
      d='M15.243 7.34317L8.65716 7.34317C8.10487 7.34317 7.65716 6.89546 7.65716 6.34317C7.65716 5.79089 8.10487 5.34317 8.65716 5.34317L17.6572 5.34317L17.6572 5.34317C18.2095 5.34317 18.6572 5.79089 18.6572 6.34317L18.6572 15.3432C18.6572 15.8955 18.2095 16.3432 17.6572 16.3432C17.1049 16.3432 16.6572 15.8955 16.6572 15.3432L16.6572 8.7574L7.0506 18.364C6.66008 18.7545 6.02691 18.7545 5.63639 18.364C5.24586 17.9735 5.24586 17.3403 5.63639 16.9498L15.243 7.34317Z'
    />
  </Icon>
);
