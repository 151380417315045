import { useCallback, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import useCallbackRef from '../../../hooks/use-callback-ref';
import { debounce } from 'lodash-es';

type ScrollProps = {
  scrollTop: number;
  scrollLeft: number;
  scrollWidth: number;
  clientWidth: number;
};

const getIsRight = ({ scrollLeft, scrollWidth, clientWidth }: ScrollProps) => {
  if (scrollLeft >= scrollWidth - clientWidth) {
    return true;
  } else {
    return false;
  }
};

const getIsLeft = ({ scrollLeft }: ScrollProps) => {
  if (scrollLeft <= 2) {
    return true;
  } else {
    return false;
  }
};

const getIsTop = ({ scrollTop }: ScrollProps) => {
  if (scrollTop <= 2) {
    return true;
  } else {
    return false;
  }
};

export const useScrollShadow = () => {
  const [isRight, setIsRight] = useState(true);
  const [isLeft, setIsLeft] = useState(true);
  const [isTop, setIsTop] = useState(true);
  const [tableClientWidth, setTableClientWidth] = useState(0);
  const [tableScrollWidth, setTableScrollWidth] = useState(0);
  const bodyRef = useRef<HTMLElement | null>(null);

  const handleScroll = useCallback((event: Event) => {
    const currentTarget = event.currentTarget as HTMLDivElement;
    window.requestAnimationFrame(() => {
      setIsRight(getIsRight(currentTarget));
      setIsLeft(getIsLeft(currentTarget));
      setIsTop(getIsTop(currentTarget));
    });
  }, []);

  const callbackRef = useCallbackRef(
    useCallback((node: HTMLDivElement) => {
      if (node !== null) {
        setIsRight(getIsRight(node));
        setIsLeft(getIsLeft(node));
        setIsTop(getIsTop(node));
        setTableClientWidth(node.clientWidth);
        setTableScrollWidth(node.scrollWidth);

        node.addEventListener('scroll', handleScroll);
        bodyRef.current = node;
      }
      return () => node.removeEventListener('scroll', handleScroll);
    }, [])
  );

  useResizeObserver(
    bodyRef,
    debounce(() => {
      if (bodyRef.current) {
        setIsRight(getIsRight(bodyRef.current));
        setIsLeft(getIsLeft(bodyRef.current));
        setIsTop(getIsTop(bodyRef.current));
        setTableClientWidth(bodyRef.current.clientWidth);
        setTableScrollWidth(bodyRef.current.scrollWidth);
      }
    }, 100)
  );

  return {
    scrollRef: callbackRef,
    tableClientWidth,
    tableScrollWidth,
    isRight,
    isLeft,
    isTop,
  };
};
